
import React from 'react'
import { I18n } from '@front/volcanion'
import _ from 'lodash'

import { Box, Typography, Grid, Button, type TypographyProps } from '@mui/material'
import { TransportInformationProps } from './types'

const CustomComponent: React.FC<{ label: string, value: string, valueProps?: TypographyProps, display?: string }> = ({ label, value, valueProps, display = 'flex' }) => {
  return (
    <Grid item xs={12}>
      <Box display={display}>
        <Typography color='primary.main'>{[label, ':'].join(' ')}</Typography>
        <Typography mx={1} sx={{ color: 'text.main' }} {...valueProps} >{value}</Typography>
      </Box>
    </Grid>
  )
}

const TransportInformationComponent: React.FC<TransportInformationProps> = ({
  schedule_type,
  waiting_time,
  passenger_number,
  has_luggage,
  driver_comment
}) => {
  return (
    <Box p={1}>
      <Grid container rowSpacing={2}>
        <CustomComponent label={I18n.t('book.label', { count: 1 })} value={schedule_type} />
        <CustomComponent label={I18n.t('date.waiting_time_estimated')} value={waiting_time} />
        <CustomComponent label={I18n.t('count.passenger')} value={passenger_number} />
        <CustomComponent label={I18n.t('luggage.label', { count: 1 })} value={I18n.t(`prefix.${!!has_luggage ? 'yes' : 'no'}`)} />
        <CustomComponent
          label={I18n.t('comment.driver')}
          value={driver_comment}
          display={'block'}
          valueProps={{
            sx: {
              backgroundColor: 'white',
              border: 1,
              borderColor: '#cfd8dc',
              borderRadius: 1,
              p: 1,
              color: 'text.main'
            }
          }}
        />
      </Grid>
    </Box>
  )
}

export default TransportInformationComponent
