import React, { useMemo, useEffect } from 'react'
import { FormHooks, I18n, type VTypes, hooks } from '@front/volcanion'

import _ from 'lodash'
import { Box, Grid2 as Grid, Icon, Typography } from '@mui/material'

import { FormFactory } from '@front/squirtle'

const PasswordModify: React.FC = props => {
  const [password_new, password_confirm] = FormHooks.useFieldValues(['password_new', 'password_confirm']) as VTypes.V__ANY
  const [, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const [has_password] = hooks.useActiveUser({ mutator: 'auth.has_password', populate: ['auth'] })
  const validators = [
    { label: I18n.t('password.rules.length'), error: !password_new || password_new.length < 8 },
    { label: I18n.t('password.rules.upper'), error: !password_new || !password_new.match(/[A-Z]/g) },
    { label: I18n.t('password.rules.lower'), error: !password_new || !password_new.match(/[a-z]/g) },
    { label: I18n.t('password.rules.number'), error: !password_new || !password_new.match(/[0-9]/g) }
  ]

  const isValidPassword = useMemo(() => {
    if (!password_confirm || !password_new) return false
    else if (password_confirm !== password_new)
      return false
    else return _.isEmpty(_.filter(validators, ['error', true]))
  }, [password_new, password_confirm, validators])


  useEffect(() => {
    setFormState('isValidPassword', isValidPassword)
  }, [isValidPassword])

  return (
    <Grid container rowSpacing={3} spacing={3}>
      <Grid size={{ xs: 8 }}>
        {!!has_password && <FormFactory.TextField
          _native={{
            label: I18n.t('password.label.current'),
            type: 'password',
            required: true
          }}
          name='password'
          required
        />}
      </Grid>
      <Grid size={{ xs: 8 }}>
        <FormFactory.TextField
          _native={{
            label: I18n.t('password.label.new'),
            type: 'password',
            required: true
          }}
          name='password_new'
          required
        />
        <Box hidden={!password_new}>
          {validators.map(rule =>
            <Box display='flex' my={1}>
              <Icon fontSize='small' color={!!rule.error ? 'error' : 'success'}>{!!rule.error ? 'cancel' : 'check_circle'}</Icon>
              <Typography ml={1} fontWeight={200} sx={{ opacity: !!rule.error ? 0.6 : 1 }}> {rule.label}</Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid size={{ xs: 8 }}>
        <FormFactory.TextField
          _native={{
            label: 'Confirmation',
            type: 'password',
            required: true
          }}
          name='password_confirm'
          required
        />
      </Grid>
    </Grid>
  )
}



export default PasswordModify
