export default {
  account: {
    b2b: 'Compte business',
    my: 'Mon compte',
    private: 'Compte privé'
  },
  action: {
    add_option: '+ ajouter une option',
    apply: 'Appliquer',
    book: "Commander",
    book_for: "Commander pour",
    book_new: "Nouvelle commande",
    cancel: 'Annuler',
    confirm: 'Confirmer',
    create: 'Créer',
    delete_account: 'Supprimer mon compte',
    logout: 'Déconnexion',
    order_detail: 'Afficher le détail de la commande',
    return: 'Retour',
    modify: 'Modifier',
    login: 'Se connecter',
    contact_us: 'Nous contacter',
    signup: 'S\'inscrire',
    send: 'Envoyer',
    save: 'Enregistrer'
  },
  admin: {
    label: {
      one: 'Administrateur',
      other: 'Administrateurs',
      short: 'Admin'
    },
    space: 'Espace administrateur'
  },
  address: {
    label: {
      one: 'Addresse',
      other: 'Addresses',
      work: 'adresse de travail',
      home: 'adresse de domicile',
      favorite: 'adresse favorite'
    },
    add: {
      click: 'Cliquer ici pour ajouter votre',
      common: 'Ajouter une adresse',
      favorite: 'Ajouter votre adresse favorite',
      work: 'Ajouter votre adresse de travail',
      home: 'Ajouter votre adresse de domicile'
    },
    billing: 'Adresse de facturation',
    destination: {
      label: {
        one: "Destination",
        alter: 'Destination'
      },
      unefined: 'Adresse non défini',
      not_found: {
        home: 'Aucune adresse de domicile n\'est actuellement définie. Souhaitez-vous le faire maintenant ?',
        work: 'Aucune adresse de travail n\'est actuellement définie. Souhaitez-vous le faire maintenant ?',
        b2b: 'Aucune adresse de travail n\'est actuellement définie. Souhaitez-vous le faire maintenant ?'
      }
    },
    home: 'Adresse de domicile',
    modify: {
      common: "Ajouter une adresse",
      favorite: 'Modifier votre adresse favorite',
      work: 'Modifier votre adresse de travail',
      home: 'Modifier votre adresse de domicile',
    },
    my: 'Mes adresses',
    no_destination: 'Sans destination',
    reservationinfo: {
      pickup: "{{type}} - Indiquez votre {{name}} et votre {{origin}} afin de permettre au chauffeur d'anticiper les retards",
      source: "Où souhaitez-vous être pris en charge ?"
    },
    source: {
      label: {
        alter: "Prise en charge",
        one: 'Départ'
      }
    },
    type: {
      label: {
        one: 'Typd d\'adresse'
      },
      home: 'Domicile',
      work: 'Travail',
      favorite: 'Favoris'
    },
    same: 'Attention la source et la destination sont identiques'
  },
  all: 'Tous',
  are_you_ready: "Êtes-vous prêt ?",
  book: {
    label: {
      one: 'Commande',
      other: 'Commandes'
    },
    guest: "Commande invité",
    guest_disabled: "Vous n'avez pas les droits pour commander pour une autre personne",
    home: "Partir maintenant depuis chez moi",
    quick: 'Commande rapide',
    service: 'Partir maintenant depuis l\'adresse enregistré sur le compte professionnel',
    work: "Partir maintenant depuis le bureau",
  },
  call: {
    label: {
      one: 'Appel',
      other: 'Appels'
    },
    client_driver: 'Client vers chauf.',
    driver_client: 'Chauf. vers client'
  },
  card: {
    label: '•••• •••• •••• {{tail}}',
    holder: 'Titulaire',
    tail: 'Numéro de carte',
    expiry: 'Date d\'expiration',
    add: 'Ajouter une carte',
    name: 'Nom de la carte (facultatif)',
    detail: 'Détails de la carte',
    added: 'Carte ajouté avec succès',
    removed: 'Carte supprimée avec succès',
    update: 'Carte mise à jour avec succès'
  },
  commercial_formula: {
    label: {
      one: 'Niveau de service',
      other: 'Niveaux de services',
    }
  },
  commercial_package: {
    label: {
      one: 'Classe',
      other: 'Classes'
    }
  },
  company: {
    label: {
      one: 'Entreprise',
      other: 'Entreprises'
    },
    account: {
      label: {
        one: 'Compte entreprise',
        other: 'Comptes entreprises'
      }
    },
    user: 'Utiliseateur entreprise',
    contract_services: 'Contrats / Service'
  },
  companycontract: {
    client_id: 'Numéro de contrat',
    label: {
      one: 'Contrat',
      other: 'Contrats'
    },
    count: "Contrats - {{contracts_count}}"
  },
  companyservice: {
    associated: {
      label: {
        one: 'Service associé',
        other: 'Service associés'
      }
    },
    client_id: 'Code service',
    create: 'Création de service',
    label: {
      one: 'Service',
      other: 'Services'
    },
    link: {
      one: 'Service lié',
      other: 'Services liés'
    },
    add: "Ajouter un service"
  },
  companyuser: {
    create: 'Créer un utilisateur',
    label: {
      one: 'Utilisateur',
      other: 'Utilisateurs'
    }
  },
  count: {
    passenger: "Nombre de passager",
  },
  comment: {
    label: {
      one: 'Commentaire',
      other: 'Commentaires'
    },
    admin: 'Commentaire administrateur',
    driver: 'Commentaire chauffeur',
    length: {
      default: 'Commentaire: {{count}}/250',
      driver: 'Commentaire au chauffeur (précisions éventuelles concernant une adresse ou un passager) : {{count}}/250'
    },
    too_long: 'Commentaire trop long',

  },
  curency: '€',
  customer_ref: {
    label: {
      one: 'Référence facturation',
      other: 'Références facturation',
    }
  },
  custom: 'Personnalisée',
  dashboard: {
    order: {
      complete: 'Course Terminées',
      complete_ca: 'Course Terminées CA',
      complete_ci: 'Course Terminées CI',
      cancel: 'Course Annulées',
      cancel_ca: 'Course Annulées CA',
      cancel_ci: 'Course Annulées CI',
      not_loaded: 'Client absent'
    }
  },
  date: {
    activation: 'Date d\'activation',
    datetime: 'Date & Heure',
    end: 'Date de fin',
    returnAt: 'Date de retour',
    start: 'Date de début',
    waiting_time_estimated: 'Temps d\'attente estimé',
    month: {
      current: 'Mois en cours',
      last: 'Mois dernier'
    },
    day: {
      last: '{{count}} dernier jours'
    },
    year: {
      current: 'Depuis le début de l\'année',
      specific: 'Année {{count}}'
    }
  },
  day: {
    one: 'Jour',
    other: 'Jours'
  },
  dashboard: {
    order: {
      complete: 'Terminées',
      complete_ca: 'Terminées CA',
      complete_ci: 'Terminées CI',
      cancel: 'Annulées',
      cancel_ca: 'Annulées CA',
      cancel_ci: 'Annulées CI',
      not_loaded: 'Client absent'
    }
  },
  download: {
    bill: 'Télécharger la facture',
    fail: 'Echec du téléchargement'
  },
  drawer: {
    book: {
      label: 'Commander'
    },
    admin: {
      label: 'Espace administrateur',
      dashboard: 'Dashboard',
      contracts: 'Contrats',
      users: 'Utilisateurs',
      in_progress: 'Courses en cours',
      history: 'Historique',
    },
    order: {
      label: 'Mes commandes',
      in_progress: 'En cours',
      planned: 'Á venir',
      history: 'Historique'
    }
  },
  driver: {
    meet: 'Votre chauffeur se présentera le',
  },
  email: {
    label: {
      one: 'Email',
      other: 'Emails'
    },
    pro: 'Email professionnel'
  },
  error: {
    comment: {
      to_long: 'Commentaire trop long.'
    },
    unauthorized: {
      create: 'Vous n\'êtes pas autorisé(e) à commander avec ce compte entreprise. Veuillez vous rapprochez de votre administrateur.',
      cancel: 'Vous n\'êtes pas autorisé(e) à annuler les courses de ce compte entreprise. Veuillez vous rapprochez de votre administrateur.'
    },
  },
  event: {
    order: {
      order_pooled: 'Envoi à la pool',
      order_quotation: 'Enregistrement devis',
      order_created: 'Enregistrement commande',
      order_updated: 'Modification de commande',
      order_canceled: 'Commande annulée',
      order_attributed: 'Commande attribuée',
      order_not_served: 'Course non servie',
    }
  },
  ever: 'Toujours',
  help: {
    question: 'Besoin d\'aide ?'
  },
  hour: {
    one: 'Heure',
    other: 'Heures',
    destination: 'Heure d\'arrivée prévue',
    source: 'Heure de départ'
  },
  info: {
    additional: {
      label: {
        one: "Information complémentaire",
        other: "Informations complémentaires"
      }
    },
    general: {
      label: {
        one: 'Information générale',
        other: 'Informations générales'
      }
    },
    label: {
      one: 'Information',
      other: 'Informations'
    },
    order: {
      label: {
        one: "Information de course",
        other: "Informations de course"
      },
      new: 'Nouvelle Commande',
      detail: 'Afficher détail'
    }
  },
  invoice: {
    error: 'Nous avons rencontré une erreur lors de l\'envoi de la facture. Veuillez svp nous contacter à l\'adresse {{client_email}} pour l\'obtenir.',
    send: 'Envoi du reçu',
    success: 'La facture a été envoyée à votre adresse e-mail.'
  },
  login: {
    label: {
      one: 'Connexion',
      other: 'Connexions'
    },
    register: {
      label: 'Inscrivez-vous',
      steps: {
        first: 'Inscrivez-vous',
        second: 'Créez votre compte',
        third: 'Vérification de votre numéro',
        fourth: 'Ajouter une carte'
      },
      confirm: 'Veuillez confirmer votre numéro de téléphone en rentrant le code reçu',
      finalize: 'Finalisez votre inscription en renseignant les informations manquantes pour commander vos taxis.'
    }
  },
  luggage: {
    label: {
      one: 'Bagage(s)',
      other: 'Bagages',
      alter_one: 'Bagage volumineu',
      alter_other: 'Bagages volumineux'
    }
  },
  mad: {
    duration: 'Durée de la mise à disposition'
  },
  meeting_point: {
    label: {
      one: 'Point de rencontre',
      other: 'Points de rencontre',
      alter: 'Point de rencontre ({{name}})'
    }
  },
  month: {
    one: 'Mois',
    other: 'Mois'
  },
  name: {
    label: {
      one: 'Nom',
      other: 'Noms'
    },
    first: 'Prénom',
    last: "Nom"
  },
  never: 'Jamais',
  none: 'Aucun',
  none_fem: 'Aucune',
  option: {
    driver: {
      label: {
        one: 'Compétence chauffeur',
        other: 'Compétences chauffeur'
      },
    },
    selection: 'Sélection options:',
    vehicle: {
      label: {
        one: 'Option véhicule',
        other: 'Options véhicule'
      }
    }
  },
  order: {
    label: {
      one: 'Course',
      other: 'Courses',
      alter: 'Course(s)'
    },
    number: 'Nombre de courses',
    created: 'Réserversation enregistrée',
    client_id: 'Numéro de course',
    guest_booking: 'Commandes invités',
    my: {
      other: 'Mes courses'
    },
    self_booking: 'Commande personnelle',
    status: {
      label: 'Statut de commande',
      created: 'Enregistrée',
      standby: 'Dispatch en cours',
      attributed: 'Attribuée',
      canceled: 'Annulée',
      complete: 'Clôturée',
      register_planned_reservation: 'Réservation enregistrée',
      register_immediate_reservation: 'CI enregistrée',
      dispatch_in_progress: 'Dispatch en cours',
      transport_in_progress: 'Affectée',
      paid: 'Paiement',
      quotation: 'Devis',
      not_served: 'Non servie',
      approach: 'En approche'
    },
    schedule: {
      immediate: {
        label: {
          one: "Course immédiate",
          other: "Courses immédiate"
        }
      }
    },
    saved: 'Réservation enregistrée',
    type: {
      current: {
        one: 'Course en cours',
        other: 'Courses en cours',
        alter: 'Course(s) en cours',
        subtitle: '{{count}} course(s) en cours',
        short: 'En cours'
      },
      planned: {
        one: 'Course à venir',
        other: 'Courses à venir',
        alter: 'Course(s) à venir',
        subtitle: '{{count}} course(s) à venir',
        short: 'À venir'
      },
      history: {
        one: 'Course passée',
        other: 'Courses passées',
        alter: 'Course(s) passée(s)',
        subtitle: '{{count}} course(s) passée(s)',
        short: 'Historique'
      }
    }
  },
  passenger: {
    label: {
      one: 'Passager',
      other: 'Passagers'
    }
  },
  password: {
    label: {
      one: 'Mot de passe',
      other: 'Mots de passe',
      old: 'Ancien mot de passe',
      new: 'Nouveau mot de passe',
      current: "Mot de passe actuel"
    },
    forgot: {
      label: {
        one: "Mot de passe oublié"
      },
      title: 'Vous avez oublié votre mot de passe ?',
      description: 'Veuillez entrer l\'e-mail que vous avez utilisé pour vous inscrire afin de réinitialiser votre mot de passe.',
      success: 'Email de réinitialisation envoyé avec succès'
    },
    required: 'Mot de passe requis',
    modify: 'Modifier mon mot de passe',
    add: 'Définir un mot de passe',
    rules: {
      length: 'Au moins 8 caractères',
      upper: 'Au moins  une majuscule',
      lower: 'Au moins  une minuscule',
      number: 'Au moins  un chiffre'
    },
    complexity: {
      level: 'Complexité du mot de passe :',
      weak: 'Faible',
      good: 'Moyenne',
      strong: 'Élevée'
    },
    change: {
      success: 'Le mot de passe a été modifié avec succès.',
      error: {
        incorrect: 'Ancien mot de passe incorrect.'
      }
    }
  },
  gsm: {
    label: {
      one: 'Numéro de téléphone',
      old: 'Ancien numéro de téléphone',
      new: 'Nouveau numéro de téléphone'
    },
    modify: 'Modifier mon numéro',
    code_step: "Veuillez rentrer le code de vérification envoyé par SMS ou appel vocal au",
    not_receive: "Vous n'avez pas reçu de code ? ",
    change: {
      first_step: 'Veuillez saisir votre nouveau numéro de téléphone. Un code à 4 chiffres vous sera envoyé par SMS pour confirmer le changement de numéro',
      second_step: 'Un code à 4 chiffres à été envoyé au numéro de téléphone suivant: {{new_gsm}} Veuillez renseigner ce code dans le champs ci - dessous pour validerle changement de numéro',
      done: 'Demande de changement de téléphone envoyé.'
    },
    link: {
      resend: {
        by_gsm: "Cliquez ici pour recevoir un nouveau code par SMS",
        by_vocal: "Cliquez ici pour recevoir un nouveau code par Appel vocal"
      },
      change: {
        gsm: "Changer de numéro de téléphone",
      }
    }
  },
  payment: {
    allowed: 'Autorisé',
    allow_deferred: 'Permettre la facturation en compte',
    deferred: 'Facturation en compte',
    forbidden: 'Interdit',
    mandatory: 'Obligatoire',
    mode: 'Mode de paiment',
    add_card: 'Cliquer ici pour ajouter une carte bancaire',
    added: 'Carte ajoutée avec succès',
    deleted: 'Carte supprimée avec succès',
    updated: 'Carte mise à jour avec succès',
    my_card: {
      one: 'Ma carte de paiment',
      other: 'Mes cartes de paiment'
    }
  },
  placement: {
    up: {
      label: {
        one: 'levé',
        other: 'levés'
      }
    },
    down: {
      label: {
        one: 'baissé',
        other: 'baissés'
      }
    }
  },
  preference: {
    label: {
      one: 'Préférence',
      other: 'Mes préférences de voyage'
    },
    air_conditioning: 'Climatisation',
    armrest: {
      label: {
        one: 'Accoudoir',
        other: 'Accoudoirs'
      }
    },
    comment_placeholder: 'Précisions sur l\'adresse ou le passager, à destination du chauffeur',
    open_door: 'Ouverture de porte',
    open_door_depends: 'Si le chauffeur arrive avant moi',
    open_window: 'Fenêtres ouvertes',
    preferred_music: 'Ambiance sonore',
    success: 'Préférences mise à jour avec succès',
    temperature: {
      label: {
        one: 'Température',
        other: 'Tempértures'
      },
      celcius: 'Température (°C)'
    },
    unlimited: 'Illimité',
    waiting_duration: 'Attente chauffeur(minutes)'
  },
  prefix: {
    at: 'à',
    at_alter: 'au',
    at_alter_bis: 'du',
    no: 'non',
    yes: 'oui'
  },
  ref: {
    billing: {
      mandatory: 'Code mission requis',
      label: {
        one: 'Référence facturation',
        other: 'Références facturation',
        required: 'Référence facturation *'
      },
      fill_ref: 'Saisie réf factu'
    },
    options: {
      optional: 'Optionnelle',
      required: 'Obligatoire',
      suggested: 'Suggérée'
    },
    authorized: {
      list: 'Code missions autorisés'
    }
  },
  resend: {
    receipt: 'Renvoyer le reçu'
  },
  ridemode: {
    label: {
      one: 'Prestation',
      other: 'Prestations'
    }
  },
  schedule: {
    planned: {
      one: 'Plannifié',
      other: 'Plannifiés',
      alter: 'A l\'avance'
    },
    immediate: {
      one: 'Immédiate',
      other: 'Immédiates',
      alter: 'Immédiate'
    },
    type: 'Type de planification'
  },
  setting: {
    open: 'Ouvrir paramétres'
  },
  status: {
    label: {
      one: 'Statut',
      other: 'Status'
    },
    active: 'Actif',
    archived: 'Archivé',
    deleted: 'Résilié',
    inactive: 'Inactif',
    suspended: 'Suspendu',
  },
  term: {
    general: 'En continuant, vous acceptez les',
    cgu: ' Conditions Générales d\’Utilisation.',
    notification: 'Je souhaite recevoir les bons plans et actualités de {{name}}',
    signup: '{{name}} utilise vos données pour améliorer ses services et vous fournir la meilleure expérience possible. Vous bénéficiez du droit d\'accès et de rectification des informations qui vous concernent, du droit de suppression de votre compte et du droit de vous opposer à l\'utilisation de vos données à des fins de prospection commerciale. Vous pouvez exercer vos droits à travers l\'application ou en vous adressant à '
  },
  transport: {
    status: {
      label: 'Statut transport',
      approach: 'En approche',
      attributed: 'Attribuée',
      at_dst: 'Livraison en cours',
      at_src: 'Sur place',
      canceled: 'Supprimé',
      created: 'Enregistrée',
      complete: 'Terminé',
      in_progress: 'En cours',
      none: '-',
      not_loaded: 'Non chargé',
      not_served: 'Non servie',
      off_board: 'Paiement',
      on_board: 'En direction',
      paid: 'Paiement',
      planned: 'Planifiée',
      quotation: 'Devis',
      standby: 'Dispatch en cours',
      started: 'En approche'
    }
  },
  travel: {
    come: "Aller simple",
    come_back: "Aller-retour"
  },
  total: {
    label: {
      one: 'Total',
      other: 'Totals'
    },
    spent: "Dépense totale",
    price_estimated: 'Prix estimé',
    price: 'Prix total',
    order: "Total courses"
  },
  unlimited: 'Illimité',
  unit: {
    label: {
      one: 'Unité',
      other: 'Unités'
    }
  },
  user: {
    label: {
      one: 'Utilisateur',
      other: 'Utilisateurs'
    },
    count: 'Utilisateurs - {{user_count}}',
    number: 'Nombres d’utilisateurs',
    profile: 'Profile'
  },
  validation: {
    ridemode: {
      distance: {
        min: "La distance entre les lieux de prise en charge et de destination indiqués est trop courte {{distance}}km. Nos chauffeurs ne peuvent vous accompagner que pour une distance maximum de {{min_distance}}km. Marcher ou utiliser les transports en commun, c’est bien aussi parfois, c’est bon pour la santé et l’environnement.",
        max: "La distance entre les lieux de prise en charge et de destination indiqués est trop importante {{distance}}km. Nos chauffeurs ne peuvent vous accompagner que pour une distance maximum de {{max_distance}}km. Nous ne pouvons pas répondre à votre demande.",
      },
      duration: {
        min: '',
        max: '',
      },
      night: {
        min: "La durée du séjour est trop courte {{nights}} nuit(s). Nos chauffeurs peuvent vous accompagner pour un minimum de {{min_night}} jours.",
        max: "La durée du séjour est trop importante {{nights}} nuit(s). Nos chauffeurs ne peuvent vous accompagner que pour un maximum de {{max_night}} jours consécutifs.",
      },
      stay: {
        min: "Désolé, la durée du séjour souhaitée est trop courte pour la destination que vous avez sélectionné. Nous aurions aimé pouvoir vous servir mais la téléportation n’existe pas encore.",
        date: "Désolé, le temps de repos nécessaire à nos chauffeurs entre l’aller et le retour de votre travel est insuffisant. Nous ne pouvons pas répondre à votre demande.",
      },
    },
    traffic: {
      warning: "Attention ! Votre commande est dans un créneau horaire {{requestedAt}}, de forte demande ou avec risque de trafic dense pour l'arrivée de votre taxi au point de rendez-vous. Prévoyer {{margin}}min supplémentaires."
    },
    authorization: {
      error: 'Commande interdite pour le {{requestedAt}}.'
    }
  },
  year: {
    one: 'Année',
    other: 'Années'
  }
}
