import { IFormFieldValue, ModelRecord, type IFormFieldListener, type MaybeMultiple } from '@front/volcanion/types'
import moment from 'moment'
class Callbacks {
  static afterChangeRequestedAtHandler(setFormState: (name: string, value: IFormFieldValue) => void, threshold_RA: string) {
    return function afterChangeRequestedAt(value: MaybeMultiple<string>) {
      const schedule_type = moment(value).utc().isAfter(moment().add(threshold_RA, 'minutes')) ? 'planned' : 'immediate'
      setFormState('schedule_type', schedule_type)
      return
    }
  }
  static onChangeReturnAtHandler(setFormState: (name: string, value: IFormFieldValue) => void, returnAtListener: () => IFormFieldListener<IFormFieldValue>) {
    return function onChangeReturnAt(value: boolean) {
      setFormState('display_returnAt', value)
      value === false && returnAtListener().clearValue()
      return
    }
  }
  static resolveAddressCallbackHandler(setFormState: (field: string, value: IFormFieldValue) => void) {
    return async function resolveAddressCallback(details: ModelRecord, prefix: string) {
      const detailled_address = details?.data
      detailled_address?.poi_id && setFormState('meeting_point', details?.meeting_point_translated)
      detailled_address?.poi_id && setFormState('driver_instruction', details?.driver_instruction_translated)
    }
  }
}

export default Callbacks
