
import React from 'react'

import { FormHooks, I18n, VTypes } from '@front/volcanion'

import _ from 'lodash'

import { Box, Typography, Grid2 as Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'
import { ModifyButton } from '../../../../elements/Button'

const ProfileComponent: React.FC = props => {
  return (
    <Box sx={{ boxShadow: 3, p: 2 }}>
      <Grid container rowSpacing={3}>
        <Grid size={{ xs: 12, md: 8, lg: 8 }} >
          <Typography variant={'h6'}>{I18n.t('user.profile')}</Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 4 }} >
          <Box display='flex' justifyContent={'flex-end'}>
            <ModifyButton />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <FormFactory.TextField name='first_name' _native={{ label: 'Prénom' }} />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <FormFactory.TextField name='last_name' _native={{ label: 'Nom' }} />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <FormFactory.TextField name='gsm' _native={{ label: 'Téléphone' }} />
        </Grid >
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <FormFactory.TextField name='email' _native={{ label: 'Email' }} />
        </Grid >
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <FormFactory.TextField name='password' _native={{ label: 'Mot de passe' }} />
        </Grid>
      </Grid >
    </Box >
  )
}

export default ProfileComponent
