import React, { useMemo } from "react"
import { I18n, FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import { TimeUtils, BillingUtils } from "@front/volcanion/utils"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], {
    single: true, populate: ['assigned_transport.payment', 'source', 'loads', 'service.contract.formula', 'do.commercial_formula']
  })

  const requestedAt = TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source, { format: `DD/MM/YYYY [${I18n.t('prefix.at')}] HH:mm` })
  const passenger_name = order?.loads?.[0]?.src_contact
  const commercial_formula = order?.service ? order?.service?.contract?.formaula?.name : order?.do?.commercial_formula?.name
  const company = order?.service ? [order?.service?.contract?.name, order?.service?.service?.name].join(', ') : undefined

  const total = useMemo(() => {
    if (order?.quotation?.type === 'special') {
      return BillingUtils.mergeWithCurrency(BillingUtils.getRoundedPrice(order?.quotation?.manual_price, 1))
    } else if (_.includes(['complete', 'canceled'], order?.status)) {
      return BillingUtils.getPaymentDisplay(order?.assigned_transport?.payment, false, 1)
    } else {
      BillingUtils.getEstimationDisplay(order?.quotation, false, 1,)
    }
  }, [order?.status, order?.quotation])

  const mergedProps = {
    requestedAt,
    passenger_name,
    commercial_formula,
    company,
    total
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
