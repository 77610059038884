import { type IFormFieldListener, type IFormFieldValue, type MaybeMultiple, type ModelRecord } from "@front/volcanion/types"

class Callbacks {
  static afterChangeHandler() {
    return function afterChange(value: MaybeMultiple<IFormFieldValue>, prev_value: MaybeMultiple<IFormFieldValue>, listener: IFormFieldListener<IFormFieldValue>) {
      const ridemode = listener.getValueOption(value) as ModelRecord
      const display_duration = ridemode?.config?.display_duration_field
      const display_travel = !!ridemode?.stay_required
      const parentFormListener = listener.getParentForm()
      !display_duration && parentFormListener.getField('ridemode_duration').clearValue()
      !display_travel && parentFormListener.getField('returnAt').clearValue()
    }
  }
}

export default Callbacks
