
import { I18n } from '@front/volcanion'

import _ from 'lodash'


import { IFormBaseListener, ISubmitMap, ModelRecord } from '@front/volcanion/types'
import { FormatUtils, GeoUtils } from '@front/volcanion/utils'

class Callbacks {
  static getEmptyStateHandler(user_id: string) {
    return function getEmptyState() {
      return {

      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {

      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record: ModelRecord) {
      const status = FormatUtils.getStatus(record)
      return {
        name: record?.name,
        client_id: record?.client_id,
        invoice_type: record?.invoice_type,
        deferred_billing: record?.deferred_billing,
        availableSince: record?.availableSince,
        availableUntil: record?.availableUntil,
        contact: {
          email: record?.contact?.email,
          address: record?.contact?.address
        },
        comment: record?.comment?.text,
        source_address: {
          formatted_address: GeoUtils.mergeAddress(record?.source_address),
          ...record?.source_address
        },
        status: I18n.t(`status.${status}`)
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record: ModelRecord) {
      return {
        commercial_formula: record?.formula
      }
    }
  }

  static formToSubmitHandler() {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      submit_map
        .setDataKey('name', form.getFieldValue('name'))
        .setDataKey('client_id', form.getFieldValue('client_id'))
        .setDataKey('invoice_type', form.getFieldValue('invoice_type'))
        .setDataKey('deferred_billing', form.getFieldValue('deferred_billing'))
        .setDataKey('availableSince', form.getFieldValue('availableSince'))
        .setDataKey('availableUntil', form.getFieldValue('availableUntil'))
        .setDataKey('status', form.getFieldValue('status'))
        .setDataKey('source_address', form.getFieldValue('source_address'))
        .setDataKey('contact', { address: form.getFieldValue('contact.address') })
        .setDataKey('comment', form.getFieldValue('comment'))
      return submit_map
    }
  }



}

export default Callbacks

