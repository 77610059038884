
import React, { useCallback, useEffect, useMemo } from "react"
import { FormHooks, hooks, HookUtils, type VTypes } from "@front/volcanion"
import { Loader } from "@front/squirtle"

import _ from "lodash"

import type { Colors } from "../types"
import Callbacks from "./callbacks"
import Component from "./component"
import NoResultComponent from "../noResult"

const withContainer: React.FC = () => {
  const theme = hooks.useRelayConfig('COLORS') as Colors
  const formListener = FormHooks.useFormListener()
  const [company] = hooks.useGlobalState('persist.company_id')
  const [contract, service, user, date_start, date_end, schedule_type] = FormHooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end', 'schedule_type']) as VTypes.V__ANY

  const [data, search, state] = hooks.useModelValues('order', 'get', {})
  const [dataExpanse, searchExpanse] = hooks.useModelValues('order', 'get', {}) as VTypes.V__ANY

  const getChartData = useCallback(Callbacks.getChartDataHandler(data as [], theme, schedule_type), [data as [], theme, schedule_type])

  const { labels, datasets, total } = useMemo(() => getChartData(), [schedule_type, theme, data])
  const totalExpanse = _.round(_.sumBy(dataExpanse, 'total_payment')) as number

  const default_filter = {
    requestedAt: { '>=': date_start, '<=': date_end },
    service: (!!service && { companyservice_id: service }) || { contract: (!!contract && { companycontract_id: contract }) || { company } },
    do: user || undefined,
    schedule_type: schedule_type || undefined
  }

  const filter = {
    or: [
      _.merge({}, default_filter, { reason: 'not_served' }),
      _.merge({}, default_filter, { status: { '!': 'canceled' } }),
      _.merge({}, default_filter, { reason: { '!': 'not_served' }, status: 'canceled' })
    ]
  }

  useEffect(() => {
    formListener().setStateKey('totalExpanse', totalExpanse)
  }, [totalExpanse])


  useEffect(() => {
    search({
      filter,
      options: {
        group_by: ['status AS status', 'reason AS reason', 'schedule_type AS schedule_type'],
        no_select: true,
        query_operations: [{ path: 'order_id', operation: 'COUNT' }],
        sort: ['schedule_type DESC'],
        select: [],
        limit: -1,
        type: 'b2b'
      }
    })
    searchExpanse({
      filter,
      options: {
        no_select: true,
        query_operations: [{ path: 'assigned_transport.payment.base', alias: 'total_payment', operation: 'SUM' }],
        select: [],
        limit: -1,
        type: 'b2b'
      }
    })
  }, [contract, service, user, date_start, date_end, schedule_type, company])

  const mergedProps = {
    labels,
    datasets,
    total,
    schedule_type
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
