
import React from 'react'
import _ from 'lodash'

import { I18n, CDNSVG, FormHooks, type VTypes } from '@front/volcanion'
import { Box, Typography, Grid } from '@mui/material'

import { Search } from './Section'
import { OrdersCount, TotalExpenses, UsersCount } from './Chart'

const DashboardComponent: React.FC = props => {
  const [{ totalExpanse }] = FormHooks.useFormState() as VTypes.V__ANY
  return (
    <Box>
      <Grid container >
        <Grid item xs={12}>
          <Box display={'flex'} alignItems='center'>
            <CDNSVG name={'drawer'} category={'icons'} variant={'dashboard'} />
            <Typography ml={1} variant='h6' color='primary'>{I18n.t('drawer.admin.dashboard')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={1} boxShadow={4} m={1}>
            <Search />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={1} boxShadow={4} m={1}>
            <Typography fontSize={18}>{I18n.t('total.spent')}: {totalExpanse}{I18n.t('curency')}</Typography>
            <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><TotalExpenses /></Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={1} boxShadow={4} m={1}>
            <Typography fontSize={18}> {I18n.t('user.number')}:</Typography>
            <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><UsersCount /></Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={1} boxShadow={4} m={1} >
            <Typography fontSize={18}>{I18n.t('order.number')}:</Typography>
            <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}><OrdersCount /></Box>
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default DashboardComponent
