import React, { useMemo } from 'react'

import { hooks } from '@front/volcanion'
import { Elements } from '@front/squirtle'

import _ from 'lodash'
import moment from 'moment'

import type { Colors } from '../types'
import type { UsersCountProps } from './types'

const hexToRgba = (hex: string, alpha = 1) => {
  const replacedHex = hex.replace(/^#/, '')
  const formatted_hex = replacedHex?.length === 3
    ? _.map(_.split(replacedHex, ''), char => char + char).join('')
    : replacedHex

  const r = parseInt(formatted_hex.slice(0, 2), 16)
  const g = parseInt(formatted_hex.slice(2, 4), 16)
  const b = parseInt(formatted_hex.slice(4, 6), 16)

  return `rgba(${r},${g},${b},${alpha})`
}

const UsersCountChart: React.FC<UsersCountProps> = ({ data, unit, date_start, date_end }) => {
  const theme = hooks.useRelayConfig('COLORS') as Colors

  const datasets = [{
    data,
    borderColor: theme.primary[700],
    borderWidth: 1,
    fill: true,
    tension: 0.8,
    borderDash: [4, 5],
    pointRadius: 0,
    cumulative: true,
    backgroundColor: function (context: any) {
      const chart = context.chart
      const { ctx, chartArea } = chart

      if (!chartArea) return null

      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      const baseColor = '35,35,35'
      gradient.addColorStop(0, hexToRgba(theme.primary[700], 0))
      gradient.addColorStop(.25, hexToRgba(theme.primary[700], .1))
      gradient.addColorStop(.5, hexToRgba(theme.primary[700], .2))
      gradient.addColorStop(.75, hexToRgba(theme.primary[700], .4))
      gradient.addColorStop(1, hexToRgba(theme.primary[700], .6))

      return gradient
    }
  }]

  const maxValue = _.maxBy(data, 'y')?.y as number
  const minValue = _.minBy(data, 'y')?.y as number
  const valueRange = maxValue - minValue
  const stepSize = _.ceil(valueRange / 5)

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time' as const,
        time: { unit: unit as any },
        grid: { display: false },
        min: moment(date_start).format('YYYY-MM-DD'),
        max: moment(date_end).format('YYYY-MM-DD'),
      },
      y: {
        beginAtZero: false,
        stacked: true,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
    plugins: {
      legend: { display: false }
    }
  }

  return (
    <Elements.Chart
      config={{
        type: 'line',
        data: { datasets: datasets as any },
        options
      }}
      style={{ width: '100%', height: '280px' }}
      watchers={[data]}
    />
  )
}

export default UsersCountChart
