import React, { useCallback } from "react"
import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const afterDateChange = useCallback(Callbacks.afterDateChangeHandler(), [])
  const afterPeriodChange = useCallback(Callbacks.afterPeriodChangeHandler(), [])

  const mergedProps = {
    afterDateChange,
    afterPeriodChange
  }

  return (
    <Component{...mergedProps} />
  )
}

export default withContainer
