import { I18n } from '@front/volcanion'
import type { NotificationHandler } from '@front/volcanion/types'

class Callbacks {
  static onRequestSubmitHandler(openNotification: NotificationHandler["openNotification"], updateUser: (data: object) => void, values: any) {
    return async function onRequestSubmit(force: boolean, code?: string) {
      try {
        if (!code && !force)
          openNotification(I18n.t('gsm.code.empty'), { variant: 'success' })
        await updateUser(values)
        if (!!force)
          openNotification(I18n.t('gsm.code.resend_success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('gsm.code.incorrect'), { variant: 'error' })
        throw err
      }
    }
  }
}

export default Callbacks
