import React from 'react'
import { FormHooks } from '@front/volcanion'

const withContainer = Component => ({ ...props }) => {
  const [{ step }] = FormHooks.useFormState()

  const mergedProps = {
    step
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
