
import type { UseStripeProps } from '@front/squirtle/hooks/useStripe/types'
import type { VTypes } from '@front/volcanion'
import type { IFormFieldValue, ModelRecord } from '@front/volcanion/types'
import _ from 'lodash'

class Callbacks {
  static onSubmitPackageOptionsHandler(changeVehicleOptions: (value: IFormFieldValue) => void, changeDriverOptions: (value: IFormFieldValue) => void) {
    return async function onSubmitPackageOptions(form: VTypes.IFormBaseListener, submit_map: VTypes.ISubmitMap) {
      const vehicle_opts = form.getFieldValue('vehicle_opts')
      const driver_opts = form.getFieldValue('driver_opts')
      changeVehicleOptions(_.compact(_.flatten([vehicle_opts])))
      changeDriverOptions(_.compact(_.flatten([driver_opts])))
      return submit_map
    }
  }
  static onSubmitReservationInfoHandler(changeReservationInfo: (value: IFormFieldValue) => void, setFormState: (name: string, value: IFormFieldValue) => void, poi: ModelRecord) {
    return async function onSubmitReservationInfo(form: VTypes.IFormBaseListener, submit_map: VTypes.ISubmitMap) {
      const reservationinfo_pick_type = form.getFieldValue('reservationinfo_pick_type')
      const name = form.getFieldValue('name')
      const origin = form.getFieldValue('origin')
      const reservationinfo_id = form.getStateValue('reservationinfo_id')

      const reservationinfo = poi?.type?.reservationinfo

      const arrival_trkey = reservationinfo_pick_type === 'arrival'
        ? reservationinfo?.arrival_trkey
        : poi?.meeting_point_trkey

      const driver_instruction_trkey = reservationinfo_pick_type === 'arrival'
        ? reservationinfo?.arrival_trkey
        : poi?.driver_instruction_trkey

      changeReservationInfo({
        reservationinfo_id,
        name,
        origin,
        arrival_trkey,
        driver_instruction_trkey
      })

      setFormState('reservationinfo_pick_type', reservationinfo_pick_type)
      return submit_map
    }
  }

  static getEmptyPaymentAddFormHandler(cardholder_name: string) {
    return function getEmptyPaymentAddForm() {
      return {
        cardholder_name
      }
    }
  }

}


export default Callbacks

