
import { I18n } from "@front/volcanion"
import type { IFormFieldValue, NotificationHandler, ModelRecord } from "@front/volcanion/types"

class Callbacks {
  static onRequestInvoiceHandler(getInvoice: (params: object) => void, openNotification: NotificationHandler["openNotification"], CLIENT_CONTACT_EMAIL: string, order_id: string) {
    return async function onRequestInvoice() {
      try {
        await getInvoice({ order_id })
        openNotification(I18n.t('invoice.success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('invoice.error', { client_email: CLIENT_CONTACT_EMAIL, }), { variant: 'success' })
      }
    }
  }

  static downloadBillHandler(downloadInvoice: () => void, openNotification: NotificationHandler["openNotification"]) {
    return async function downloadBill() {
      try {
        await downloadInvoice()
      } catch (err) {
        console.warn("Failed to download file", err)
        openNotification(I18n.t('download.fail'), { variant: 'error' })
      }
    }
  }

  static getBillDownloadStatusHandler(isDownloadAvailable: (params: object) => void, setFormState: (field: string, value: IFormFieldValue) => void) {
    return async function getBillDownloadStatus(order_id: string) {
      try {
        const response = await isDownloadAvailable({ order_id, type: 'invoice' })
        const result = response as any
        setFormState('canDownloadBill', result?.status === true)
      } catch (err) {
        setFormState('canDownloadBill', false)
        console.log('Err on isDownloadAvailable')
      }
    }
  }

}

export default Callbacks

