import React from 'react'
import { I18n, VTypes, FormHooks, hooks } from '@front/volcanion'
import { FormatUtils } from '@front/volcanion/utils'

import _ from 'lodash'

import { Box, Typography, Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'

const Row1: React.FC = props => {
  const [status] = FormHooks.useFieldValues(['status']) as VTypes.V__ANY
  const statusColor = FormatUtils.getStatusColor(status)
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.TextField name='name' _native={{ label: I18n.t('name.label', { count: 1 }) }} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='client_id' _native={{ label: I18n.t('companyservice.client_id') }} />
      </Grid>
      <Grid item xs >
        <FormFactory.TextField name='contact.email' _native={{ label: I18n.t('email.label', { count: 1 }) }} />
      </Grid>
      <Grid item xs>
        <FormFactory.MenuSelectorField
          name='status'
          options={[
            { label: I18n.t('status.active'), value: 'active' },
            { label: I18n.t('status.inactive'), value: 'inactive' },
            { label: I18n.t('status.archived'), value: 'archived' },
            { label: I18n.t('status.suspended'), value: 'suspended' },
          ]}
          _native={{
            label: I18n.t('status.label', { count: 1 }),
            valueDecoratorProps: {
              color: statusColor
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

const Row2: React.FC = props => {
  const [{ companycontract_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [deferred_billing] = FormHooks.useFieldValues(['deferred_billing']) as VTypes.V__ANY
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })

  const deferred_billing_options = !companycontract?.auth_account
    ? _.uniqBy(_.compact([
      { label: I18n.t('payment.forbidden'), value: 'forbidden' },
      !!deferred_billing && { label: I18n.t(`payment.${deferred_billing}`), value: deferred_billing }
    ]), 'value')
    : [
      { label: I18n.t('payment.forbidden'), value: 'forbidden' },
      { label: I18n.t('payment.allowed'), value: 'allowed' },
      { label: I18n.t('payment.mandatory'), value: 'mandatory' }
    ]

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.MenuSelectorField
          name='deferred_billing'
          options={deferred_billing_options}
          selectFirst
          required
          _native={{
            label: I18n.t('payment.deferred')
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.MenuSelectorField
          name={'customerref_option.fill_ref_rule'}
          _native={{
            label: I18n.t('ref.billing.fill_ref')
          }}
          selectFirst
          required
          options={[
            { label: I18n.t('ref.options.optional'), value: 'optional' },
            { label: I18n.t('ref.options.suggested'), value: 'suggested' },
            { label: I18n.t('ref.options.required'), value: 'required' }
          ]}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.AutocompleteField
          name={'customerref_option.refs'}
          _native={{
            label: I18n.t('ref.authorized.list')
          }}
          labelKeys={['client_id']}
          multiple
          createKey={'client_id'}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.OptionSelectorField
          name={'customerref_option.imposed_ref'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('ref.billing.mandatory')
          }}
        />
      </Grid>
    </Grid>
  )
}

const Row3: React.FC = props => {
  const { DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER } = hooks.useRelayFeatures(['DRIVER_CAN_CALL_CLIENT', 'CLIENT_CAN_CALL_DRIVER'])

  return (
    <Grid container item xs={12}>
      <Grid item xs={4} lg={3} hidden={!CLIENT_CAN_CALL_DRIVER}>
        <FormFactory.OptionSelectorField
          name={'can_call_booker'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('call.client_driver'),
          }}
        />
      </Grid>
      <Grid item xs={4} lg={3} hidden={!DRIVER_CAN_CALL_CLIENT}>
        <FormFactory.OptionSelectorField
          name={'can_call_driver'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('call.driver_client'),
          }}
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection: React.FC = props => (
  <Box display='flex'>
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>{I18n.t('info.general.label', { count: 2 })}</Typography>
      </Grid>
      <Row1 />
      <Row2 />
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>{'Appel'}</Typography>
      </Grid>
      <Row3 />
    </Grid>
  </Box >
)


export default PrimarySection
