
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import _ from 'lodash'
import { FormFactory, Loader } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'

const Booking: React.FC = () => {
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const [user, user_status] = hooks.useActiveUser({ populate: ["user_companies", "auth", "customerinfo", "info"] })

  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(), [])
  const onSubmit = useCallback(Callbacks.onSubmitHandler(updateUser), [updateUser])

  return <Loader isLoading={!user || !HookUtils.getReadyState([user_status])} nativeLoader type='linear'>
    <FormFactory.Form
      name={`user_profile_${user?.user_id}`}
      listener_type='form'
      recordToForm={recordToForm}
      formToSubmit={formToSubmit}
      onSubmit={onSubmit}
      config={{
        model_name: 'user',
        ids: [user?.user_id],
        populate: ['auth', 'info', 'customerinfo']
      }}
    >
      <FormContent />
    </FormFactory.Form>
  </Loader >


}

export default Booking
