
import React from 'react'
import { I18n, CDNImage } from '@front/volcanion'

import _ from 'lodash'

import { Box, Chip, Typography } from '@mui/material'
import { PackageInformationProps } from './types'

const PackageInformationComponent: React.FC<PackageInformationProps> = ({
  package_icon,
  package_name,
  vehicle,
  driver_opts,
  vehicle_opts
}) => {
  return (
    <Box display='flex'>
      <Box sx={{ flex: 1 }} display='flex' alignItems='center'>
        <CDNImage name={package_icon} category={'packages'} style={{ maxHeight: 120 }} />
      </Box>
      <Box sx={{ flex: 1 }} display='flex' flexDirection='column' justifyContent='space-around'>
        <Box my={1}>
          <Typography fontSize={20} fontWeight={'bold'}>{package_name}</Typography>
        </Box>
        <Box my={1} hidden={!vehicle}>
          <Typography >{vehicle}</Typography>
        </Box>
        <Box my={1}>
          <Typography mb={1}>{I18n.t('option.vehicle.label', { count: -1 })}</Typography>
          {_.map(vehicle_opts, option_vehicle =>
            <Chip label={option_vehicle?.name_translated} color='secondary' variant='outlined' sx={{ ml: 1, borderRadius: 1 }} size='small' />
          )}
        </Box>
        <Box my={1}>
          <Typography mb={1}>{I18n.t('option.driver.label', { count: -1 })}</Typography>
          {_.map(driver_opts, option_user =>
            <Chip label={option_user?.name_translated} color='secondary' sx={{ color: 'white', ml: 1, borderRadius: 1 }} size='small' />
          )}
        </Box>
      </Box>
    </Box >
  )
}

export default PackageInformationComponent
