
import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, Grid, Typography } from '@mui/material'
import { OrdersProps } from './types'
import List from './table'

const OrderList: React.FC<OrdersProps> = ({ type }) => {
  const orders = hooks.useSearchListenerResult('order', 'orders_list')
  return (
    <Box>
      <Grid container rowSpacing={2} justifyContent={'center'}>
        <Grid item xs={11}>
          <Typography variant='h5'>{I18n.t(`order.type.${type}`, { count: -1 })}</Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography fontSize={16}>{I18n.t(`order.type.${type}.subtitle`, { count: _.size(orders) })}</Typography>
        </Grid>
        <Grid item xs={12}>
          <List type={type} />
        </Grid>
      </Grid>
    </Box >
  )
}

export default OrderList
