import React from "react"
import { I18n } from '@front/volcanion'
import { ModelRecord } from "@front/volcanion/types"

import _ from "lodash"

import { FormFactory } from "@front/squirtle"

import { AccountProps } from './types'

const Account: React.FC<AccountProps> = ({
  companyuser_filter,
  watchers,
  afterChange
}) => {
  return (
    <FormFactory.AutocompleteField
      name='companyuser_id'
      selectFirst
      disabled={!companyuser_filter}
      config={{
        listener_type: 'search',
        model_name: 'companyuser',
        populate: ['contact', 'user.auth', 'user.customerinfo'],
        forced_filter: companyuser_filter,
        watchers: watchers,
        mutator: (elem: ModelRecord) => ({
          label: _.compact([elem.contact.first_name, elem.contact.last_name]).join(' '),
          ...elem
        })
      }}
      afterChange={afterChange}
      _native={{
        disableUnderline: true,
        label: I18n.t('action.book_for'),
        _render: {
          InputLabelProps: { sx: { fontSize: 16 } },
          sx: { fontSize: 16 },
          variant: 'standard'
        }
      }}
    />
  )
}

export default Account
