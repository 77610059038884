import React, { useState, useCallback } from "react"
import { I18n, CDNImage, GeoUtils } from '@front/volcanion'
import { type IFormFieldOption, type IFormFieldValue, type MaybeMultiple } from "@front/volcanion/types"

import _ from "lodash"

import TimelapseIcon from '@mui/icons-material/Timelapse'
import { Box, Grid, Typography, Icon, Switch, Button, IconButton } from "@mui/material"
import { FormFactory } from "@front/squirtle"

import { AddressesProps } from './types'
import ReservationInfo from './ReservationInfo'
import CustomAddressLabel from './Label'

const getOptionIcon = (option: IFormFieldOption) => {
  if (option?.favorite) return 'favorite'
  if (option?.type === 'home') return 'home'
  else if (option?.type === 'work') return 'work'
  else if (option?.type === 'airport') return 'flighttakeoff'
  else if (option?.type === 'festival') return 'festival'
  else if (option?.type === 'train') return 'train'
  else if (option?.address_id) return 'update'
  else return ''
}

const AddressesSection: React.FC<AddressesProps> = ({
  source,
  destination,
  minDateTime,
  maxDateTime,
  display_duration,
  display_destination,
  display_travel,
  display_returnAt,
  ridemode_options,
  finalRequestedAt,
  afterChangeRequestedAt,
  onChangeReturnAt
}) => {

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>()

  const handleClick = useCallback((e: React.MouseEvent) => {
    !open && setOpen(true)
    setAnchorEl(e?.currentTarget)
  }, [])

  return (
    <Grid container item xs={12} rowSpacing={4}>
      <Grid item xs={12} >
        <Box display='flex' alignItems='center' >
          <Typography variant='h5' color='primary'>{I18n.t('address.source.label.alter')}</Typography>
          <Box hidden={!display_travel}>
            <Box display='flex' mt={1} ml={2} >
              <Typography variant='subtitle2' color='primary'>{I18n.t('travel.come')}</Typography>
              <Switch size='small' onChange={() => onChangeReturnAt(!display_returnAt)} color='secondary' />
              <Typography variant='subtitle2' color='primary'>{I18n.t('travel.come_back')}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs display={'flex'} alignItems={'center'} sx={{ mb: 2 }}>

        <Button onClick={handleClick} sx={{ textTransform: 'none' }} disableFocusRipple>
          <Box display='flex' alignItems='flex-end' >
            <Box>
              <Icon color='primary' fontSize='small'>{'calendar_month_icon'}</Icon>
            </Box>
            <Box ml={1}>
              <Typography ml={1} textAlign='left' sx={{ color: 'black', fontSize: 12, opacity: 0.7 }}>{I18n.t('date.datetime')}</Typography>
              <Box display='flex'>
                <Box display='flex' sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.7)', p: '3px' }} justifyContent='space-between'>
                  <Typography sx={{ color: 'black' }}>{finalRequestedAt}</Typography>
                  <Icon color='primary'>{'arrow_right'}</Icon>
                </Box>
              </Box>
            </Box>
          </Box>
        </Button>

        <Box hidden>
          <FormFactory.DateTimeField
            name='requestedAt'
            afterUserChange={afterChangeRequestedAt}
            _native={{
              label: I18n.t('date.datetime'),
              compact: true,
              _date: {
                minDateTime,
                maxDateTime,
                onClose: () => setOpen(false),
                disablePast: true,
                open,
                slotProps: {
                  popper: {
                    anchorEl
                  }
                }
              }
            }}
          />
        </Box>

        <Box hidden={!display_returnAt || !display_travel}>
          <Box mr={1}><Icon color='primary' fontSize='small'>{'calendar_month_icon'}</Icon> </Box>
          <FormFactory.DateTimeField
            name='returnAt'
            _native={{
              label: I18n.t('date.returnAt'),
              compact: true,
              _date: {
                disablePast: true
              }
            }}
          />
        </Box>

        <Box hidden={!display_duration}>
          <Box display='flex' alignItems='flex-end' sx={{ minWidth: 120 }}>
            <Box mr={1}><TimelapseIcon color='primary' fontSize='small' /> </Box>
            <FormFactory.MenuSelectorField
              name='ridemode_duration'
              _native={{ label: I18n.t('mad.duration'), variant: 'standard' }}
              options={ridemode_options}
              selectFirst
            />
          </Box>
        </Box>

      </Grid>
      <Grid item xs={12}>
        <Box display='flex' alignItems='flex-end'>
          <Box mr={1}>
            <CDNImage name={'source'} category='map' style={{ width: '24px', height: "24px" }} />
          </Box>
          <FormFactory.AutocompleteField
            name={'source'}
            _native={{
              label: I18n.t('address.source.label', { count: 1 }),
              onInputChange: source?.autocomplete,
              _render: { InputLabelProps: { style: { width: '200%' } } }
            }}
            clearInvalidOption={false}
            labelKeys={['formatted_address']}
            matchKeys={GeoUtils.getAddressKeys()}
            options={source?.options}
            getOptionIcon={getOptionIcon}
            afterUserChange={(value: MaybeMultiple<IFormFieldValue>) => source?.onResolveAddress(value as object)}
          >
            <CustomAddressLabel slot='label_slot' prefix='source' />
          </FormFactory.AutocompleteField>
        </Box>
        <ReservationInfo />
      </Grid>
      <Grid item xs={12} hidden={!display_destination}>
        <Box display='flex' alignItems='flex-end'>
          <Box mr={1}>
            <CDNImage name={'destination'} category='map' style={{ width: '24px', height: "24px" }} />
          </Box>
          <FormFactory.AutocompleteField
            name={'destination'}
            _native={{
              label: I18n.t('address.destination.label', { count: 1 }),
              onInputChange: destination?.autocomplete,
              _render: { InputLabelProps: { style: { width: '200%' } } }
            }}
            clearInvalidOption={false}
            labelKeys={['formatted_address']}
            matchKeys={GeoUtils.getAddressKeys()}
            options={destination?.options}
            getOptionIcon={getOptionIcon}
            afterUserChange={(value: MaybeMultiple<IFormFieldValue>) => destination?.onResolveAddress(value as object)}
          >
            <CustomAddressLabel slot='label_slot' prefix='destination' />
          </FormFactory.AutocompleteField>
        </Box>
      </Grid>
    </Grid >
  )
}

export default AddressesSection
