import React, { useCallback } from 'react'
import { FormHooks, CDNSVG, VTypes } from '@front/volcanion'

import _ from 'lodash'

import { Typography, Box, IconButton } from '@mui/material'
import { LabelProps, OptionAutocomplete } from './types'

const Label: React.FC<LabelProps> = ({ label, authorized_pois, prefix }) => {
  const [{ source_option, destination_option, source_trigger, destination_trigger }, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const { includeFavorite, poiTypes } = (prefix === 'source' ? source_option : destination_option) as OptionAutocomplete

  const onClick = useCallback((poitype_id: string) => {
    const state_prefix = prefix === 'source' ? 'source_option' : 'destination_option'
    const state_trigger = prefix === 'source' ? 'source_trigger' : 'destination_trigger'
    const state_trigger_value = prefix === 'source' ? source_trigger : destination_trigger

    if (poitype_id && !_.includes(poiTypes, poitype_id)) {
      setFormState(`${state_prefix}.includePOI`, true)
      setFormState(`${state_prefix}.includeFavorite`, false)
      setFormState(`${state_prefix}.includeRecent`, false)
      setFormState(`${state_prefix}.poiTypes`, [poitype_id])
    }
    else if (!!includeFavorite) {
      setFormState(`${state_prefix}.includePOI`, false)
      setFormState(`${state_prefix}.includeFavorite`, false)
      setFormState(`${state_prefix}.includeRecent`, false)
      setFormState(`${state_prefix}.poiTypes`, undefined)
    } else {
      setFormState(`${state_prefix}.includePOI`, true)
      setFormState(`${state_prefix}.includeRecent`, true)
      setFormState(`${state_prefix}.includeFavorite`, true)
      setFormState(`${state_prefix}.poiTypes`, undefined)
    }
    setFormState(state_trigger, !state_trigger_value)
  }, [poiTypes, includeFavorite, setFormState, prefix, source_trigger, destination_trigger])


  const getColor = useCallback((poitype_id: string) => {
    if (poitype_id && _.includes(poiTypes, poitype_id))
      return 'secondary.main'
    else if (!!includeFavorite && !poitype_id)
      return 'secondary.main'
    else
      return 'primary.main'
  }, [poiTypes, includeFavorite, setFormState])

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mb={4} pr={2}>
      <Typography>{label}</Typography>
      <Box display='flex'>
        {_.map(authorized_pois, ({ poitype_id, icon }, key) =>
          <Box key={key}>
            <IconButton onClick={() => onClick(poitype_id)}>
              <CDNSVG
                category={'icons'}
                variant='poi'
                name={poitype_id === 'other' ? 'star' : icon}
                style={{ width: 20, height: 20 }}
                fill={getColor(poitype_id)}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Label
