import React, { useCallback } from "react"
import { hooks } from '@front/volcanion'

import _ from "lodash"

import { Box, Icon, IconButton, Typography, Button } from "@mui/material"

import { AddresseCardProps } from "./types"
import { GeoUtils } from "@front/volcanion/utils"
import type { AddressObject } from "@front/volcanion/utils/geo"

const AddresseCard: React.FC<AddresseCardProps> = ({ address_id }) => {
  const [removeAddress] = hooks.useModelFunction('address', 'modify')
  const [, { openDialog }] = hooks.useDialogs()
  const [address] = hooks.useModel('address', [address_id], { single: true })
  const formatted_address = GeoUtils.getFormattedAddress(address as AddressObject)
  const type = !!address?.type ? address?.type : 'favorite' as string

  const onOpen = useCallback(() => {
    openDialog('address_modify', {
      formProps: {
        config: {
          model_name: 'address',
          ids: [address_id]
        },
        initialState: {
          isReadOnly: false
        }
      }
    })
  }, [openDialog, address_id, type, address])

  const onDelete = useCallback(() => {
    const newFavorite = type === 'favorite' ? false : undefined
    removeAddress({ filter: { address_id }, updates: { type: null, favorite: newFavorite } })
  }, [openDialog, type, address_id])


  return (
    <Button
      fullWidth
      size='large'
      variant='text'
      sx={{ flex: 1, height: '100%', boxShadow: 3, textTransform: 'unset' }}
      onClick={onOpen}
      startIcon={<Icon>{type}</Icon>}
      endIcon={<Box display='flex' flexDirection='column'>
        <IconButton onClick={onOpen}>
          <Icon color='secondary'>{'mode_edit_outlined'}</Icon>
        </IconButton>
        <IconButton onClick={onDelete}>
          <Icon color='primary'>{'delete_outlined'}</Icon>
        </IconButton>
      </Box>
      }
    >
      <Typography fontSize={'1vw'}>{formatted_address}</Typography>
    </Button >

  )
}


export default AddresseCard
