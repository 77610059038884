import React from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], {
    single: true, populate: [
      'commercial_package',
      'assigned_transport.driver.info',
      'assigned_transport.vehicle.info',
      'assigned_transport.vehicle.info.model.brand',
      'assigned_transport.license',
      'driver_opts',
      'vehicle_opts'
    ]
  })

  const package_icon = order?.commercial_package?.client_type_icon
  const package_name = order?.commercial_package?.name_translated

  const vehicle = !!order?.assigned_transport
    ? _.compact([
      order?.assigned_transport?.vehicle?.info?.model.name,
      order?.assigned_transport?.vehicle?.info?.model?.brand?.name,
      order?.assigned_transport?.vehicle?.info?.color,
      '-',
      order?.assigned_transport?.vehicle?.info?.plaque
    ]).join(' ')
    : null

  const driver_opts = order?.driver_opts
  const vehicle_opts = order?.vehicle_opts

  const mergedProps = {
    package_icon,
    package_name,
    vehicle,
    driver_opts,
    vehicle_opts
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
