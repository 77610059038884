
import React from 'react'
import { withSlot, I18n } from '@front/volcanion'

import _ from 'lodash'
import { Box } from '@mui/material'

import { DialogForm } from '@front/squirtle'
import { DecoratorContentSlot } from '@front/squirtle/Components_ts/Dialog/types'

import { PopupsProps } from './types'
import Companyservice from '../../Service/'

const CompanyserviceComponentSlot = withSlot<{}, DecoratorContentSlot>(props => <Companyservice {...props} />)

const PopupComponent: React.FC<PopupsProps> = ({ formToSubmitCompanyuserService }) => {

  return (
    <Box>
      <DialogForm
        name={'create_service'}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        _button_confirm={{ label: I18n.t('action.create') }}
        formProps={{
          formToSubmit: formToSubmitCompanyuserService,
          listener_type: 'form',
          config: {
            model_name: 'companyuserservices',
            ids: []
          }
        }}
        maxWidth='xl'
      >
        <CompanyserviceComponentSlot slot='dialog_content' />
      </DialogForm>
    </Box >
  )
}

export default PopupComponent
