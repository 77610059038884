
import React, { useCallback } from 'react'

import _ from 'lodash'

import { FormFactory } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'
import { hooks } from '@front/volcanion'

const SearchComponent: React.FC = () => {
  const [company_id] = hooks.useGlobalState('persist.company_id')
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])
  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(company_id as string), [company_id])
  return (
    <FormFactory.Form
      name={`dashboard`}
      listener_type='form'
      getEmptyForm={getEmptyForm}
      getEmptyState={getEmptyState}
      config={{
        model_name: 'order',
        ids: []
      }}
    >
      <FormContent />
    </FormFactory.Form>
  )
}

export default SearchComponent
