
import React from 'react'
import { FormHooks, withSlot, I18n } from '@front/volcanion'

import _ from 'lodash'
import { Box } from '@mui/material'

import { DialogConfirmation } from '@front/squirtle'
import { DecoratorContentSlot } from '@front/squirtle/Components_ts/Dialog/types'

import { PopupsProps } from './types'

import ServiceForm from '../../Service/form'

const ServiceSlot = withSlot<{}, DecoratorContentSlot>(props => <ServiceForm {...props} />)

const PopupComponent: React.FC<PopupsProps> = props => {
  const requestSubmit = FormHooks.useGlobalFormSubmit('servicet_detail_create')
  return (
    <Box>
      <DialogConfirmation
        name={'create_service'}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ size: 'large' }}
        maxWidth='lg'
        confirmLabel={I18n.t('action.create')}
        cancelLabel={I18n.t('action.cancel')}
        onConfirm={() => requestSubmit()}
      >
        <ServiceSlot slot='dialog_content' />
      </DialogConfirmation>
    </Box >
  )
}

export default PopupComponent
