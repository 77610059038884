import { I18n } from '@front/volcanion'

class Callbacks {
  static onSubmitHandler(signin) {
    return async function onSubmit(instance, submit_map) {
      const isForgot = instance.getStateValue('isForgot')

      if (!!isForgot) return
      await signin({ email: instance.getFieldValue('email'), password: instance.getFieldValue('password') })
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(form, err, submit_map) {
      if (_.includes(['PASSWORD_INCORRECT'], err.getCode()))
        return [I18n.t('password.incorrect'), { variant: 'error' }]
      else return [I18n.t('notification.order.failed'), { variant: 'error' }]

    }
  }

  static getEmptyStateHandler() {
    return function getEmptyState() {
      return { isForgot: false, step: 0 }
    }
  }
}

export default Callbacks
