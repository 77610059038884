import React from "react"
import { I18n, withSlot } from "@front/volcanion"

import _ from "lodash"

import { Box, Grid, Icon, Typography, Tooltip } from "@mui/material"
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'

import { InformationProps } from './types'
import { FormFactory } from "@front/squirtle"
import { DecoratorElemSlot } from "@front/squirtle/FormFactory/MenuSelector/types"
import { DecoratorLabelSlot } from "@front/squirtle/FormFactory/Text/types"

const DecoratorElem = withSlot<{}, DecoratorElemSlot>(({ option }) => (
  <Typography color='primary' >{option?.label}</Typography>
))

const CustomPassengerLabel = withSlot<{}, DecoratorLabelSlot>(({ label }) => (
  <Tooltip title={I18n.t("book.guest_disabled")} placement='left' >
    <Box display='flex'>
      <Typography color='primary' fontSize={16}>{label}</Typography>
      <Box ml={1}>
        <Icon color='secondary' fontSize='small'>{'help'}</Icon>
      </Box>
    </Box>
  </Tooltip>
))

const InformationSection: React.FC<InformationProps> = ({ comment, hasError, max_load, has_luggage, guest_booking }) => (
  <Grid container item rowSpacing={4}>
    <Grid item xs={12}>
      <Typography variant={'h5'} color='primary'>{I18n.t('info.order.label', { count: -1 })}</Typography>
    </Grid>
    <Grid item xs={12} container spacing={3}>
      <Grid item xs display='flex' alignItems='flex-end'>
        <Box mr={{ xs: 1, sm: 1 }}><Icon color='primary' fontSize='small'>{'person'}</Icon> </Box>
        <FormFactory.TextField
          name='passenger_name'
          _native={{ label: I18n.t('passenger.label', { count: 1 }) }}
          disabled={!guest_booking}
        >
          {!guest_booking && <CustomPassengerLabel slot='label_slot' />}
        </FormFactory.TextField>
      </Grid>
      <Grid item xs display='flex' alignItems='flex-end'>
        <Box mr={{ xs: 1, sm: 1 }}><Icon color='primary' fontSize='small'>{'call_icon'}</Icon> </Box>
        <Tooltip title={I18n.t("help.question")} >
          <FormFactory.TextField
            name='passenger_number'
            _native={{ label: I18n.t('gsm.label', { count: 1 }) }}
            disabled={!guest_booking}
          />
        </Tooltip>
      </Grid>
    </Grid>

    <Grid item xs={12} container spacing={3}>
      <Grid item xs display='flex' alignItems='flex-end'>
        <Box mr={{ xs: 1, sm: 1 }}><Icon color='primary' fontSize='small'>{'group'}</Icon> </Box>
        <FormFactory.MenuSelectorField
          name='load_count'
          options={Array.from(Array(max_load).keys()).map((elem, i) => ({ label: i + 1, value: i + 1 }))}
          _native={{ label: I18n.t('count.passenger'), variant: 'standard' }}
        >
          <DecoratorElem slot='decorator' />
        </FormFactory.MenuSelectorField>
      </Grid>
      <Grid item xs display='flex' alignItems='flex-end'>
        <Box mr={{ xs: 1, sm: 1 }}><Icon color='primary' fontSize='small'>{!!has_luggage ? 'luggage' : 'no_luggage'}</Icon> </Box>
        <FormFactory.OptionSelectorField
          name='has_luggage'
          options={[{ label: '', value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('luggage.label.alter_other')
          }}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} display='flex' alignItems='flex-end'>
      <Box mr={{ xs: 1, sm: 1 }}><CommentOutlinedIcon color='primary' fontSize='small' /> </Box>
      <FormFactory.TextField
        name='comment'
        _native={{
          label: I18n.t('comment.driver'),
          multiline: true,
          _adornment: { position: 'end', sx: { position: 'absolute', right: 5, bottom: 15 } },
          slotProps: {
            input: {
              endAdornment: <Typography fontSize={16} color={!!hasError ? 'error' : 'text'}>{`${comment?.length || 0}/250`}</Typography>,
            }
          }
        }}
        validate={(new_value) => {
          if (new_value?.length > 250)
            return [{
              code: 'length',
              message: I18n.t('error.commet.to_long')
            }]
          return []
        }}
      />
    </Grid>
  </Grid>
)

export default InformationSection
