import React from 'react'
import { I18n, withSlot } from '@front/volcanion'

import { Box, Grid, Link, Typography } from '@mui/material'
import { FormFactory } from '@front/squirtle'
import LoadingButton from '@mui/lab/LoadingButton'

const CustomLabel = withSlot(({ option, CLIENT_CGU_PAGE }) => (
  <Typography component='div' nowrap sx={{ whiteSpace: 'nowrap', display: 'flex', mr: 1 }}>
    <Typography color='text.label' fontSize={14}>{I18n.t('term.general')}</Typography>
    <Link href={CLIENT_CGU_PAGE} target={'_blank'} sx={{ ml: 1 }} nowrap>
      <Typography color={'primary'} fontSize={14}>{I18n.t('term.cgu')} </Typography>
    </Link>
  </Typography>
))

const Title = props =>
  <Grid item xs={9}>
    <Typography sx={{ fontSize: 'h4.fontSize', color: 'primary.main' }}> {I18n.t('login.register.steps.second')} </Typography>
    <Typography sx={{ ml: 2, fontSize: 12, color: 'text.label', fontWeight: 200 }}> {I18n.t('login.register.finalize')} </Typography>
  </Grid>

const Inputs = props =>
  <Grid item container xs={10} justifyContent='center' spacing={1} rowSpacing={2}>
    <Grid item xs={6}>
      <FormFactory.TextField
        name='first_name'
        _native={{
          autoFocus: true,
          label: I18n.t('user.first_name'),
          variant: 'outlined',
          size: 'medium'
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <FormFactory.TextField
        name='last_name'
        _native={{
          autoFocus: true,
          label: I18n.t('user.last_name'),
          variant: 'outlined',
          size: 'medium'
        }}
      />
    </Grid>
    <Grid item xs={9}>
      <FormFactory.PhoneInputField
        name='gsm'
        _native={{
          label: I18n.t('gsm.label.short'),
          variant: 'outlined',
          defaultCountry: 'fr',
          size: 'medium'
        }}
      />
    </Grid>
  </Grid>

const Footer = ({ isSubmitting, onRequestSubmit, CLIENT_COMMERCIAL_NAME, CLIENT_CGU_PAGE, ...props }) =>
  <Grid container item xs={11} rowSpacig={2}>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <FormFactory.OptionSelectorField name={'notification'} >
          <CustomLabel slot='label_option_slot' CLIENT_CGU_PAGE={CLIENT_CGU_PAGE} />
        </FormFactory.OptionSelectorField>
        <LoadingButton onClick={onRequestSubmit} loading={!!isSubmitting} variant='outlined'>
          <Typography color={'primary'} variant={'h6'} sx={{ textTransform: 'none' }}>  {I18n.t('action.signup')} </Typography>
        </LoadingButton>
      </Box>
    </Grid>

  </Grid >

const SecondStep = props =>
  <Grid container item xs={12} justifyContent='center' rowSpacing={2}>
    <Title />
    <Inputs />
    <Footer {...props} />
  </Grid>

export default React.memo(SecondStep)
