
import React from 'react'
import { I18n } from '@front/volcanion'

import _ from 'lodash'

import { Box, Icon, Typography, Tooltip, Badge } from '@mui/material'
import { GlobalInformationProps } from './types'

const CustomComponent: React.FC<{ value: string, icon: string, mr?: number, ml?: number }> = ({ value, icon, mr, ml }) => (
  <Box display='flex' mr={mr} ml={ml}>
    <Box mr={1}><Icon>{icon}</Icon></Box>
    <Typography fontSize={16}>{value}</Typography>
  </Box>
)

const GlobalInformationComponent: React.FC<GlobalInformationProps> = ({
  requestedAt,
  passenger_name,
  commercial_formula,
  company,
  total,
}) => {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
      <Box display='flex' alignItems='flex-end'>
        <CustomComponent value={requestedAt} icon={'watch_later'} mr={1} />
        <CustomComponent value={passenger_name} icon={'person'} mr={1} ml={1} />
        <CustomComponent value={commercial_formula} icon={'insert_drive_file'} mr={1} ml={1} />
        {!!company && <CustomComponent value={company} icon={'folder'} ml={1} />}
      </Box>
      <Box display='flex' alignItems='flex-end'>
        <Tooltip title='TODO' placement='right-start'>
          <Badge badgeContent={<Box fontSize={14}><Icon color="primary" fontSize={'inherit'}>{'info'}</Icon></Box>}>
            <Box display='flex'>
              <Typography fontSize={20} >{[I18n.t('total.label', { count: 1 }), ':'].join(' ')}</Typography>
              <Typography ml={1} fontSize={20}>{total}</Typography>
            </Box>
          </Badge>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default GlobalInformationComponent
