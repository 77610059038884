export default {
  account: {
    b2b: 'Business account',
    my: 'My account',
    private: 'Private account'
  },
  action: {
    add_option: '+ add an option',
    apply: 'Apply',
    book: "Order",
    book_for: "Order for",
    book_new: "New order",
    cancel: 'Cancel',
    confirm: 'Confirm',
    create: 'Create',
    delete_account: 'Delete my account',
    logout: 'Logout',
    order_detail: 'Show order details',
    return: 'Return',
    modify: 'Modify',
    login: 'Login',
    contact_us: 'Contact us',
    signup: 'Sign up',
    send: 'Send',
    save: 'Save'
  },
  admin: {
    label: {
      one: 'Administrator',
      other: 'Administrators',
      short: 'Admin'
    },
    space: 'Administrator space'
  },
  address: {
    label: {
      one: 'Address',
      other: 'Addresses',
      work: 'work address',
      home: 'home address',
      favorite: 'favorite address'
    },
    add: {
      click: 'Click here to add your',
      common: 'Add an address',
      favorite: 'Add your favorite address',
      work: 'Add your work address',
      home: 'Add your home address'
    },
    billing: 'Billing address',
    destination: {
      label: {
        one: "Destination",
        alter: 'Destination'
      },
      unefined: 'Address undefined',
      not_found: {
        home: 'No home address is currently defined. Would you like to do it now?',
        work: 'No work address is currently defined. Would you like to do it now?',
        b2b: 'No work address is currently defined. Would you like to do it now?'
      }
    },
    home: 'Home address',
    modify: {
      common: "Add an address",
      favorite: 'Modify your favorite address',
      work: 'Modify your work address',
      home: 'Modify your home address',
    },
    my: 'My addresses',
    no_destination: 'No destination',
    reservationinfo: {
      pickup: "{{type}} - Enter your {{name}} and your {{origin}} to allow the driver to anticipate delays",
      source: "Where would you like to be picked up?"
    },
    source: {
      label: {
        alter: "Pick-up",
        one: 'Departure'
      }
    },
    type: {
      label: {
        one: 'Address type'
      },
      home: 'Home',
      work: 'Work',
      favorite: 'Favorite'
    },
    same: 'Please note the source and destination are identical'
  },
  all: 'All',
  are_you_ready: "Are you ready?",
  book: {
    label: {
      one: 'Order',
      other: 'Orders'
    },
    guest: "Guest order",
    guest_disabled: "You do not have the rights to order for another person",
    home: "Leave now from my home",
    quick: 'Quick order',
    service: 'Leave now from the address registered on the business account',
    work: "Leave now from the office",
  },
  call: {
    label: {
      one: 'Call',
      other: 'Calls'
    },
    client_driver: 'Client to driver',
    driver_client: 'Driver to client'
  },
  card: {
    label: '•••• •••• •••• {{tail}}',
    holder: 'Holder',
    tail: 'Card number',
    expiry: 'Expiry date',
    add: 'Add payment card',
    name: 'Card name (optional)',
    detail: 'Card details',
    added: 'Card added successfully',
    deleted: 'Card deleted successfully',
    updated: 'Card updated successfully'
  },
  commercial_formula: {
    label: {
      one: 'Service level',
      other: 'Service levels',
    }
  },
  commercial_package: {
    label: {
      one: 'Class',
      other: 'Classes'
    }
  },
  company: {
    label: {
      one: 'Company',
      other: 'Companies'
    },
    account: {
      label: {
        one: 'Company account',
        other: 'Company accounts'
      }
    },
    user: 'Company user',
    contract_services: 'Contracts / Service'
  },
  companycontract: {
    client_id: 'Contract number',
    label: {
      one: 'Contract',
      other: 'Contracts'
    },
    count: "Contracts - {{contracts_count}}"
  },
  companyservice: {
    associated: {
      label: {
        one: 'Associated service',
        other: 'Associated services'
      }
    },
    client_id: 'Service code',
    create: 'Service creation',
    label: {
      one: 'Service',
      other: 'Services'
    },
    link: {
      one: 'Linked service',
      other: 'Linked services'
    },
    add: "Add a service"
  },
  companyuser: {
    create: 'Create a user',
    label: {
      one: 'User',
      other: 'Users'
    }
  },
  count: {
    passenger: "Number of passengers",
  },
  comment: {
    label: {
      one: 'Comment',
      other: 'Comments'
    },
    admin: 'Administrator comment',
    driver: 'Driver comment',
    length: {
      default: 'Comment: {{count}}/250',
      driver: 'Comment to the driver (any details regarding an address or a passenger): {{count}}/250'
    },
    too_long: 'Comment too long',
  },
  curency: '$',
  customer_ref: {
    label: {
      one: 'Billing reference',
      other: 'Billing references',
    }
  },
  date: {
    activation: 'Activation date',
    datetime: 'Date & Time',
    end: 'End date',
    returnAt: 'Return date',
    start: 'Start date',
    waiting_time_estimated: 'Estimated waiting time',
    month: {
      current: 'Current month',
      last: 'Last month'
    },
    day: {
      last: '{{count}} lasrt days'
    },
    year: {
      current: 'From the gebin of the year',
      specific: 'Year {{count}}'
    }
  },
  day: {
    one: 'Day',
    other: 'Days'
  },
  dashboard: {
    order: {
      complete: 'Complete',
      complete_ca: 'Complete CA',
      complete_ci: 'Complete CI',
      cancel: 'Cancel',
      cancel_ca: 'Cancel CA',
      cancel_ci: 'Cancel CI',
      not_loaded: 'Not loaded'
    }
  },
  download: {
    bill: 'Download the invoice',
    fail: 'Download failed'
  },
  drawer: {
    book: {
      label: 'Order'
    },
    admin: {
      label: 'Administrator space',
      dashboard: 'Dashboard',
      contracts: 'Contracts',
      users: 'Users',
      in_progress: 'Ongoing rides',
      history: 'History',
    },
    order: {
      label: 'My orders',
      in_progress: 'In progress',
      planned: 'Upcoming',
      history: 'History'
    }
  },
  driver: {
    meet: 'Your driver will arrive on',
  },
  email: {
    label: {
      one: 'Email',
      other: 'Emails'
    },
    pro: 'Professional email'
  },
  error: {
    comment: {
      to_long: 'Comment too long.'
    },
    order: {
      unauthorized: {
        create: 'You are not allowed to book a ride with this company account. Please contact your administrator for more information.',
        cancel: 'You are not allowed to cancel a ride for this company account. Please contact your administrator for more information.'
      }
    }
  },
  event: {
    order: {
      order_pooled: 'Sent to pool',
      order_quotation: 'Quotation recording',
      order_created: 'Order recording',
      order_updated: 'Order modification',
      order_canceled: 'Order canceled',
      order_attributed: 'Order attributed',
      order_not_served: 'Ride not served',
    }
  },
  ever: 'Always',
  help: {
    question: 'Need help?'
  },
  hour: {
    one: 'Hour',
    other: 'Hours',
    destination: 'Estimated arrival time',
    source: 'Departure time'
  },
  info: {
    additional: {
      label: {
        one: "Additional information",
        other: "Additional information"
      }
    },
    general: {
      label: {
        one: 'General information',
        other: 'General information'
      }
    },
    label: {
      one: 'Information',
      other: 'Information'
    },
    order: {
      label: {
        one: "Ride information",
        other: "Ride information"
      },
      new: 'New Order',
      detail: 'Show details'
    }
  },
  invoice: {
    error: 'We encountered an error while sending the invoice. Please contact us at {{client_email}} to receive it.',
    send: 'Sending receipt',
    success: 'The invoice has been sent to your email address.'
  },
  login: {
    label: {
      one: 'Login',
      other: 'Logins'
    },
    register: {
      label: 'Sign up',
      steps: {
        first: 'Sign up',
        second: 'Create your account',
        third: 'Phone number verification',
        fourth: 'Add a card'
      },
      confirm: 'Please confirm your phone number by entering the code received',
      finalize: 'Complete your registration by providing the missing information to order your taxis.'
    }
  },
  luggage: {
    label: {
      one: 'Luggage(s)',
      other: 'Luggage',
      alter_one: 'Large luggage',
      alter_other: 'Large luggage'
    }
  },
  mad: {
    duration: 'Duration of provision'
  },
  meeting_point: {
    label: {
      one: 'Meeting point',
      other: 'Meeting points',
      alter: 'Meeting point ({{name}})'
    }
  },
  month: {
    one: 'Month',
    other: 'Months'
  },
  name: {
    label: {
      one: 'Name',
      other: 'Names'
    },
    first: 'First name',
    last: "Last name"
  },
  never: 'Never',
  none: 'None',
  none_fem: 'None',
  option: {
    driver: {
      label: {
        one: 'Driver skill',
        other: 'Driver skills'
      },
    },
    selection: 'Option selection:',
    vehicle: {
      label: {
        one: 'Vehicle option',
        other: 'Vehicle options'
      }
    }
  },
  order: {
    label: {
      one: 'Ride',
      other: 'Rides',
      alter: 'Ride(s)'
    },
    number: 'Nombre de courses',
    created: 'Booking registered',
    client_id: 'Ride number',
    guest_booking: 'Guest orders',
    my: {
      other: 'My rides'
    },
    self_booking: 'Personal order',
    status: {
      label: 'Order status',
      created: 'Registered',
      standby: 'Dispatch in progress',
      attributed: 'Attributed',
      canceled: 'Canceled',
      complete: 'Closed',
      register_planned_reservation: 'Reservation registered',
      register_immediate_reservation: 'Immediate reservation registered',
      dispatch_in_progress: 'Dispatch in progress',
      transport_in_progress: 'Assigned',
      paid: 'Payment',
      quotation: 'Quotation',
      not_served: 'Not served',
      approach: 'Approaching'
    },
    schedule: {
      immediate: {
        label: {
          one: "Immediate ride",
          other: "Immediate rides"
        }
      }
    },
    saved: 'Reservation registered',
    type: {
      current: {
        one: 'Current ride',
        other: 'Current rides',
        alter: 'Current ride(s)',
        subtitle: '{{count}} ongoing orders',
        short: 'In progress'
      },
      planned: {
        one: 'Planned ride',
        other: 'Planned rides',
        alter: 'Planned ride(s)',
        subtitle: '{{count}} upcoming orders',
        short: 'Upcoming'
      },
      history: {
        one: 'Past ride',
        other: 'Past rides',
        alter: 'Past ride(s)',
        subtitle: '{{count}} past orders',
        short: 'History'
      }
    }
  },
  passenger: {
    label: {
      one: 'Passenger',
      other: 'Passengers'
    }
  },
  password: {
    label: {
      one: 'Password',
      other: 'Passwords',
      old: 'Old password',
      new: 'New password',
      current: "Current password"
    },
    forgot: {
      label: {
        one: "Forgot password"
      },
      title: 'Forgot your password?',
      description: 'Please enter the email you used to sign up to reset your password.',
      success: 'Reset email sent successfully'
    },
    required: 'Password required',
    modify: 'Modify my password',
    add: 'Set a password',
    rules: {
      length: 'At least 8 characters',
      upper: 'At least one uppercase',
      lower: 'At least one lowercase',
      number: 'At least one number'
    },
    complexity: {
      level: 'Password complexity:',
      weak: 'Weak',
      good: 'Average',
      strong: 'Strong'
    },
    change: {
      success: 'Password changed successfully.',
      error: {
        incorrect: 'Incorrect old password.'
      }
    }
  },
  gsm: {
    label: {
      one: 'Phone number',
      old: 'Old phone number',
      new: 'New phone number'
    },
    modify: 'Modify my number',
    code_step: "Please enter the verification code sent by SMS or voice call to",
    not_receive: "Didn't receive a code?",
    change: {
      first_step: 'Please enter your new phone number. A 4-digit code will be sent by SMS to confirm the change',
      second_step: 'A 4-digit code has been sent to the following phone number: {{new_gsm}} Please enter this code below to validate the change of number',
      done: 'Request to change phone number sent.'
    },
    link: {
      resend: {
        by_gsm: "Click here to receive a new code by SMS",
        by_vocal: "Click here to receive a new code by voice call"
      },
      change: {
        gsm: "Change phone number",
      }
    }
  },
  payment: {
    allowed: 'Allowed',
    allow_deferred: 'Allow deferred billing',
    deferred: 'Deferred billing',
    forbidden: 'Forbidden',
    mandatory: 'Mandatory',
    mode: 'Payment mode',
    add_card: 'Click here to add a credit card',
    added: 'Card added successfully',
    deleted: 'Card deleted successfully',
    updated: 'Card updated successfully',
    my_card: {
      one: 'My payment card',
      other: 'My payment cards'
    }
  },
  placement: {
    up: {
      label: {
        one: 'up',
        other: 'ups'
      }
    },
    down: {
      label: {
        one: 'down',
        other: 'downs'
      }
    }
  },
  preference: {
    label: {
      one: 'Preference',
      other: 'My travel preferences'
    },
    air_conditioning: 'Air conditioning',
    armrest: {
      label: {
        one: 'Armrest',
        other: 'Armrests'
      }
    },
    comment_placeholder: 'Details about the address or passenger, for the driver',
    open_door: 'Open door',
    open_door_depends: 'If the driver arrives before me',
    open_window: 'Open windows',
    preferred_music: 'Sound ambiance',
    success: 'Preferences updated successfully',
    temperature: {
      label: {
        one: 'Temperature',
        other: 'Temperatures'
      },
      celcius: 'Temperature (°C)'
    },
    unlimited: 'Unlimited',
    waiting_duration: 'Driver waiting time (minutes)'
  },
  prefix: {
    at: 'at',
    at_alter: 'at',
    at_alter_bis: 'at',
    no: 'no',
    yes: 'yes'
  },
  ref: {
    billing: {
      mandatory: 'Mission code required',
      label: {
        one: 'Billing reference',
        other: 'Billing references',
        required: 'Billing reference *'
      },
      fill_ref: 'Enter billing ref'
    },
    options: {
      optional: 'Optional',
      required: 'Mandatory',
      suggested: 'Suggested'
    },
    authorized: {
      list: 'Authorized mission codes'
    }
  },
  resend: {
    receipt: 'Resend the receipt'
  },
  ridemode: {
    label: {
      one: 'Service',
      other: 'Services'
    }
  },
  schedule: {
    planned: 'In advance',
    immediate: 'Immediate',
    planned: {
      one: 'Planned',
      other: 'Planned',
      alter: 'In advance'
    },
    immediate: {
      one: 'Immediat',
      other: 'Immediats',
      alter: 'Immediate'
    },
    type: 'Schedule type'
  },
  setting: {
    open: 'Open settings'
  },
  status: {
    label: {
      one: 'Status',
      other: 'Statuses'
    },
    active: 'Active',
    archived: 'Archived',
    deleted: 'Terminated',
    inactive: 'Inactive',
    suspended: 'Suspended',
  },
  term: {
    general: 'By continuing, you accept the',
    cgu: ' General Terms of Use.',
    notification: 'I would like to receive news and updates from {{name}}',
    signup: '{{name}} uses your data to improve its services and provide you with the best possible experience. You have the right to access and rectify the information concerning you, the right to delete your account, and the right to object to the use of your data for commercial purposes. You can exercise your rights through the app or by contacting us at'
  },
  transport: {
    status: {
      label: 'Transport status',
      approach: 'Approaching',
      attributed: 'Attributed',
      at_dst: 'Delivery in progress',
      at_src: 'On site',
      canceled: 'Deleted',
      created: 'Registered',
      complete: 'Completed',
      in_progress: 'In progress',
      none: '-',
      not_loaded: 'Not loaded',
      not_served: 'Not served',
      off_board: 'Payment',
      on_board: 'On the way',
      paid: 'Payment',
      planned: 'Planned',
      quotation: 'Quotation',
      standby: 'Dispatch in progress',
      started: 'Approaching'
    }
  },
  travel: {
    come: "One way",
    come_back: "Round trip"
  },
  total: {
    label: {
      one: 'Total',
      other: 'Totals'
    },
    spent: "Total cost",
    price_estimated: 'Estimated price',
    price: 'Total price',
    order: "Total orders"
  },
  unlimited: 'Unlimited',
  user: {
    label: {
      one: 'User',
      other: 'Users'
    },
    count: 'Users - {{user_count}}',
    number: 'Users number',
    profile: 'Profile'
  },
  validation: {
    ridemode: {
      distance: {
        min: "The distance between the pick-up and drop-off locations indicated is too short {{distance}}km. Our drivers can only accompany you for a minimum distance of {{min_distance}}km. Walking or using public transport is sometimes a good idea, it's good for health and the environment.",
        max: "The distance between the pick-up and drop-off locations indicated is too long {{distance}}km. Our drivers can only accompany you for a maximum distance of {{max_distance}}km. We cannot fulfill your request.",
      },
      duration: {
        min: '',
        max: '',
      },
      night: {
        min: "The duration of stay is too short {{nights}} night(s). Our drivers can accompany you for a minimum of {{min_night}} days.",
        max: "The duration of stay is too long {{nights}} night(s). Our drivers can only accompany you for a maximum of {{max_night}} consecutive days.",
      },
      stay: {
        min: "Sorry, the desired stay duration is too short for the selected destination. We would have liked to serve you but teleportation does not exist yet.",
        date: "Sorry, the necessary rest time for our drivers between the outward and return journeys of your travel is insufficient. We cannot fulfill your request.",
      },
    },
    traffic: {
      warning: "Warning! Your order is in a time slot {{requestedAt}}, with high demand or with a risk of heavy traffic for the arrival of your taxi at the meeting point. Please allow {{margin}} additional minutes."
    },
    authorization: {
      error: 'Order prohibited for {{requestedAt}}.'
    }
  },
  year: {
    one: 'Year',
    other: 'Years'
  }
}
