
import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, Divider, Grid, Typography, Icon } from '@mui/material'

import List from './table'

const ContractList: React.FC = props => {
  const contracts = hooks.useSearchListenerResult('companycontract', 'companycontract_list')
  return (
    <Box p={4}>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Box display='flex' alignItems='center'>
            <Icon>{'description'}</Icon>
            <Typography ml={1} variant='h5'>{I18n.t('companycontract.count', { contracts_count: _.size(contracts) })}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List />
          <Divider />
        </Grid>
      </Grid>
    </Box >
  )
}

export default ContractList
