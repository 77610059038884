import { I18n } from '@front/volcanion'

class Callbacks {
  static onRequestSubmitHandler(setState, signin, openNotification, email, password, score) {
    return async function onRequestSubmit() {
      if (score !== 4) {
        openNotification(I18n.t('password.not_match_rules'), { variant: 'error' })
        return
      } else if (!email.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
        openNotification(I18n.t('email.incorrect'), { variant: 'error' })
        return
      }
      try {
        await signin({ email, password })
      } catch (err) {
        if (_.includes(['RECORD_NOTFOUND'], err.getCode())) {
          setState('step', 1)
        } else {
          openNotification(I18n.t('email.already_use'), { variant: 'error' })
        }
      }
    }
  }
}

export default Callbacks
