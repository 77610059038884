
import React, { useCallback } from 'react'
import { FormHooks, I18n, VTypes } from '@front/volcanion'
import _ from 'lodash'

import { Box, Icon, Button } from '@mui/material'

const ModifyButton: React.FC = props => {
  const [{ isReadOnly, record_id }, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const onSubmit = FormHooks.useFormSubmit()

  const onEnableEdit = useCallback(() => {
    !!isReadOnly && setFormState('isReadOnly', !isReadOnly)
    !isReadOnly && onSubmit()
  }, [onSubmit, setFormState, isReadOnly])

  const onCancelEdit = useCallback(() => {
    setFormState('isReadOnly', true)
  }, [setFormState, isReadOnly])

  return (
    <Box display='flex'>
      <Box hidden={!!isReadOnly}>
        <Button
          onClick={onCancelEdit}
          endIcon={<Icon fontSize='small'>{'close'}</Icon>}
          sx={{ textTransform: 'unset' }}
          variant='outlined'
          color='error'
          size='small'
        >
          {I18n.t('action.cancel')}
        </Button>
      </Box>
      <Box mx={2} hidden={!record_id || !isReadOnly}>
        <Button
          onClick={onEnableEdit}
          endIcon={<Icon fontSize='small'>{'edit'}</Icon>}
          sx={{ textTransform: 'unset' }}
          variant='outlined'
          size='small'
        >
          {I18n.t('action.modify')}
        </Button>
      </Box>
      <Box mx={2} hidden={!!isReadOnly}>
        <Button
          onClick={() => onSubmit()}
          endIcon={<Icon fontSize='small'>{'send'}</Icon>}
          sx={{ textTransform: 'unset' }}
          variant='outlined'
          size='small'
        >
          {!!record_id ? I18n.t('action.save') : I18n.t('action.create')}
        </Button>
      </Box>
    </Box >
  )
}

export default ModifyButton
