import React, { useCallback } from "react"
import { HookUtils, hooks } from "@front/volcanion"

import _ from "lodash"
import { Loader } from '@front/squirtle'

import Component from "./component"
import Callbacks from "./callbacks"
import { ModelRecord } from "@front/volcanion/types"

const withContainer: React.FC = () => {
  const domain = hooks.useCurrentDomain({}) as ModelRecord
  const [user, user_state] = hooks.useActiveUser({
    populate: ["user_companies.companyuser_services", 'user_companies.services.contract', 'auth'],
  })

  const user_id = user?.user_id
  const domain_id = domain?.domain_id

  const afterChange = useCallback(Callbacks.afterChangeHandler(user), [user])
  const mutator = useCallback(Callbacks.mutatorHandler(), [])

  const mergedProps = {
    user_id,
    afterChange,
    mutator,
    domain_id,
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([user_state])} nativeLoader type='linear'>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
