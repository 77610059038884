import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, Grid2 as Grid, Typography } from '@mui/material'

import Card from './Card/'

const PaymentMethodComponent: React.FC = props => {
  const { openNotification } = hooks.useNotification()
  const onPaymentMethodChange = useCallback((action?: 'add' | 'remove' | 'update') => {
    switch (action) {
      case 'add': openNotification(I18n.t('card.added'), { variant: 'success' })
        break
      case 'remove': openNotification(I18n.t('card.deleted'), { variant: 'success' })
        break
      case 'update': openNotification(I18n.t('card.updated'), { variant: 'success' })
        break
    }
  }, [openNotification])

  const [{ payment_methods }] = hooks.useAuthorizedPaymentTypes({}, undefined, undefined, onPaymentMethodChange)

  return (
    <Box sx={{ boxShadow: 3, p: 2 }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant='h6' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{I18n.t('payment.my_card', { count: -1 })}</Typography>
        </Grid>
        {_.map(payment_methods, (card, key) =>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }} key={key}><Card.Default card={card} /></Grid>
        )}
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}><Card.Add /></Grid>
      </Grid>
    </Box >
  )
}

export default PaymentMethodComponent
