
import React from 'react'
import { hooks } from '@front/volcanion'

import _ from 'lodash'

import { FormFactory } from '@front/squirtle'

import FormContent from './index'
import type { ParamType } from './types'

const Booking: React.FC = () => {
  const { order_id } = hooks.useParams() as ParamType
  return (
    <FormFactory.Form
      name={`order_detail_${order_id}`}
      listener_type='form'
      config={{
        model_name: 'order',
        ids: [order_id]
      }}
    >
      <FormContent />
    </FormFactory.Form>
  )
}

export default Booking
