
import React from 'react'
import _ from 'lodash'

import { Box, Grid } from '@mui/material'

import {
  AccountSection,
  AddressesSection,
  Bottombar,
  GlobalInformationSection,
  InformationSection,
  PackageSection,
  RidemodeSection
} from './Sections'

import Popups from './Popups'

const FormComponent: React.FC = props =>
  <Box>
    <Popups />
    <RidemodeSection />
    <Grid container rowSpacing={4} spacing={12} sx={{ p: 4, pb: '80px', }}>
      <Grid item xs={12} lg={6}><AccountSection /></Grid>
      <Grid item xs={12} lg={6}><GlobalInformationSection /></Grid>
      <Grid item xs={12} sm={12} md={12} lg><AddressesSection /></Grid>
      <Grid item xs={12} sm={12} md={12} lg><InformationSection /></Grid>
      <Grid item xs={12}><PackageSection /></Grid>
    </Grid>
    <Box mt={5}><Bottombar /> </Box>
  </Box >

export default FormComponent
