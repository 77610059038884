import React from "react"
import { FormHooks, VTypes, hooks, I18n } from "@front/volcanion"
import { TimeUtils, GeoUtils } from "@front/volcanion/utils"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['source', 'destination', 'assigned_transport.info', 'service.contract.formula', 'do.commercial_formula'] })

  const source = GeoUtils.getFormattedAddress(order?.source)
  const destination = !order?.destination
    ? I18n.t('address.no_destination')
    : GeoUtils.getFormattedAddress(order?.destination)

  const source_date = TimeUtils.getDetailsMomentFront(
    order?.assigned_transport?.info?.arrivedAt || order?.requestedAt, order?.source,
    { format: 'HH[h]mm' }
  )
  const destination_date = TimeUtils.getDetailsMomentFront(
    order?.assigned_transport?.info?.dropoffAt || order?.info?.dest_datetime, order?.destination,
    { format: 'HH[h]mm' }
  )

  const mergedProps = {
    source,
    source_date,
    destination,
    destination_date
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
