
import React from 'react'
import { VTypes, hooks, FormHooks, I18n } from '@front/volcanion'

import _ from 'lodash'

import { Box, IconButton, Icon, Typography, Grid, Chip } from '@mui/material'

import Primary from './Section/Primary'
import Popups from './Popups'
import Services from './table'
import { ModifyButton } from '../../elements/Button'

const UserComponent: React.FC = props => {
  const navigate = hooks.useNavigate()
  const [{ name, record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [status, administration_level] = FormHooks.useFieldValues(['status', 'administration_level']) as VTypes.V__ANY
  const onSubmit = FormHooks.useFormSubmit()
  return (
    <Box m={6}>
      <Popups />
      <Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box hidden={!!record_id}>
            <Box display='flex'>
              <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}><Icon color='primary'>{'arrow_back'}</Icon></IconButton>
              <Typography ml={2} variant='h5'> {I18n.t('companyuser.create')} </Typography>
            </Box>
          </Box>
          <Box hidden={!record_id}>
            <Box display='flex' alignItems='center'>
              <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}><Icon color='primary'>{'arrow_back'}</Icon></IconButton>
              <Typography my={3} variant='h5'> {name} </Typography>
              <Chip label={I18n.t(`status.${status}`)} color={'secondary'} size='small' />
              {!!administration_level && <Chip label={I18n.t(`admin.label.short`)} color={'primary'} size='small' sx={{ ml: 1 }} />}
            </Box>
          </Box>
          <ModifyButton />
        </Box>
      </Box>



      <Box sx={{ p: 3, mt: 3, backgroundColor: '#F9F6F4' }}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Primary />
          </Grid>
          <Grid item xs={12} hidden={!record_id}>
            <Services />
          </Grid>
        </Grid>
      </Box >
    </Box >
  )
}

export default UserComponent
