import React, { useCallback } from "react"
import { FormHooks, VTypes, hooks, I18n } from "@front/volcanion"
import { SHooks } from "@front/squirtle"

import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const [{ guest_booking }] = FormHooks.useFormState() as VTypes.V__ANY
  const [service] = FormHooks.useFieldValues(['service']) as VTypes.V__ANY
  const { openNotification } = hooks.useNotification()
  const [companyservice] = hooks.useModel('companyservice', [service], { single: true, populate: ['customerref_option'] })
  const onPaymentMethodChange = useCallback((action?: 'add' | 'remove' | 'update') => {
    switch (action) {
      case 'add': openNotification(I18n.t('payment.added'), { variant: 'success' })
        break
      case 'remove': openNotification(I18n.t('payment.deleted'), { variant: 'success' })
        break
      case 'update': openNotification(I18n.t('payment.updated'), { variant: 'success' })
        break
    }
  }, [openNotification])
  const [{ payment_types, payment_methods }] = hooks.useAuthorizedPaymentTypes({ service }, undefined, undefined, onPaymentMethodChange)

  const options_payment_type = _.map(payment_types, pt =>
    ({ label: pt?.name_translated, value: pt?.paymenttype_id, card_required: pt?.card_required, isMethod: false })
  )
  const options_payment_method = _.map(payment_methods, pm =>
    ({ label: pm?.name || I18n.t('card.label', { tail: pm?.card_tail }), value: pm?.payment_method_id, isMethod: true })
  )

  const options = _.flatten([
    _.filter(options_payment_type, ['card_required', false]),
    !!_.find(options_payment_type, ['card_required', true]) ? options_payment_method : []
  ])

  const afterPaymentChange = useCallback(Callbacks.afterPaymentChangeHandler(options, payment_types), [options, payment_types])

  const customerrefoption_id = companyservice?.customerref_option?.customerrefoption_id
  const required = !service || companyservice?.customerref_option?.fill_ref_rule === 'required'
  const manual_allowed = !service || companyservice?.customerref_option.manual_allowed

  const mergedProps = {
    afterPaymentChange,
    guest_booking,
    customerrefoption_id,
    required,
    manual_allowed,
    options
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
