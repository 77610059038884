import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Link, Box } from '@mui/material'
import { ReservationInfoProps } from './types'

const ReservationInfoComponent: React.FC<ReservationInfoProps> = ({ arrival_translated, name, origin, reservationinfo, meeting_point, onClick, reservationinfo_pick_type }) =>
  <Grid item xs={11}>
    <Box sx={{ display: !!reservationinfo ? 'flex' : 'none', justifyContent: 'flex-end' }}>
      <Link onClick={onClick} component="button" sx={{ display: 'flex', fontSize: 11 }} noWrap>
        <Box sx={{ display: 'flex' }}>
          {!arrival_translated && <Typography sx={{ mr: '3px', fontSize: 'inherit' }}> {I18n.t('meeting_point.label', { count: 1 })} : </Typography>}
          <Typography sx={{ fontSize: 'inherit' }}>{reservationinfo_pick_type === "arrival" ? arrival_translated : meeting_point}</Typography>
        </Box>

        <Box sx={{ display: (!!name || !!origin) ? 'flex' : 'none' }}>
          <Typography sx={{ ml: '3px', mr: '3px', fontSize: 'inherit' }}>  | </Typography>
          <Typography sx={{ mr: '3px', fontSize: 'inherit' }}> {reservationinfo?.name_translated} : </Typography>
          <Typography sx={{ fontSize: 'inherit' }}> {name}</Typography>
          <Typography sx={{ ml: '3px', mr: '3px', fontSize: 'inherit' }}>  | </Typography>
          <Typography sx={{ mr: '3px', fontSize: 'inherit' }}> {reservationinfo?.origin_translated} : </Typography>
          <Typography sx={{ fontSize: 'inherit' }}> {origin} </Typography>
        </Box>
      </Link>
    </Box >
    <Box sx={{ display: !reservationinfo ? 'flex' : 'none', justifyContent: 'flex-end' }}>
      <Typography sx={{ fontSize: 11 }}>  {meeting_point} </Typography>
    </Box>
  </Grid >

export default React.memo(ReservationInfoComponent)
