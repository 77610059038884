
import React from 'react'
import { I18n, hooks } from "@front/volcanion"

import _ from 'lodash'
import { Box, Typography, Icon } from '@mui/material'

import { GeoUtils, TimeUtils } from '@front/volcanion/utils'
import { DecoratorContentProps, DecoratorTitleProps } from '@front/squirtle/Components_ts/Dialog/types'

const BookingSuccessTitle: React.FC<DecoratorTitleProps> = props => (
  <Box display='flex' mb={3} alignItems='center' justifyContent='center'>
    <Icon fontSize='large' color='success'>{'check_circle'}</Icon>
    <Typography ml={2} variant='h4' color='primary'>{I18n.t('order.saved')}</Typography>
  </Box>
)

const BookingSuccess: React.FC<DecoratorContentProps> = ({ order_id }) => {
  const [order] = hooks.useModel('order', [order_id], { populate: ['source', 'destination', 'reservationinfos', 'info'], single: true })
  const order_reservationinfo = order?.reservationinfos?.[0]
  return (
    <Box>
      <Box display='flex' mb={3}>
        <Typography>{I18n.t('driver.meet')}</Typography>
        <Typography ml={1} sx={{ fontWeight: 'bold' }}>{TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source, { format: `DD/MM/YYYY ${I18n.t('prefix.at')} HH[h]mm` })}</Typography>
      </Box>
      <Box display='flex'>
        <Typography>{[I18n.t('prefix.at_alter'), ':'].join(' ')}</Typography>
        <Typography ml={1} sx={{ fontWeight: 'bold' }}>{GeoUtils.getFormattedAddress(order?.source)}</Typography>
      </Box>
      <Box hidden={!order_reservationinfo}>
        <Typography align={'center'}> {I18n.t('meeting_point.label', { count: 1 })} : </Typography>
        <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} align={'center'}> {order?.info?.meeting_point}</Typography>
        <Box display='flex'>
          <Typography sx={{ mr: 1 }}> {order_reservationinfo?.reservationinfo?.name_translated} : </Typography>
          <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} > {order_reservationinfo?.name}</Typography>
        </Box>
        <Box display='flex'>
          <Typography sx={{ mr: 1 }}> {order_reservationinfo?.reservationinfo?.origin_translated} : </Typography>
          <Typography color={'primary'} sx={{ fontWeight: 600, opacity: 0.8 }} > {order_reservationinfo?.origin}</Typography>
        </Box>
      </Box>
    </Box >
  )
}

export default BookingSuccess
export { BookingSuccessTitle }