
import { I18n } from '@front/volcanion'
import { IFormBaseListener, ISubmitMap, type ModelRecord, type NotificationHandler } from '@front/volcanion/types'
import { GeoUtils } from '@front/volcanion/utils'
import type { AddressObject } from '@front/volcanion/utils/geo'

import _ from 'lodash'

class Callbacks {
  static onSubmitAddressModifyHandler(changeType: (obj: object) => Promise<Partial<AddressObject>[] | undefined>, toggleFavorite: (obj: object) => Promise<Partial<AddressObject>[] | undefined>) {
    return async function onSubmitAddressModify(instance: IFormBaseListener, submit_map: ISubmitMap) {
      const address = instance.getFieldValue('address')
      const type = instance.getStateValue('type')
      try {
        if (type === 'favorite')
          await toggleFavorite({ address, favorite: true })
        else
          await changeType({ address, type })
      } catch (err) {
      }
      return
    }
  }

  static onSubmitPasswordModifyHandler(changePassword: (data: object) => void, openNotification: NotificationHandler["openNotification"], user_id: string) {
    return async function onSubmitPasswordModify(instance: IFormBaseListener, submit_map: ISubmitMap) {
      try {
        await changePassword({
          new_password: instance.getFieldValue('password_new'),
          old_password: instance.getFieldValue('password'),
          options: { force: !instance.getStateValue('has_password') }
        })
        openNotification(I18n.t('password.change.success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('password.change.error.incorrect'), { variant: 'error' })
        throw err
      }
      return
    }
  }

  static onSubmitGsmModifyHandler(updateUser: (data: object) => void, openNotification: NotificationHandler["openNotification"], setActiveStep: (value: number) => void) {
    return async function onSubmitGsmModify(instance: IFormBaseListener, submit_map: ISubmitMap) {
      try {
        await updateUser({ updates: { auth: { gsm: instance.getFieldValue('new_gsm') } } })
      } catch (err) {
      } finally {
        openNotification(I18n.t('gsm.change.done'), { variant: 'success' })
        setActiveStep(2)
      }
      return
    }
  }

  static recordToFormAddressHandler() {
    return function recordToFormAddress(record: ModelRecord) {

      return {
        'address--text': GeoUtils.mergeAddress(record),
        address: record?.address_id,
        comment: record?.comment
      }
    }
  }

  static onSubmitPaymentDetailHandler(modifyPayment: (data: object) => ModelRecord) {
    return async function onSubmitPaymentDetail(instance: IFormBaseListener, submit_map: ISubmitMap) {
      const name = instance.getFieldValue('name')
      const record_id = instance.getStateValue('record_id')

      try {
        const payment = await modifyPayment({ payment_method_id: record_id, name })
      } catch (err) {
        console.log('onSubmitPaymentDetail err : ', err)
      }
      return submit_map
    }
  }

  static recordToFormPaymentDetailHandler() {
    return function recordToFormPaymentDetail(record: ModelRecord) {
      return {

      }
    }
  }

  static getEmptyFormPaymentDetailHandler() {
    return function getEmptyFormPaymentDetail() {
      return {
      }
    }
  }

  static getEmptyGsmFormHandler(gsm: string) {
    return function getEmptyGsmForm() {
      return {
        gsm
      }
    }
  }

  static getEmptyPaymentAddFormHandler(cardholder_name: string) {
    return function getEmptyPaymentAddForm() {
      return {
        cardholder_name
      }
    }
  }

  static recordToStateAddressHandler() {
    return function recordToStateAddress(record: ModelRecord) {
      return {
        initial_type: !record?.type ? 'favorite' : record?.type
      }
    }
  }

}


export default Callbacks
