
import React, { useEffect } from 'react'
import { FormHooks, hooks, I18n, VTypes } from "@front/volcanion"
import { IFormFieldOption, ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'

import { Box, Typography } from '@mui/material'
import { FormFactory, MapFactory } from '@front/squirtle'

const getOptionIcon = (option: ModelRecord) => {
  if (option?.favorite) return 'favorite'
  if (option?.type === 'home') return 'home'
  else if (option?.type === 'work') return 'work'
  else if (option?.type === 'airport') return 'flighttakeoff'
  else if (option?.type === 'festival') return 'festival'
  else if (option?.type === 'train') return 'train'
  else if (option?.address_id) return 'update'
}

const AddressTitle: React.FC = () => {
  const [{ initial_type, record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  return (
    <Typography variant='h4' color='text'>{I18n.t(`address.${!!record_id ? 'modify' : 'add'}.${!!initial_type ? initial_type : 'common'}`)}</Typography>
  )
}

const AddressModifyComponent: React.FC = props => {
  const [{ record_id, isReadOnly, initial_type }, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const [user] = hooks.useActiveUser()
  const [address] = FormHooks.useFieldValues(['address']) as VTypes.V__ANY
  const [addresses, autocomplete] = hooks.useAddressAutocomplete({
    owner: user?.user_id,
    listener_id: 'autocomplete_address',
    requestOptions: {
      recentLimit: 4,
      favoriteLimit: 0,
      commercial_formula: user?.commercial_formula,
    },
    watchers: [user?.user_id]
  })

  const modelAddress = address as ModelRecord

  useEffect(() => {
    !!isReadOnly && setFormState('isReadOnly', false)
  }, [isReadOnly])

  return (
    <Box display='flex' sx={{ height: 300 }}>
      <Box flex={1} display='flex' flexDirection='column' justifyContent={'center'}>
        <Box p={2} hidden={!!initial_type}>
          <FormFactory.MenuSelectorField
            _native={{
              label: I18n.t('address.type.label', { count: 1 })
            }}
            name='type'
            options={[
              { label: I18n.t('address.type.favorite'), value: 'favorite' },
              { label: I18n.t('address.type.home'), value: 'home' },
              { label: I18n.t('address.type.work'), value: 'work' }
            ]}
          />
        </Box>
        <Box p={2}>
          <FormFactory.AutocompleteField
            name={'address'}
            _native={{
              onInputChange: autocomplete,
              label: I18n.t('address.label', { count: 1 })
            }}
            labelKeys={['formatted_address', 'name']}
            selectKeys={['address_id']}
            options={addresses as IFormFieldOption[]}
            getOptionIcon={getOptionIcon as (option: IFormFieldOption) => string}
          />
        </Box>
        <Box p={2}>
          <FormFactory.TextField name='comment' _native={{
            label: I18n.t('comment.label', { count: 1 }),
            slotProps: {
              input: {
                disableUnderline: true,
                sx: { border: 1, borderRadius: 1 }
              }
            },
            minRows: 3,
            rows: 3,
            multiline: true
          }} />
        </Box>
      </Box>
      <Box flex={1}>
        {/* <MapFactory.MapContainer className='full-screen-map' mapId='test'>
          <MapFactory.SuperLayer fitToBounds >
            <MapFactory.Marker
              config={{ listener_type: 'basic', model_name: 'address', ids: [modelAddress?.address_id || record_id], coordinates_path: 'coordinates' }}
            />
          </MapFactory.SuperLayer>
        </MapFactory.MapContainer > */}
      </Box>
    </Box>
  )
}

export default AddressModifyComponent
export { AddressTitle }
