import React from 'react'

import Authentication from './Authentication'

const PublicRouter = () => {
  return (
    <Authentication />
  )
}

export default React.memo(PublicRouter)
