import React, { useCallback, useEffect } from 'react'
import { FormHooks, hooks, VTypes } from '@front/volcanion'

import _ from 'lodash'

import Component from "./component"

const withContainer: React.FC = () => {
  const [, { openDialog }] = hooks.useDialogs()

  const [{ schedule_type, commercial_formula, reservationinfo_pick_type }] = FormHooks.useFormState() as VTypes.V__ANY
  const [source, reservationinfo] = FormHooks.useFieldValues(['source', 'reservationinfo']) as VTypes.V__ANY

  const poi_id = source?.poi_id
  const [poi] = hooks.useModel('poi', [source?.poi_id], { single: true, populate: ['type.authorized_pois', 'type.reservationinfo'] })

  const onClick = useCallback(() => {
    openDialog('reservationinfo', {
      formProps: {
        initialState: { poi_id, reservationinfo_pick_type, reservationinfo_id: poi?.type?.reservationinfo?.reservationinfo_id },
        initialValues: { name: reservationinfo?.name, origin: reservationinfo?.origin }
      }
    })
  }, [poi_id, reservationinfo_pick_type, reservationinfo, poi])

  const authorized_cf_poi = _.find(poi?.type?.authorized_pois, ['commercial_formula', commercial_formula])
  const pickup_at_transport = authorized_cf_poi?.pickup_at_transport

  useEffect(() => {
    if (!!pickup_at_transport && schedule_type === 'planned' && !!poi?.required_additional_info)
      openDialog('reservationinfo',
        {
          formProps: {
            initialState: { poi_id, reservationinfo_pick_type, reservationinfo_id: poi?.type?.reservationinfo?.reservationinfo_id },
            initialValues: { name: reservationinfo?.name, origin: reservationinfo?.origin }
          }
        }
      )
  }, [pickup_at_transport, schedule_type, poi_id, poi])


  const arrival_translated = hooks.useLocalTranslation(reservationinfo?.arrival_trkey) as string
  const mergedProps = {
    reservationinfo: poi?.type?.reservationinfo,
    meeting_point: poi?.meeting_point,
    name: reservationinfo?.name,
    origin: reservationinfo?.origin,
    reservationinfo_pick_type,
    arrival_translated,
    onClick,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer