
import React from 'react'
import { CDNSVG, CDNImage, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, Grid, Typography, Button, Icon } from '@mui/material'
import { HistoryComponentProps } from './types'

import TimerProgress from './progress'

const HistoryComponent: React.FC<HistoryComponentProps> = ({
  waitingSince,
  waitFor,
  driver_name,
  transport_status,
  order_status,
  source
}) => {
  const [, { openDialog }] = hooks.useDialogs()
  return (
    <Box display='flex' flexDirection='column' sx={{ height: '40%' }}>
      <Box display='flex' mx={2} alignItems={'center'} justifyContent={'space-between'} pt={2}>
        <Box display='flex' alignItems={'center'}>
          <Box sx={{ height: 46, width: 46 }}><CDNSVG name={'profil_picture'} category={'icons'} style={{ width: '100%', height: '100%' }} /></Box>
          <Box><Typography ml={3} variant='h6' textAlign='center'>{driver_name}</Typography></Box>
        </Box>
        <Button variant={'outlined'} startIcon={<Icon>{'call'}</Icon>} onClick={() => openDialog('driver_call')}>{'Appel Chauffeur'}</Button>
      </Box>

      <Box sx={{ backgroundColor: 'white', border: 1, borderColor: "#cfd8dc", p: 1, borderRadius: 1, m: 2, flex: 1 }}>
        <Grid container>
          <Grid item xs={12} hidden={transport_status !== 'started'}>
            <Typography>{'Votre chauffeur est en route pour : '}</Typography>
            <Box display='flex'>
              <CDNImage name={'source'} category='map' style={{ width: '24px', height: "24px" }} />
              <Typography ml={1}>{source}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} hidden={transport_status !== 'at_src'}>
            <Typography p={1}>{'Votre chauffeur est arrivé au lieu de prise en charge'}</Typography>
            <Box display='flex' p={1}>
              <CDNImage name={'source'} category='map' style={{ width: '24px', height: "24px" }} />
              <Typography ml={1}>{source}</Typography>
            </Box>
            <Box>
              <Box display={'flex'} p={1}>
                <Typography>{'Votre chauffeur attends depuis: '}</Typography>
                <Typography fontWeight='bold' ml={1} color='secondary'>{waitFor}{'min'}</Typography>
              </Box>
              <TimerProgress startTime={waitingSince} maxWaitTime={waitFor} />
            </Box>
          </Grid>
          <Grid item xs={12} hidden={transport_status !== 'on_board'}>
            <Typography>{'Course en cours'}</Typography>
          </Grid>
          <Grid item xs={12} hidden={transport_status !== 'off_board'}>
            <Typography>{'Course terminée'}</Typography>
          </Grid>
          <Grid item xs={12} hidden={transport_status !== 'complete'}>
            <Typography>{'Course clôturé'}</Typography>
          </Grid>
          <Grid item xs={12} hidden={transport_status !== 'cancel' && order_status !== 'canceled'}>
            <Typography>{'Course annulé'}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}

export default HistoryComponent
