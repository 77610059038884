import React, { useEffect } from 'react'
import { I18n, FormHooks, HookUtils, hooks, type VTypes } from '@front/volcanion'
import { FormFactory } from '@front/squirtle'

import _ from 'lodash'

import { Box, Typography } from '@mui/material'

const PaymentDetail: React.FC = props => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [payment_method, payment_method_state] = hooks.useModel('paymentmethod', [record_id], { single: true })
  const formListener = FormHooks.useFormListener()
  const isLoading = HookUtils.getLoadingState([payment_method_state])

  useEffect(() => {
    !isLoading && !!payment_method && formListener().getField<VTypes.IFormFieldValue>('name').setValue(payment_method?.name)
  }, [isLoading])

  return (
    <Box>
      <Box display='flex' gap={1} alignItems='flex-end' my={1}>
        <Typography sx={{ minWidth: 'max-content' }}>{I18n.t('card.name')}: </Typography>
        <FormFactory.TextField name={'name'} _native={{ slotProps: { htmlInput: { sx: { p: 0, m: 0 } } } }} />
      </Box>
      <Box display='flex' gap={1} alignItems='flex-end' my={1}>
        <Typography>{I18n.t('card.holder')}: </Typography>
        <Typography>{payment_method?.cardholder_name || '-'}</Typography>
      </Box>
      <Box display='flex' gap={1} alignItems='flex-end' my={1}>
        <Typography>{I18n.t('card.tail')}: </Typography>
        <Typography>{I18n.t('card.label', { tail: payment_method?.card_tail }) || '-'}</Typography>
      </Box>
      <Box display='flex' gap={1} alignItems='flex-end' my={1}>
        <Typography>{I18n.t('card.expiry')}: </Typography>
        <Typography>{_.compact([payment_method?.expiry_month, payment_method?.expiry_year]).join('/') || '-'}</Typography>
      </Box>
    </Box>
  )
}

export default PaymentDetail
