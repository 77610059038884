import React from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ name }] = FormHooks.useFormState() as VTypes.V__ANY
  const [{ preferred_language }] = FormHooks.useFormState() as VTypes.V__ANY
  const [armrest, driver_comment] = FormHooks.useFieldValues(['preference.armrest', 'preference.driver_comment']) as VTypes.V__ANY

  const features = hooks.useRelayFeatures([
    'DISPLAY_PREF_WAITING_DURATION',
    'DISPLAY_PREF_PREFERRED_MUSIC',
    'DISPLAY_PREF_AIR_CONDITIONING',
    'DISPLAY_PREF_TEMPERATURE',
    'DISPLAY_PREF_OPEN_DOOR',
    'DISPLAY_PREF_OPEN_WINDOW',
    'DISPLAY_PREF_ARMREST',
    'DISPLAY_PREF_COMMENT_TO_DRIVER'
  ])

  const MUSIC_LIST = hooks.useRelayConfig('MUSIC_LIST')

  const music_options = _.compact(_.map(MUSIC_LIST, music => ({
    label: music[preferred_language] || music['fr'],
    value: music[preferred_language] || music['fr']
  })))

  const mergedProps = {
    name,
    music_options,
    armrest,
    driver_comment,
    features
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
