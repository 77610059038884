
import React from 'react'

import _ from 'lodash'

import { Box } from '@mui/material'
import { MapComponentProps } from './types'
import { MapFactory } from '@front/squirtle'

const MapComponent: React.FC<MapComponentProps> = ({
  source,
  destination,
  location_history_ids
}) => (
  <Box sx={{ width: '100%', backgroundColor: "red" }}>
    {/* TODO TAREK */}
    {/* <MapFactory.MapContainer className='full-screen-map' mapId='test'> */}
    {/* <MapFactory.SuperLayer fitToBounds followBounds >
        <MapFactory.Marker
          config={{ listener_type: 'basic', model_name: 'address', ids: [source], coordinates_path: 'coordinates' }}
        />
        <MapFactory.Marker
          config={{ listener_type: 'basic', model_name: 'address', ids: [destination], coordinates_path: 'coordinates' }}
        />
        <MapFactory.Polyline
          config={{
            model_name: 'locationhistory',
            ids: location_history_ids,
            coordinates_path: 'coordinates'
          }}
        />
      </MapFactory.SuperLayer> */}
    {/* </MapFactory.MapContainer > */}
  </Box >
)

export default MapComponent
