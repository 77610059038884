
import { hooks } from "@front/volcanion"

import React from "react"

import { RouterCustom, Loader } from "@front/squirtle"
import PublicRouter from "./public"
import PrivateRouter from "./private"

const Pages = () => {
  const isAuthenticated = hooks.useAuthenticated()
  const isReady = hooks.useReadyStatus()

  return (
    <Loader isLoading={!isReady}>
      <RouterCustom>
        {!!isAuthenticated ? <PrivateRouter /> : <PublicRouter />}
      </RouterCustom>
    </Loader>
  )
}

export default Pages
