import type { VTypes } from '@front/volcanion'
import _ from 'lodash'

class Callbacks {
  static afterPaymentChangeHandler(options: VTypes.ModelRecord[], payment_types: VTypes.ModelRecord[]) {
    return function afterPaymentChange(value: VTypes.MaybeMultiple<VTypes.IFormFieldValue>, prev_value: VTypes.MaybeMultiple<VTypes.IFormFieldValue>, listener: VTypes.IFormFieldListener<VTypes.IFormFieldValue>) {
      const parentFormListener = listener.getParentForm()
      const payment = listener.getValueOption(value) as VTypes.ModelRecord

      if (!value) return
      const hasMethod = !!_.get(_.find(options, ['value', value]), 'isMethod')
      if (!!hasMethod) {
        const cb_payment_type = _.get(_.find(payment_types, ['card_required', true]), 'paymenttype_id')
        parentFormListener.setStateKey('payment_type', cb_payment_type)
        parentFormListener.setStateKey('payment_method', payment?.value)
      } else {
        parentFormListener.setStateKey('payment_type', payment?.value)
        parentFormListener.setStateKey('payment_method', null)
      }
    }
  }

  static onCardSocketHandler(search_payment_method: () => void, formListener: () => VTypes.IFormBaseListener) {
    return function onCardSocket(data?: VTypes.ModelRecord) {
      search_payment_method()
      formListener().getField('payment').setValue(data?.payment_method_id)
    }
  }
}

export default Callbacks
