import _ from 'lodash'

import { IFormBaseListener, ISubmitMap } from '@front/volcanion/types'

class Callbacks {
  static formToSubmitCompanyuserServiceHandler(companyuser: string) {
    return function formToSubmitCompanyuserService(instance: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      submit_map
        .setDataKey('companyuser', companyuser)
        .setDataKey('companyservice', {
          contract: instance.getStateValue('companycontract_id'),
          name: instance.getFieldValue('name'),
          client_id: instance.getFieldValue('client_id'),

          contact: {
            email: instance.getFieldValue('contact.email')
          },
          deferred_billing: instance.getFieldValue('deferred_billing'),
          can_call_booker: instance.getFieldValue('can_call_booker'),
          can_call_driver: instance.getFieldValue('can_call_driver'),
          password: instance.getFieldValue('password'),
          new_password: instance.getFieldValue('new_password'),
          active: instance.getFieldValue('statusa') === 'active',
          deleted: instance.getFieldValue('statusa') === 'archive',
          new_password_availableSince: instance.getFieldValue('new_password_availableSince'),
          customerref_option: {
            refs: instance.getFieldValue('customerref_option.refs'),
            fill_ref_rule: instance.getFieldValue('customerref_option.fill_ref_rule'),
            manual_allowed: instance.getFieldValue('customerref_option.imposed_ref')
          }
        })
      return submit_map
    }
  }


}

export default Callbacks

