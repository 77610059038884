
import React, { useCallback } from 'react'
import { I18n, CDNSVG, hooks } from '@front/volcanion'
import _ from 'lodash'

import { Box, Typography, Grid2 as Grid, Divider, Button } from '@mui/material'
import { UserUtils } from '@front/volcanion/utils'

const ListText: React.FC<{ onClick: () => void, label?: string }> = ({ onClick, label }) => (
  <Grid size={11}>
    <Button onClick={onClick} sx={{ maxWidth: '100%' }}>
      <Typography sx={{ fontSize: { xs: '1.5vw', md: '1.2vw', lg: '0.9vw' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{label}</Typography>
    </Button>
  </Grid>
)

const PrimaryInformationComponent: React.FC = props => {
  const [, { openDialog }] = hooks.useDialogs()
  const [user] = hooks.useActiveUser({ populate: ['info', 'customerinfo', 'auth'] })

  const name = UserUtils.getDisplayName(user)
  const gsm = user?.auth?.gsm
  const email = user?.auth?.email
  const has_password = user?.auth?.has_password

  const onLogout = useCallback(() => {
    localStorage.clear()
    window.location.reload()
  }, [])

  const onAddAddress = useCallback(() => openDialog('address_modify', { title: I18n.t('address.add.common') }), [openDialog])
  const onAddCard = useCallback(() => openDialog('payment_add'), [openDialog])
  const onChangePassword = useCallback(() => openDialog('password_modify'), [openDialog])
  const onChangeGsm = useCallback(() => openDialog('gsm_modify'), [openDialog])

  return (
    <Box sx={{ boxShadow: 3, p: 1, height: '98%' }}>
      <Grid container justifyContent={'center'} rowSpacing={2}>
        <Grid size={12} display='flex' justifyContent='center'>
          <CDNSVG name={'user_account'} category={'icons'} sx={{ width: 120, height: 120 }} />
        </Grid>
        <Grid size={12}>
          <Typography variant={'h5'} fontSize={'1.3vw'} textAlign='center'>{name}</Typography>
        </Grid>
        <Grid size={12}>
          <Typography textAlign='center'>{gsm} {email}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid container rowSpacing={2} justifyContent='flex-end'>
        <ListText onClick={onChangePassword} label={!!has_password ? I18n.t('password.modify') : I18n.t('password.add')} />
        <ListText onClick={onChangeGsm} label={I18n.t('gsm.modify')} />
        <ListText onClick={onAddCard} label={I18n.t('payment.add_card')} />
        <ListText onClick={onAddAddress} label={I18n.t('address.add.common')} />
        <ListText onClick={() => console.log('do some stuff')} label={I18n.t('action.delete_account')} />
        <ListText onClick={onLogout} label={I18n.t('action.logout')} />
      </Grid>
    </Box>
  )
}

export default PrimaryInformationComponent
