
import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Button, Box, Icon, Grid2 as Grid, Typography } from '@mui/material'

import { AddressesProps } from './types'
import AddressesCard from './Card'

const AddAddress: React.FC<{ type: string }> = ({ type }) => {
  const [, { openDialog }] = hooks.useDialogs()

  return (
    <Button
      fullWidth
      size='large'
      variant='text'
      sx={{ flex: 1, textTransform: 'unset', boxShadow: 3, opacity: 0.3, p: 4, height: 90 }}
      onClick={() => openDialog('address_modify', { formProps: { initialState: { initial_type: type } } })}
      startIcon={<Icon>{'add_circle'}</Icon>}
    >
      {`${I18n.t('address.add.click')} ${I18n.t(`address.label.${type}`)}`}
    </Button>
  )
}

const AddressesComponent: React.FC<AddressesProps> = ({ home_address, work_address, favorites_address }) => (
  <Box sx={{ boxShadow: 3, p: 2 }}>
    <Grid container spacing={2}>
      <Grid size={12}> <Typography variant='h6' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{I18n.t('address.my')}</Typography></Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 6, xl: 4 }} hidden={!!home_address}><AddAddress type='home' /></Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 6, xl: 4 }} hidden={!home_address}><AddressesCard address_id={home_address} /></Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 6, xl: 4 }} hidden={!!work_address}><AddAddress type='work' /></Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 6, xl: 4 }} hidden={!work_address}><AddressesCard address_id={work_address} /></Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 6, xl: 4 }}><AddAddress type='favorite' /></Grid>
      {_.map(favorites_address, address_id => <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}><AddressesCard address_id={address_id} /></Grid>)}
    </Grid>
    <Box my={2} />
  </Box >
)

export default AddressesComponent
