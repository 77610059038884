import React from 'react'
import { I18n } from '@front/volcanion'

import { FormFactory } from '@front/squirtle'

import { Box, Grid, Link, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const AuthenticationPage = ({ isSubmitting, requestSubmit, onForgotChange, onVisibleChange, visible }) => <Box>
  <Grid container justifyContent='center' rowSpacing={6}>
    <Grid item xs={9}>
      <Typography variant='h4' color='primary' sx={{ letterSpacing: 1.5 }}>
        {I18n.t('login.label', { count: 1 })}
      </Typography>
    </Grid>
    <Grid item sm={11} md={8}>
      <FormFactory.TextField
        name={'email'}
        _native={{
          label: I18n.t('email.label', { count: 1 }),
          type: 'text',
          variant: 'outlined',
          size: 'medium',
          autoFocus: true,
          required: true
        }}
        required
      />
    </Grid>
    <Grid item sm={11} md={8}>
      <FormFactory.TextField
        name={'password'}
        _native={{
          label: I18n.t('password.label', { count: 1 }),
          type: 'password',
          variant: 'outlined',
          size: 'medium',
          autoFocus: true,
          required: true
        }}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-end' }}>
        <Link href='#' onClick={onForgotChange}>
          <Typography fontSize={16} color={'primary'} fontWeight={600}> {I18n.t('password.forgot.label', { count: 1 })}
          </Typography>
        </Link>
        <LoadingButton onClick={requestSubmit} loading={!!isSubmitting} variant='outlined' size={'large'}>
          <Typography variant='h6' color={'primary'} sx={{ textTransform: 'none' }}>  {I18n.t('action.login')} </Typography>
        </LoadingButton>
      </Box>
    </Grid>
  </Grid>
</Box >

export default React.memo(AuthenticationPage)
