import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import { FormFactory } from '@front/squirtle'

const withForm = Component => props => {
  const [, signin] = hooks.useAuthFunction('signin')
  const { openNotification } = hooks.useNotification()

  const onSubmit = useCallback(Callbacks.onSubmitHandler(signin, openNotification), [signin, openNotification])
  const getSuccessNotification = useCallback(Callbacks.getSuccessNotificationdHandler(), [])
  const getFailedNotification = useCallback(Callbacks.getFailedNotificationHandler(), [])
  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(), [])

  const mergedProps = {
  }

  return (
    <FormFactory.Form
      name={'login_form'}
      listener_type='form'
      onSubmit={onSubmit}
      onSubmitSuccess={getSuccessNotification}
      onSubmitFail={getFailedNotification}
      getEmptyState={getEmptyState}
      config={{
        ids: []
      }}
      closeOnSuccess={false}
      submitOnEnter={true}
    >
      <Component {...mergedProps} />
    </FormFactory.Form>
  )
}

export default withForm
