import React, { useEffect, useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Stepper, Step, StepLabel, Typography, Icon } from '@mui/material'
const styles = theme => ({
  alternativeLabel: {
    margin: '3px !important',
  }
})


const COLORS = { 0: 'red', 1: 'red', 2: 'orange', 3: 'green' }
const LEVELS = {
  0: 'password.complexity.weak',
  1: 'password.complexity.weak',
  2: 'password.complexity.good',
  3: 'password.complexity.good',
  4: 'password.complexity.strong'
}

const StepIconComponent = ({ error, size }) =>
  <Box sx={{
    backgroundColor: !!error ? 'red' : 'green',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 1,
    width: size,
    height: size
  }}>
    <Icon sx={{ fontSize: 13, color: 'white' }}>{!!error ? 'close' : 'check'} </Icon>
  </Box>

const PasswordComplexity = ({
  value,
  disableTitle = true,
  onScoreChange = _.noop,
  iconSize = 22,
  fontSize = {},
  ...rest
}) => {


  const { titleSize = '1.6vw', textSize = '1vw' } = fontSize

  const validators = useMemo(() => ([
    { label: I18n.t('password.rules.length'), error: !value || value.length < 8 },
    { label: I18n.t('password.rules.upper'), error: !value || !value.match(/[A-Z]/g) },
    { label: I18n.t('password.rules.lower'), error: !value || !value.match(/[a-z]/g) },
    { label: I18n.t('password.rules.number'), error: !value || !value.match(/\d/g) }
  ]), [value])

  const score = validators.length - _.filter(validators, 'error').length

  useEffect(() => {
    onScoreChange(score)
  }, [score])

  return (
    <Box sx={{ display: !!value ? 'block' : 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!disableTitle && <Typography mr={1} color={'text.label'} fontSize={titleSize} letterSpacing={1.5}>
          {I18n.t('password.complexity.level')}
        </Typography>}
        <Typography sx={{ color: COLORS[score] }} fontSize={titleSize} letterSpacing={1.5}>{I18n.t(LEVELS[score])} </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        <Stepper alternativeLabel orientation='horizontal'>
          {_.map(validators, rule =>
            <Step key={rule.label} completed active={!rule.error}>
              <StepLabel StepIconComponent={() => <StepIconComponent error={!!rule.error} size={iconSize} />}>
                <Typography fontSize={textSize} color={'text.label'} fontWeight={200}>
                  {rule.label}
                </Typography>
              </StepLabel>
            </Step>
          )}
        </Stepper>
      </Box>
    </Box >
  )
}

export default React.memo(PasswordComplexity)
