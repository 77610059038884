import React from 'react'

import AdminList from './Admin'
import CommonList from './Common'
import OrdersList from './Orders'

import { ListItemIcon } from '@mui/material/'
import type { CDNSVGProps } from '@front/volcanion/components/CDNSVG/type'
import { CDNSVG } from '@front/volcanion'

const ListItemButtonProps = {
  sx: { minHeight: 46, display: 'flex', justifyContent: 'center', alignItems: 'center' }
}

const ListItemTextProps = {
  primaryTypographyProps: { fontSize: '1.1rem', lineHeight: 0 },
  sx: { flex: 1, ml: 2, mt: '8px' }
}

const CustomListItemIcon: React.FC<CDNSVGProps> = props => (
  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', minWidth: 0 }}>
    <CDNSVG {...props} fill='black' />
  </ListItemIcon>
)

export {
  AdminList,
  CommonList,
  CustomListItemIcon,
  ListItemButtonProps,
  ListItemTextProps,
  OrdersList
}