import React, { useCallback, useEffect } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import { OrderUtils } from "@front/volcanion/utils"

import Callbacks from "./callbacks"
import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id, canDownloadBill }, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const { openNotification } = hooks.useNotification()
  const navigate = hooks.useNavigate()
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['mode'] })
  const [isDownloadAvailable] = hooks.useModelFunction('order', 'isDownloadAvailable')
  const [getInvoice] = hooks.useModelFunction('order', 'sendInvoice')
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const CLIENT_CONTACT_EMAIL = hooks.useRelayConstant('CLIENT_CONTACT_EMAIL') as string

  const canRequestInvoice = order?.transaction?.status === 'captured'
  const client_id = order?.order_client_id
  const status = OrderUtils.getOrderStatus(order?.status, order?.confirmed)
  const ridemode = order?.mode?.name_translated
  const ridemode_icon = order?.mode?.client_type_icon

  const downloadBill = useCallback(Callbacks.downloadBillHandler(downloadInvoice, openNotification), [])
  const getBillDownloadStatus = useCallback(Callbacks.getBillDownloadStatusHandler(isDownloadAvailable, setFormState), [isDownloadAvailable, setFormState])
  const onRequestInvoice = useCallback(Callbacks.onRequestInvoiceHandler(getInvoice, navigate, CLIENT_CONTACT_EMAIL, record_id), [getInvoice, navigate, CLIENT_CONTACT_EMAIL, record_id])

  useEffect(() => {
    !!record_id && getBillDownloadStatus(record_id)
  }, [record_id])

  const mergedProps = {
    canDownloadBill,
    canRequestInvoice,
    client_id,
    status,
    ridemode,
    ridemode_icon,
    onRequestInvoice,
    downloadBill
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
