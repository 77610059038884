import _ from 'lodash'

import moment from 'moment'

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        date_start: moment().subtract(3, 'months').startOf('day').format(),
        date_end: moment().endOf('day').format()
      }
    }
  }
  static getEmptyStateHandler(company: string) {
    return function getEmptyState() {
      return {
        company
      }
    }
  }
}

export default Callbacks

