import React from 'react'
import { hooks } from '@front/volcanion'
import { FormatUtils } from '@front/volcanion/utils'
import type { ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

const HeaderComponent: React.FC = props => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Nom</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Numéro de contrat</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Adresse mail</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Adresse de facturation</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Utilisateurs</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Statut</TableCell>
      </TableRow>
    </TableHead>
  )
}

const RowComponent: React.FC<{ row: ModelRecord }> = ({ row }) => {
  const navigate = hooks.useNavigate()
  const status = FormatUtils.getStatus(row)
  const statusColor = FormatUtils.getStatusColor(status)
  return (
    <TableRow key={row?.name} onClick={() => navigate(`/admin/contract/${row?.companycontract_id}`)}>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.name}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.client_id}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.contact?.email}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.contact?.address}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{_.size(_.uniqBy(row?.companyservices, 'users'))}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}><Typography sx={{ color: statusColor }}>{status}</Typography></TableCell>
    </TableRow>
  )
}

const BasicTable: React.FC<{ type?: string }> = ({ type }) => {

  const [data] = hooks.useModelSearch('companycontract', 'get', {
    initial_filter: {},
    populate: ['contact', 'companyservices'],
    listener_id: 'companycontract_list'
  })

  return (
    <TableContainer>
      <Table>
        <HeaderComponent />
        <TableBody>
          {_.map(data, (row) => (<RowComponent row={row} />))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
