import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';

const TimerProgress = ({ startTime, maxWaitTime }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const start = moment(startTime);
      const duration = moment.duration(now.diff(start));
      const elapsedSeconds = duration.asSeconds();
      const maxWaitSeconds = maxWaitTime * 60; // Convert maxWaitTime from minutes to seconds
      const computedProgress = (elapsedSeconds / maxWaitSeconds) * 100;

      if (computedProgress >= 100) {
        setProgress(100);
        clearInterval(interval);
      } else {
        setProgress(computedProgress);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, maxWaitTime]);

  return (
    <LinearProgress variant="determinate" value={progress} color={'secondary'} sx={{ p: '3px', borderRadius: 2, shadow: 2 }} />
  );
};

export default TimerProgress;
