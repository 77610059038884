import React from "react"
import { FormHooks, hooks, VTypes } from "@front/volcanion"
import { ModelRecord } from "@front/volcanion/types"
import { BillingUtils, HookUtils, } from "@front/volcanion/utils"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [commercial_package] = FormHooks.useFieldValues(['commercial_package', 'service']) as VTypes.V__ANY
  const [estimation, estimation_state] = hooks.useSelectedEstimation(commercial_package)
  const modelEstimation = estimation as ModelRecord
  const price = !!estimation ? BillingUtils.getEstimationDisplay(modelEstimation?.details?.pricing, false, 1) : undefined
  const isLoading = HookUtils.getLoadingState([estimation_state])

  const mergedProps = {
    price,
    isLoading
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
