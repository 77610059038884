import _ from 'lodash'

import { IFormBaseListener, ISubmitMap, ModelRecord, type MaybePromise } from '@front/volcanion/types'

class Callbacks {

  static recordToFormHandler() {
    return function recordToForm(record: ModelRecord) {
      return {
        first_name: record?.info?.first_name,
        last_name: record?.info?.last_name,
        gsm: record?.auth?.gsm,
        email: record?.auth?.email
      }
    }
  }

  static formToSubmitHandler() {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      submit_map
        .setDataKey('info.first_name', form.getFieldValue('first_name'))
        .setDataKey('info.last_name', form.getFieldValue('last_name'))
        .setDataKey('auth.gsm', form.getFieldValue('gsm'))
        .setDataKey('auth.email', form.getFieldValue('email'))
      return submit_map
    }
  }

  static onSubmitHandler(updateUser: (data: object) => MaybePromise<object>) {
    return function onSubmit(instance: IFormBaseListener, submit_map: ISubmitMap) {
      return updateUser({ updates: submit_map?.getData() })
    }
  }

}

export default Callbacks

