import React from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['payment_type'] })

  const customer_ref = order?.customer_ref
  const payment_type = order?.payment_type?.name_translated

  const mergedProps = {
    customer_ref,
    payment_type
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
