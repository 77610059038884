import React, { useCallback } from "react"
import { hooks, FormHooks } from '@front/volcanion'
import { SHooks } from "@front/squirtle"
import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"
import AddCardPopupCallbacks from '@front/squirtle/elements/AddCard/callbacks'
import { AddressObject } from "@front/volcanion/utils/geo"

const withContainer: React.FC = () => {
  const [user] = hooks.useActiveUser({ populate: ['auth', 'info'] })
  const { openNotification } = hooks.useNotification()
  const [toggleFavorite] = hooks.useModelFunction<Partial<AddressObject>[]>('address', 'toggleFavorite')
  const [changeType] = hooks.useModelFunction<Partial<AddressObject>[]>('address', 'changeType')
  const [, changePassword] = hooks.useAuthFunction('changePassword')
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const [, setActiveStep] = hooks.useGlobalState('activeStep')
  const [state] = FormHooks.useGlobalFormState('password_modify_dialog_form')
  const [modifyCard] = hooks.useModelFunction('paymentmethod', 'modify')

  const [, , , { addCard }] = SHooks.useStripe()

  const onSubmitAddressModify = useCallback(Callbacks.onSubmitAddressModifyHandler(changeType, toggleFavorite), [changeType, toggleFavorite])
  const onSubmitPasswordModify = useCallback(Callbacks.onSubmitPasswordModifyHandler(changePassword, openNotification, user?.user_id), [changePassword, openNotification, user?.user_id])
  const onSubmitGsmModify = useCallback(Callbacks.onSubmitGsmModifyHandler(changePassword, openNotification, setActiveStep), [changePassword, openNotification, setActiveStep])
  const onSubmitCard = useCallback(AddCardPopupCallbacks.onSubmitCardHandler(addCard), [addCard])
  const onSubmitPaymentDetail = useCallback(Callbacks.onSubmitPaymentDetailHandler(modifyCard), [modifyCard])

  const recordToFormAddress = useCallback(Callbacks.recordToFormAddressHandler(), [])
  const recordToStateAddress = useCallback(Callbacks.recordToStateAddressHandler(), [])

  const getEmptyGsmForm = useCallback(Callbacks.getEmptyGsmFormHandler(user?.auth?.gsm), [user?.auth?.gsm])
  const getEmptyPaymentAddForm = useCallback(Callbacks.getEmptyPaymentAddFormHandler(user?.info?.full_name), [user?.info?.full_name])

  const isValidPassword = state?.isValidPassword

  const [activeGsmStep] = hooks.useGlobalState('activeStep')


  const allowConfirmCloseGsm = (activeGsmStep as number) === 2
  const mergedProps = {
    isValidPassword,
    allowConfirmCloseGsm,
    recordToFormAddress,
    recordToStateAddress,
    onSubmitAddressModify,
    onSubmitPasswordModify,
    onSubmitGsmModify,
    onSubmitPaymentDetail,
    onSubmitCard,
    getEmptyGsmForm,
    getEmptyPaymentAddForm
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
