
import React from 'react'
import { I18n, VTypes, FormHooks, hooks } from '@front/volcanion'
import { FormatUtils } from '@front/volcanion/utils'

import _ from 'lodash'

import { Box, Typography, Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'

import { IFormFieldOption, ModelRecord } from '@front/volcanion/types'

const getOptionIcon = (option: ModelRecord) => {
  if (option?.favorite) return 'favorite'
  if (option?.type === 'home') return 'home'
  else if (option?.type === 'work') return 'work'
  else if (option?.type === 'airport') return 'flighttakeoff'
  else if (option?.type === 'festival') return 'festival'
  else if (option?.type === 'train') return 'train'
  else if (option?.address_id) return 'update'
}

const Row1: React.FC = props => (
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <FormFactory.TextField name='name' _native={{ label: I18n.t('name.label', { count: 1 }) }} />
    </Grid>
    <Grid item xs>
      <FormFactory.TextField name='client_id' _native={{ label: I18n.t('companycontract.client_id') }} />
    </Grid>
    <Grid item xs >
      <FormFactory.MenuSelectorField
        name='invoice_type'
        _native={{ label: I18n.t('invoice.send') }}
        options={[
          { label: I18n.t('company.label', { count: 1 }), value: 'company' },
          { label: I18n.t('companycontract.label', { count: 1 }), value: 'contract' },
          { label: I18n.t('companyuser.label', { count: 1 }), value: 'do' }
        ]}
      />
    </Grid>
    <Grid item xs>
      <FormFactory.OptionSelectorField
        name='deferred_billing'
        options={[{ value: true }]}
        _native={{
          valueType: 'switch',
          label: I18n.t('payment.allow_deferred')
        }}
      />

    </Grid>
  </Grid>
)

const Row2: React.FC = props => {
  const [status] = FormHooks.useFieldValues(['status']) as VTypes.V__ANY
  const statusColor = FormatUtils.getStatusColor(status)
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.DateTimeField
          name='availableSince'
          _native={{
            label: I18n.t('date.start'),
            compact: true
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.DateTimeField
          name='availableUntil'
          _native={{
            label: I18n.t('date.end'),
            compact: true
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField
          name='status'
          _native={{
            label: I18n.t('status.label', { count: 1 }),
            slotProps: {
              htmlInput: { color: statusColor }
            }
          }} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='contact.email' _native={{ label: I18n.t('email.label', { count: 1 }) }} />
      </Grid>
    </Grid>
  )
}

const Row3: React.FC = props => {
  const [{ commercial_formula }] = FormHooks.useFormState() as VTypes.V__ANY
  const [addresses, autocomplete] = hooks.useAddressAutocomplete({
    listener_id: 'autocomplete_companycontract_source_address',
    requestOptions: {
      recentLimit: 4,
      favoriteLimit: 0,
      commercial_formula,
    },
    watchers: [commercial_formula]
  })
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.TextField name='contact.address' _native={{ label: I18n.t('address.billing') }} />
      </Grid>
      <Grid item xs>
        <FormFactory.AutocompleteField
          name={'source_address'}
          _native={{
            onInputChange: autocomplete,
            label: I18n.t('address.home')
          }}
          labelKeys={['formatted_address', 'name']}
          options={addresses as IFormFieldOption[]}
          getOptionIcon={getOptionIcon}
        />
      </Grid>
    </Grid >
  )
}

const PrimarySection: React.FC = props => (
  <Box display='flex'>
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>{I18n.t('info.general.label', { count: 2 })}</Typography>
      </Grid>
      <Row1 />
      <Row2 />
      <Row3 />
      <Grid item xs={12}>
        <FormFactory.TextField name='comment' _native={{
          label: I18n.t('comment.admin'),
          rows: 3
        }}
        />
      </Grid>
    </Grid>
  </Box >
)


export default PrimarySection
