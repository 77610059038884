
import React from 'react'
import { I18n } from '@front/volcanion'

import _ from 'lodash'

import { Box, Typography, Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'

const PasswordSection: React.FC = props => (
  <Box display='flex'>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>{I18n.t('password.label', { count: 1 })}</Typography>
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='password' _native={{ label: I18n.t('password.label.current') }} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='new_password' _native={{ label: I18n.t('password.label.new') }} />
      </Grid>
      <Grid item xs>
        <FormFactory.DateTimeField
          name='new_password_availableSince'
          _native={{
            label: I18n.t('date.activation'),
            compact: true,
          }}
        />
      </Grid>
    </Grid>
  </Box >
)


export default PasswordSection
