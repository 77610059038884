
import React from 'react'

import { I18n } from '@front/volcanion'

import _ from 'lodash'

import { Box, Grid, Typography } from '@mui/material'

import BasicTable from './table'

const CompaniesComponent: React.FC = props => {
  return (
    <Box sx={{ boxShadow: 3, p: 2 }}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{I18n.t('company.account.label', { count: -1 })}</Typography>
        </Grid>
        <Grid item xs={12}>
          <BasicTable />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CompaniesComponent
