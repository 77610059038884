
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import _ from 'lodash'
import { FormFactory, Loader } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'

const Booking: React.FC = () => {
  const [user, user_status] = hooks.useActiveUser()

  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(), [])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const recordToState = useCallback(Callbacks.recordToStateHandler(), [])

  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(), [])

  return <Loader isLoading={!user || !HookUtils.getReadyState([user_status])} nativeLoader type='linear'>
    <FormFactory.Form
      name={`user_detail_${user?.user_id}_preference`}
      listener_type='form'
      getEmptyState={getEmptyState}
      getEmptyForm={getEmptyForm}
      recordToForm={recordToForm}
      recordToState={recordToState}
      formToSubmit={formToSubmit}
      config={{
        model_name: 'user',
        ids: [user?.user_id],
        populate: ['info', 'customerinfo', 'preference']
      }}
    >
      <FormContent />
    </FormFactory.Form>
  </Loader>


}

export default Booking
