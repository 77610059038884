import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import PasswordComplexity from '@onix/elements/passwordComplexity'

import { FormFactory } from '@front/squirtle'

const FirstStep = props => <Box>
  <Grid container justifyContent='center' rowSpacing={4}>
    <Grid item xs={9}>
      <Typography variant='h4' color='primary' sx={{ letterSpacing: 1.5 }}>
        {I18n.t('login.register.steps.first')}
      </Typography>
    </Grid>
    <Grid item xs={11} sm={8}>
      <FormFactory.TextField
        name='email'
        _native={{
          autoFocus: true,
          label: I18n.t('email.label', { count: 1 }),
          variant: 'outlined',
          size: 'medium'
        }}
      />
    </Grid>
    <Grid item xs={11} sm={8}>
      <FormFactory.TextField
        name='password'
        _native={{
          autoFocus: true,
          label: I18n.t('password.label', { count: 1 }),
          variant: 'outlined',
          type: 'password',
          size: 'medium'
        }}
      />
    </Grid>
    <Grid item xs={12} hidden={!props?.password}>
      <PasswordComplexity value={props?.password} disableTitle={false} onScoreChange={props?.setScore} />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <LoadingButton onClick={props?.onRequestSubmit} loading={!!props?.isSubmitting} variant='outlined'>
          <Typography color='primary' variant='h6' sx={{ textTransform: 'none' }}>  {I18n.t('action.signup')} </Typography>
        </LoadingButton>
      </Box>
    </Grid>
  </Grid>
</Box>

export default React.memo(FirstStep)
