import React, { useState, useCallback } from 'react'
import { FormHooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [{ isForgot }, setFormState] = FormHooks.useFormState()
  const [visible, setVisible] = useState(false)

  const isSubmitting = FormHooks.useFormStatus('isSubmitting')
  const requestSubmit = FormHooks.useFormSubmit()

  const onForgotChange = useCallback(() => setFormState('isForgot', !isForgot), [isForgot, setFormState])
  const onVisibleChange = useCallback(() => setVisible(!visible), [visible, setVisible])

  const mergedProps = {
    visible,
    requestSubmit,
    isSubmitting,
    onForgotChange,
    onVisibleChange,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
