import React, { useCallback, useEffect } from "react"
import { I18n, FormHooks, VTypes, hooks } from '@front/volcanion'
import { GeoUtils } from "@front/volcanion/utils"
import { SHooks } from "@front/squirtle"
import AddCardPopupCallbacks from '@front/squirtle/elements/AddCard/callbacks'

import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const [full_name] = hooks.useActiveUser({ mutator: 'info.full_name', populate: ['info'] })
  const [{ source, destination }] = FormHooks.useFormState() as VTypes.V__ANY
  const [, { openDialog }] = hooks.useDialog('warning')
  const [, setFormState] = FormHooks.useFormState()
  const [poi] = hooks.useModel('poi', [source?.poi_id], { single: true, populate: ['type.reservationinfo'] })
  const [, , , { addCard }] = SHooks.useStripe()

  const changeVehicleOptions = FormHooks.useFieldChange('vehicle_opts')
  const changeDriverOptions = FormHooks.useFieldChange('driver_opts')
  const changeReservationInfo = FormHooks.useFieldChange('reservationinfo')

  const onSubmitPackageOptions = useCallback(Callbacks.onSubmitPackageOptionsHandler(changeVehicleOptions, changeDriverOptions), [changeVehicleOptions, changeDriverOptions])
  const onSubmitReservationInfo = useCallback(Callbacks.onSubmitReservationInfoHandler(changeReservationInfo, setFormState, poi), [changeReservationInfo, setFormState, poi])
  const onSubmitCard = useCallback(AddCardPopupCallbacks.onSubmitCardHandler(addCard), [addCard])

  const getEmptyPaymentAddForm = useCallback(Callbacks.getEmptyPaymentAddFormHandler(full_name), [full_name])

  const isSameAddress = !!source && !!destination && GeoUtils.isSameAddress(source, destination)

  useEffect(() => {
    !!isSameAddress && openDialog({ description: I18n.t('address.same') })
  }, [isSameAddress])

  const mergedProps = {
    onSubmitPackageOptions,
    onSubmitReservationInfo,
    onSubmitCard,
    getEmptyPaymentAddForm
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
