import React, { useCallback } from "react"
import { hooks, I18n, withSlot } from '@front/volcanion'

import _ from "lodash"

import { Box, Icon, Typography } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { FormFactory } from "@front/squirtle"
import { DecoratorElemSlot } from '@front/squirtle/FormFactory/MenuSelector/types'

import { AccountSelectorProps } from "./types"
import type { ModelRecord } from "@front/volcanion/types"

const DecoratorElem = withSlot<{}, DecoratorElemSlot>(({ option }) => {
  const { icon = 'person' } = option || {}

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} ml={2}>
      <Box pb='2px' mr={1}>
        <Icon color='primary'> {icon}</Icon>
      </Box>
      <Box display='flex'>
        {!!option?.value && <Typography color='primary' noWrap sx={{ display: { xs: 'none', sm: 'block' }, mr: 1 }}>{[I18n.t('account.b2b'), ':'].join(' ')}</Typography>}
        <Typography color='primary' noWrap >{option?.label}</Typography>
      </Box>
    </Box>
  )
})

const SelectIconComponent = () => <Box sx={{ m: 1, pl: '3px', borderLeft: 2 }}><ArrowForwardIosIcon /></Box>

const AccountSelector: React.FC<AccountSelectorProps> = ({ afterChange, mutator, domain_id, user_id, hide }) => {
  const [company] = hooks.useGlobalState('persist.company_id')
  return (
    <FormFactory.MenuSelectorField
      name='service'
      selectFirst
      selectKeys={['value']}
      afterChange={afterChange}
      config={{
        model_name: 'companyuserservices',
        listener_type: 'search',
        listener_id: 'user_accounts',
        populate: ['companyservice.contract.company', 'companyuser.user.customerinfo', 'companyuser.contact', 'companyuser.blacklisted_domains'],
        initial_filter: {},
        forced_filter: {
          companyuser: { status: [{ name: 'active' }], user: user_id, company },
          companyservice: { status: [{ name: 'active' }] }
        },
        sort: ['companyservice.contract.formula.display_index ASC'],
        watchers: [user_id, domain_id],
        validate_filter: useCallback(() => !!user_id && !!domain_id, [user_id, domain_id]),
        postFilter: (record: ModelRecord) => {
          return !_.includes(_.map(record?.companyuser?.blacklisted_domains, 'domain_id'), domain_id)
            && !(!record?.companyuser?.guest_booking && !record?.companyuser?.self_booking && !record?.companyuser?.booking_level)
        },
        mutator
      }}
      _native={{
        fullWidth: true,
        _select: {
          IconComponent: SelectIconComponent
        },
        variant: 'standard',
        disableUnderline: true,
        sx: { backgroundColor: 'common.white', boxShadow: '0px 10px 20px 4px rgba(35, 35, 35, 0.10)' }
      }}
      nullOptionLabel={I18n.t('account.private')}
      addNullOption
      nullOptionFirst={false}
    >
      <DecoratorElem slot='decorator' />
    </FormFactory.MenuSelectorField>
  )
}

export default AccountSelector
