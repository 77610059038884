import _ from 'lodash'

class Callbacks {
  static onMouseActionHandler(isExpand: boolean, action: boolean, setOpen: (value: boolean) => void) {
    return function onMouseAction(e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) {
      const target = e?.target as Element
      const forbiddenClassname = target?.getAttribute('class')
      const disableTarget = 'SvgIcon'

      if (_.includes(forbiddenClassname, disableTarget) || !!isExpand)
        return
      else {
        setOpen(action)
      }
    }
  }
  static onStaticExpandHandler(isExpand: boolean, setOpen: (value: boolean) => void, setExpand: (value: boolean) => void) {
    return function onStaticExpand() {
      setExpand(!isExpand)
      setOpen(!isExpand)
    }
  }
}

export default Callbacks

