import React, { useCallback } from 'react'
import { I18n, FormHooks } from '@front/volcanion'

import { Box, Stepper, Step, StepLabel, Typography, IconButton } from '@mui/material'

const styles = theme => ({
  alternativeLabel: {
    margin: '0 !important',
  }
})


const StepIconComponent = ({ step, onClick, activeStep, ...props }) =>
  <IconButton onClick={() => onClick(step)} sx={{ p: 0 }}>
    <Box sx={{
      backgroundColor: activeStep === step ? 'primary.main' : 'gray',
      borderRadius: 10,
      boxShadow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 22,
      height: 22
    }}>
      <Typography sx={{ color: activeStep === step ? 'white' : 'extraWhite', fontSize: 13, textAlign: 'center', fontWeight: 600 }}>
        {step + 1}
      </Typography>
    </Box>
  </IconButton>

const RegistrationStepper = ({ value = 0, ...props }) => {
  const [, setFormState] = FormHooks.useFormState()
  const getLabelProps = useCallback(step => ({
    color: step === value ? 'primary.main' : 'text.label',
    fontSize: step === value ? '1vw' : '0.8vw',
    fontWeight: step === value ? 600 : 200
  }), [value])

  const onStepChange = useCallback(target => {
    if (target > value) return
    else setFormState('step', target)
  }, [value, setFormState])

  return (
    <Stepper activeStep={value} alternativeLabel>
      <Step key={0} >
        <StepLabel StepIconComponent={() => <StepIconComponent step={0} activeStep={value} onClick={onStepChange} />}>
          <Typography {...getLabelProps(0)}> {I18n.t('login.register.steps.first')} </Typography>
        </StepLabel>
      </Step>
      <Step key={1} >
        <StepLabel StepIconComponent={() => <StepIconComponent step={1} activeStep={value} onClick={onStepChange} />}>
          <Typography {...getLabelProps(1)}> {I18n.t('login.register.steps.second')} </Typography>
        </StepLabel>
      </Step>
      <Step key={2} >
        <StepLabel StepIconComponent={() => <StepIconComponent step={2} activeStep={value} onClick={onStepChange} />}>
          <Typography {...getLabelProps(2)}> {I18n.t('login.register.steps.third')} </Typography>
        </StepLabel>
      </Step>
    </Stepper>
  )
}

export default React.memo(RegistrationStepper)
