import { type IFormFieldListener, type IFormFieldValue, type MaybeMultiple } from "@front/volcanion/types"

import moment from 'moment'

class Callbacks {
  static afterDateChangeHandler() {
    return function afterDateChange(value: MaybeMultiple<IFormFieldValue>, prev_value: MaybeMultiple<IFormFieldValue>, listener: IFormFieldListener<IFormFieldValue>) {
      const parentFormListener = listener.getParentForm()

      const date_start = parentFormListener.getField('date_start').getValue() as string
      const date_end = parentFormListener.getField('date_end').getValue() as string
      const daysDifference = moment(date_end).diff(moment(date_start), 'days')
      parentFormListener.getField('unit').setValue(daysDifference > 730 ? 'year' : 'day')
    }
  }

  static afterPeriodChangeHandler() {
    return function afterPeriodChange(value: MaybeMultiple<IFormFieldValue>, prev_value: MaybeMultiple<IFormFieldValue>, listener: IFormFieldListener<IFormFieldValue>) {
      const parentFormListener = listener.getParentForm()

      let date_start = moment().startOf('day').format()
      let date_end = moment().endOf('day').format()

      switch (value) {
        case 'current_month':
          date_start = moment().startOf('month').format()
          date_end = moment().format()
          break
        case 'last_month':
          date_start = moment().subtract(1, 'month').startOf('month').format()
          date_end = moment().subtract(1, 'month').endOf('month').format()
          break
        case 'last_thirty_days':
          date_start = moment().subtract(30, 'days').format()
          date_end = moment().format()
          break
        case 'last_ninety_days':
          date_start = moment().subtract(90, 'days').format()
          date_end = moment().format()
          break
        case 'start_of_year':
          date_start = moment().startOf('year').format()
          date_end = moment().format()
          break
        case 'last_year':
          date_start = moment().subtract(1, 'year').startOf('year').format()
          date_end = moment().subtract(1, 'year').endOf('year').format()
          break
        default:
          break
      }

      parentFormListener.getField('date_start').setValue(date_start)
      parentFormListener.getField('date_end').setValue(date_end)

    }
  }

}

export default Callbacks

