
import React from 'react'
import { I18n, withSlot, hooks } from '@front/volcanion'

import _ from 'lodash'
import { Box, Typography } from '@mui/material'

import { Theme } from '@mui/material/styles'

const CalLDriver: React.FC = props => {
  const theme = hooks.useTheme() as Theme
  return (
    <Box>
      <Typography>
        {'Pour joindre le chauffeur, cliquer sur '}
        <span style={{ fontWeight: 'bold', marginLeft: '4px', marginRight: '4px' }}>{'Valider'}</span>
        {'puis composez le numéro '}
        <span style={{ color: theme?.palette?.secondary?.main, marginLeft: '4px', marginRight: '4px' }}>{'0612345678'}</span>
        {'depuis le téléphone dont le numéro d\'appel est le'}
        <span style={{ color: theme?.palette?.secondary?.main, marginLeft: '4px', marginRight: '4px' }}>{'0112345678'}</span>
      </Typography>
    </Box >
  )
}

export default CalLDriver
