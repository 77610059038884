import React from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Elements } from '@front/squirtle'

import type { OrdersCountProps } from './types'
import type { Colors } from '../types'

import moment from 'moment'

import _ from 'lodash'

const OrdersCountChart: React.FC<OrdersCountProps> = ({ unit, data, date_end, date_start }) => {
  const theme = hooks.useRelayConfig('COLORS') as Colors

  const datasets = [{
    label: I18n.t('dashboard.order.complete'),
    data: _.filter(data, ['status', 'complete']),
    backgroundColor: theme.primary[600],
    borderColor: theme.primary[700],
  }, {
    label: I18n.t('dashboard.order.cancel'),
    data: _.filter(data, ['status', 'canceled']),
    backgroundColor: theme.error[600],
    borderColor: theme.error[700],
  }, {
    label: I18n.t('dashboard.order.not_loaded'),
    data: _.filter(data, ['reason', 'not_served']),
    backgroundColor: theme.gray[600],
    borderColor: theme.gray[700],
  }]

  const maxDataValue = _.maxBy(data, 'y')?.y as number
  const stepCount = 5
  const rawStepSize = maxDataValue / stepCount
  const stepSize = _.max([_.ceil(rawStepSize), 5]) as number
  const maxValue = _.ceil(maxDataValue / stepSize)

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          font: { size: 16 },
          left: -40
        }
      }
    },
    scales: {
      x: {
        type: 'time' as const,
        time: { unit: unit as any },
        min: moment(date_start).format('YYYY-MM-DD'),
        max: moment(date_end).format('YYYY-MM-DD'),
        stacked: true,
        ticks: { autoSkip: false },
        grid: { display: false }
      },
      y: {
        stacked: true
      },
    }
  }


  return (
    <Elements.Chart
      config={{
        type: 'bar',
        data: { datasets },
        options
      }}
      style={{ width: '100%', height: '280px' }}
      watchers={[unit, stepSize]}
    />
  )
}

export default OrdersCountChart
