
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import _ from 'lodash'

import { FormFactory, Loader } from '@front/squirtle'

import FormContent from './index'
import Callbacks from './callbacks'

const Booking: React.FC = () => {
  const [, { openDialog }] = hooks.useDialogs()
  const [user, user_status] = hooks.useActiveUser()
  const { openNotification } = hooks.useNotification()

  const getEstimation = hooks.useEstimationSelector()
  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(user?.user_id), [user?.user_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])
  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(getEstimation), [getEstimation])
  const onSubmitSuccess = useCallback(Callbacks.onSubmitSuccessHandler(openDialog), [openDialog])
  const onSubmitFail = useCallback(Callbacks.onSubmitFailHandler(openNotification), [openNotification])

  return (
    <Loader isLoading={!HookUtils.getReadyState([user_status])} nativeLoader type='linear'>
      <FormFactory.Form
        name='order_book'
        listener_type='form'
        getEmptyState={getEmptyState}
        getEmptyForm={getEmptyForm}
        formToSubmit={formToSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={onSubmitFail}
        config={{
          model_name: 'order',
          ids: []
        }}
      >
        <FormContent />
      </FormFactory.Form>
    </Loader>
  )
}

export default Booking
