import * as React from 'react'
import { hooks } from '@front/volcanion'
import type { ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const HeaderComponent: React.FC = props => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center'>Entreprise</TableCell>
        <TableCell align='center' >Contrat</TableCell>
        <TableCell align='center' >Service</TableCell>
        <TableCell align='center' >Paiement</TableCell>
        <TableCell align='center' >Facturation différée </TableCell>
      </TableRow>
    </TableHead>
  )
}

const RowComponent: React.FC<{ row: ModelRecord }> = ({ row }) => (
  <TableRow key={row.name}>
    <TableCell align="center"> {row?.companyservice?.contract?.company?.name}</TableCell>
    <TableCell align="center">{`${row?.companyservice?.contract?.name} - ${row?.companyservice?.contract?.client_id}`}</TableCell>
    <TableCell align="center">{`${row?.companyservice?.name} - ${row?.companyservice?.client_id}`}</TableCell>
    <TableCell align="center">{'En compte'}</TableCell>
    <TableCell align="center">{!!row?.companyservice?.deferred_billing ? 'Autorisé' : 'Interdit'}</TableCell>
  </TableRow>
)

const BasicTable: React.FC = props => {
  const [user] = hooks.useActiveUser()
  const [companyuserservice] = hooks.useModelSearch('companyuserservices', 'get', {
    initial_filter: {},
    forced_filter: {
      companyuser: { user: user?.user_id }
    },
    populate: ['companyuser', 'companyservice.contract.company'],
    watchers: [user?.user_id]
  })
  return (
    <TableContainer>
      <Table>
        <HeaderComponent />
        <TableBody>
          {_.map(companyuserservice, (row) => (<RowComponent row={row} />))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
