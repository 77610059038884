import React, { useCallback, useEffect } from "react"
import { I18n, hooks, FormHooks, HookUtils } from "@front/volcanion"

import { Button } from '@mui/material'
import _ from "lodash"

import Component from "./component"
import type { IFormFieldValue } from "@front/volcanion/types"

const withContainer: React.FC = () => {
  const { openNotification, closeNotification } = hooks.useNotification()
  const formListener = FormHooks.useFormListener()
  const [commercial_package] = FormHooks.useFieldValues(['commercial_package'])

  const estimation_status = hooks.useEstimationStatus()
  const [selectedEstimation] = hooks.useSelectedEstimation(commercial_package as string)

  const openValidateNotification = useCallback(() => {
    const errors = selectedEstimation?.validation?.errors
    _.map(errors, error => {
      const argument = error?.context?.argument
      const suggestion = error?.context?.suggestion
      const type = error?.type
      const message = error?.message
      !!message && openNotification(message, { variant: type }, {
        preventDuplicate: true,
        persist: true,
        action: !!suggestion ? (key: string) =>
          <Button
            onClick={() => {
              formListener().getField<IFormFieldValue>(argument).setValue(suggestion)
              closeNotification(key)
            }}
            sx={{ color: 'white', textDecoration: 'underline' }}
            variant='text'>
            {I18n.t('action.apply')}
          </Button>
          : undefined
      })
    })
  }, [openNotification, closeNotification, formListener, selectedEstimation])

  const isEstimationLoading = HookUtils.getLoadingState([estimation_status])

  useEffect(() => {
    !isEstimationLoading && openValidateNotification()
    // TODO REMOVE WHEN SELECTFIRST FIX 
  }, [isEstimationLoading, commercial_package])

  const mergedProps = {
  }

  return (
    <Component{...mergedProps} />
  )
}

export default withContainer
