
import React, { useEffect } from 'react'
import _ from 'lodash'

import { Box, Grid2 as Grid } from '@mui/material'

import {
  Addresses,
  PaymentMethods,
  PrimarySection,
  ProfileSection,
  Companies,
  Preferences
} from './Section'

import Popups from './Popups'

const FormComponent: React.FC = props => {

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => { document.body.style.overflow = '' }
  }, [])

  return (
    <Box >
      <Box pr={1}>
        <Popups />
        <Grid container spacing={1}>

          <Grid size={{ xs: 6, lg: 4 }}><PrimarySection /></Grid>
          <Grid size={{ xs: 6, lg: 8 }} justifyContent='center' rowSpacing={1} sx={{
            overflowY: 'auto',
            flexGrow: 1,
            p: 1,
            maxHeight: window.innerHeight
          }}>
            <Grid size={12}><ProfileSection /></Grid>
            <Grid size={12}><Preferences /></Grid>
            <Grid size={12}><Addresses /></Grid>
            <Grid size={12}><Companies /></Grid>
            <Grid size={12}><PaymentMethods /></Grid>
          </Grid>

        </Grid>
      </Box >
    </Box>
  )
}

export default FormComponent
