import React, { useCallback } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['assigned_transport.info', 'service.contract.formula', 'do.commercial_formula'] })

  const [location_history_ids] = hooks.useModelSearchList('locationhistory', 'sample', {
    initial_filter: {},
    forced_filter: {
      job: order?.job,
      createdAt: {
        '>=': order?.requestedAt
      }
    },
    validate_filter: useCallback(() => !!order?.job && !!order?.requestedAt, [order?.job, order?.requestedAt]),
    watchers: [order?.job, order?.requestedAt]
  })

  const loacations = location_history_ids as string[]
  const source = order?.source
  const destination = order?.destination

  const mergedProps = {
    location_history_ids: loacations,
    destination,
    source
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
