import React, { useEffect } from "react"
import { FormHooks, hooks, HookUtils, type VTypes } from "@front/volcanion"
import { Loader } from "@front/squirtle"

import _ from "lodash"

import Component from "./component"
import NoResultComponent from "../noResult"
import { UsersCountProps } from "./types"

const withContainer: React.FC = () => {
  const [company] = hooks.useGlobalState('persist.company_id')
  const [contract, service, user, date_start, date_end, unit] = FormHooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end', 'unit']) as VTypes.V__ANY

  const [data, search, state] = hooks.useModelValues('companyuser', 'get', {})
  const [dataHistory, searchHistory, stateHistory] = hooks.useModelValues('companyuser', 'get', {})

  const filter = {
    createdAt: { '>=': date_start, '<=': date_end },
    company: { company_id: company },
    services: !!service && [service] || !!contract && { contract: { companycontract_id: contract } } || undefined
  }
  const filterHistory = {
    createdAt: { '<': date_start },
    company,
    services: !!service && [service] || !!contract && { contract: { companycontract_id: contract } } || undefined
  }


  const options = {
    group_by: ['DATE(createdAt) AS x'],
    no_select: true,
    query_operations: [{ path: 'companyuser_id', operation: 'COUNT', alias: 'y' }],
    sort: ['DATE(createdAt) ASC'],
    select: [] as [],
    log_query: true,
    limit: -1,
    type: 'b2b'
  }
  const optionsHistory = {
    no_select: true,
    query_operations: [{ path: 'companyuser_id', operation: 'COUNT', alias: 'originValue' }],
    sort: ['DATE(createdAt) ASC'],
    select: [] as [],
    single: true,
    type: 'b2b'
  }

  useEffect(() => {
    search({ filter, options })
  }, [contract, service, user, date_start, date_end, company])


  useEffect(() => {
    searchHistory({ filter: filterHistory, options: optionsHistory })
  }, [contract, service, user, date_start, company])

  const cumulativeData = _.reduce(data as UsersCountProps['data'], (acc, curr, index) => {
    const prevValue = index > 0 ? acc[index - 1].y : 0
    acc.push({ x: curr.x, y: prevValue + curr.y })
    return acc
  }, [] as UsersCountProps['data'])


  useEffect(() => {
    searchHistory({ filter: filterHistory, options: optionsHistory })
  }, [contract, service, user, date_start])

  const mergedProps = {
    unit: unit as string,
    data: cumulativeData,
    date_start: date_start as string,
    date_end: date_end as string
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
