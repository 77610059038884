import React from 'react'
import { FormHooks, I18n } from '@front/volcanion'

import _ from "lodash"

import { Box, Typography } from "@mui/material"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import { BottomBarProps } from './types'
import { Loader, SubmitButton } from '@front/squirtle'

const GlobalActions: React.FC = () => (
  <Box display='flex' alignItems='center'>
    <Typography mx={1} variant='h6'>{I18n.t('are_you_ready')}</Typography>
    <SubmitButton label={I18n.t('action.book')} sx={{ textTransform: 'unset', ml: 1 }} endIcon={<ArrowRightAltIcon />} />
  </Box>
)


const BottomBar: React.FC<BottomBarProps> = ({ price, isLoading }) => (
  <Box sx={{ mt: 2, ml: '-57px', px: '1%', width: '-webkit-fill-available', backgroundColor: 'white', position: 'fixed', bottom: 0, height: { xs: 80, md: 72 }, boxShadow: '0px -4px 20px 4px rgba(35, 35, 35, 0.15)' }}>
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box ml='57px'>
        {!!price && <Loader isLoading={isLoading} size={10} type='linear'>
          <Typography textAlign={{ xs: 'center', md: 'start' }} fontWeight='bold' fontSize={{ xs: 16, sm: 20 }} color='text'>
            {`${I18n.t('total.price_estimated')} :`} {price}</Typography>
        </Loader>}
      </Box>
      <GlobalActions />
    </Box>
  </Box >
)

export default BottomBar
