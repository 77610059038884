import React, { useCallback, useEffect } from 'react'
import { I18n, hooks, FormHooks, type VTypes } from '@front/volcanion'

import { Grid, Box, Link, Typography } from '@mui/material'

import { FormFactory } from '@front/squirtle'
import Callbacks from './callbacks'

const FirstStep: React.FC = props => (
  <Grid container rowSpacing={2}>
    <Grid item xs={12} >
      <FormFactory.TextField name='gsm' disabled _native={{ fullWidth: false, label: I18n.t('gsm.label.old') }} />
    </Grid>
    <Grid item xs={12} >
      <Typography color='primary'>{I18n.t('gsm.change.first_step')}</Typography>
    </Grid>
    <Grid item xs={12} >
      <FormFactory.PhoneInputField name='new_gsm' required _native={{ label: I18n.t('gsm.label.new') }} />
    </Grid>
  </Grid>
)

const SecondStep: React.FC = props => {
  const { openNotification } = hooks.useNotification()
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const [new_gsm] = FormHooks.useFieldValues(['new_gsm']) as VTypes.V__ANY
  const [activeStep, setActiveStep] = hooks.useGlobalState('activeStep')

  const onRequestSubmit = useCallback(Callbacks.onRequestSubmitHandler(openNotification, updateUser, {}), [openNotification, updateUser])
  const afterCodeChange = useCallback((value: string) => {
    activeStep === 2 && !!value && value.length === 4 && onRequestSubmit(false, value)
  }, [onRequestSubmit, activeStep])

  return (
    <Grid container justifyContent='center' rowSpacing={3}>
      <Grid item xs={12}>
        <Typography color='primary'>{I18n.t('gsm.change.second_step', { new_gsm })}</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormFactory.PhoneVerificationField name='code' afterChange={afterCodeChange} />
      </Grid>
      <Grid item xs={12}>
        <Link href='#' onClick={() => setActiveStep(1)}>
          <Typography textAlign='center' color='secondary' variant='subtitle1' mt={1}>{I18n.t('gsm.link.change.gsm')}</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}

const GsmTitle: React.FC = props => {
  const [activeStep,] = hooks.useGlobalState('activeStep')

  return <Box>
    <Box display={'flex'} justifyContent={'flex-end'}> <Typography color='secondary'>{`${activeStep}/2`} </Typography></Box>
    <Typography variant='h5' color='primary'>{'Modification du numéro de téléphone'} </Typography>
  </Box>
}

const GsmModify: React.FC = props => {
  const [activeStep, setActiveStep] = hooks.useGlobalState('activeStep')

  useEffect(() => {
    !activeStep && setActiveStep(1)
  }, [])

  return (
    <Box >
      <Box hidden={activeStep === 2}> <FirstStep /></Box>
      <Box hidden={activeStep === 1}> <SecondStep /></Box>
    </Box>
  )
}


export default GsmModify

export {
  GsmTitle
}