
import React from 'react'
import { I18n, VTypes } from '@front/volcanion'

import moment from 'moment'
import _ from 'lodash'

import { Button } from "@mui/material"

import { TimeUtils } from '@front/volcanion/utils'
import { IFormBaseListener, IFormFieldValue, ISubmitMap, ModelRecord, type MaybeMultiple, type NotificationHandler } from '@front/volcanion/types'
import type { AddressObject } from '@front/volcanion/utils/geo'
import type { EstimationObject } from '@front/volcanion/hooks/model/estimation/types'

class Callbacks {
  static getEmptyStateHandler(user_id: string) {
    return function getEmptyState() {
      return {
        user_id: user_id,
        schedule_type: 'immediate',
        guest_booking: true,
        source_option: {
          includeRecent: true,
          includeFavorite: true,
          includePOI: true,
          includeExternal: true
        },
        destination_option: {
          includeRecent: true,
          includeFavorite: true,
          includePOI: true,
          includeExternal: true
        }
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        load_count: 1,
        requestedAt: moment().format(),
      }
    }
  }

  static onSubmitSuccessHandler(openDialog: (name: string, extra: object) => void) {
    return function onSubmitSuccess(form: IFormBaseListener, record: ModelRecord, submit_map: ISubmitMap) {
      openDialog('booking_success', { title: I18n.t('order.created'), order_id: record?.order_id })
    }
  }

  static onSubmitFailHandler(openNotification: NotificationHandler["openNotification"]) {
    return function onSubmitFail(instance: IFormBaseListener, error: any, submit_map: ISubmitMap) {
      if (error.getCode() === 'UNAUTHORIZED_COMPANYUSER')
        openNotification(I18n.t('error.order.unauthorized.create'), { variant: 'error' })
    }
  }

  static formToSubmitHandler(getEstimation: (value?: string, type?: string) => EstimationObject | undefined) {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      const commercial_package = form.getFieldValue('commercial_package')
      const source = form.getFieldValue('source') as AddressObject
      const destination = form.getFieldValue('destination')

      const ridemode_duration = form.getFieldValue('ridemode_duration')
      const reservationinfo = form.getFieldValue('reservationinfo')
      const passenger_name = form.getFieldValue('passenger_name')
      const passenger_number = form.getFieldValue('passenger_number')
      const requestedAt = form.getFieldValue('requestedAt') as string
      const returnAt = form.getFieldValue('returnAt') as string
      const schedule_type = form.getStateValue('schedule_type')

      const estimation = getEstimation(commercial_package as string & IFormFieldValue)
      const estimation_id = _.get(estimation, 'estimation_id')

      const final_loads = {
        src_contact: passenger_name,
        src_phone: passenger_number,
        dest_contact: passenger_name,
        dest_phone: passenger_number
      }
      const final_info = {
        meeting_point_trkey: _.get(reservationinfo, 'arrival_trkey'),
        driver_instruction_trkey: _.get(reservationinfo, 'driver_instruction_trkey'),
        duration: ridemode_duration
      }
      const final_reservationinfos = _.has(reservationinfo, 'reservationinfo_id')
        ? [{
          reservationinfo: _.get(reservationinfo, 'reservationinfo_id'),
          name: _.get(reservationinfo, 'name'),
          origin: _.get(reservationinfo, 'origin')
        }]
        : undefined

      const final_returnAt = !!returnAt
        ? TimeUtils.getDetailsMomentBack(returnAt, source)
        : undefined

      const final_requestedAt = schedule_type === 'immediate'
        ? undefined :
        TimeUtils.getDetailsMomentBack(requestedAt, source)

      submit_map
        .setDataKey('do', form.getStateValue('user_id'))
        .setDataKey('source', source)
        .setDataKey('destination', destination)
        .setDataKey('loads', final_loads)
        .setDataKey('info', final_info)
        .setDataKey('calling_number', passenger_number)
        .setDataKey('requestedAt', final_requestedAt)
        .setDataKey('returnAt', final_returnAt)
        .setDataKey('commercial_package', commercial_package)
        .setDataKey('estimation_id', estimation_id)
        .setDataKey('schedule_type', schedule_type)
        .setDataKey('reservationinfos', final_reservationinfos)
        .setDataKey('payment_type', form.getStateValue('payment_type'))
        .setDataKey('payment_method', form.getStateValue('payment_method'))
        .setDataKey('customer_ref', form.getFieldValue('customer_ref'))
        .setDataKey('mode', form.getFieldValue('ridemode'))
        .setDataKey('has_luggage', form.getFieldValue('has_luggage'))
        .setDataKey('driver_comment', form.getStateValue('driver_comment'))
        .setDataKey('driver_opts', form.getFieldValue('driver_opts'))
        .setDataKey('vehicle_opts', form.getFieldValue('vehicle_opts'))
        .setDataKey('service', form.getFieldValue('service'))
        .setDataKey('confirmed', true)

      return submit_map
    }
  }

}

export default Callbacks

