
import React from 'react'
import { FormHooks, I18n, VTypes, hooks, withSlot } from "@front/volcanion"

import _ from 'lodash'

import { Box, Typography, Grid, Divider } from '@mui/material'
import { FormFactory } from '@front/squirtle'
import { DecoratorLabelOptionSlot } from '@front/squirtle/FormFactory/OptionSelector/types'

const CustomLabel = withSlot<{}, DecoratorLabelOptionSlot>(({ option }) => (
  <Typography color='secondary'> {option?.label}</Typography>
))

const PackageOptions: React.FC = props => {
  const [{ poi_id, reservationinfo_pick_type }] = FormHooks.useFormState() as VTypes.V__ANY
  const [poi] = hooks.useModel('poi', [poi_id], { single: true, populate: ['type.authorized_pois', 'type.reservationinfo'] })

  const required = reservationinfo_pick_type === 'arrival'
  const reservationinfo = poi?.type?.reservationinfo
  const arrival = reservationinfo?.arrival_translated
  const meeting_point = poi?.meeting_point
  const placeholder_name = _.compact([reservationinfo?.name_translated, !!required && '*']).join('')
  const placeholder_origin = _.compact([reservationinfo?.origin_translated, !!required && '*']).join('')

  return (
    <Box>
      <Grid container justifyContent='center' rowSpacing={3}>
        <Grid item xs={12}>
          <FormFactory.OptionSelectorField
            name='reservationinfo_pick_type'
            _native={{
              label: I18n.t('address.reservationinfo.source'),
              _label: { sx: { fontSize: 17, color: 'primary', p: 1 } },
              valueType: 'checkbox',
              orientation: 'vertical'
            }}
            options={[
              { label: arrival, value: 'arrival' },
              { label: meeting_point, value: 'meeting_point' }
            ]}
            getOptionProps={() => ({ controlProps: { size: 'sm' } })}
            clearOnEmpty
            selectFirst
          >
            <CustomLabel slot='label_option_slot' />
          </FormFactory.OptionSelectorField>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography component='div' sx={{ '& .highlight': { color: 'secondary.main', fontWeight: 'bold' } }}>
            <span className={'highlight'}> {_.toUpper(I18n.t(required ? 'mandatory' : 'optional'))} </span>
            {I18n.t('address.reservationinfo.pickup', { type: '', name: reservationinfo?.name_translated, origin: reservationinfo?.origin_translated })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormFactory.TextField
            name='name'
            _native={{
              placeholder: placeholder_name,
            }}
            required={required}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFactory.TextField
            name='origin'
            _native={{
              placeholder: placeholder_origin
            }}
            required={required}
          />
        </Grid>
      </Grid>
    </Box >
  )
}

export default PackageOptions
