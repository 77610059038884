import FieldListener from '@front/volcanion/classes/providers/form/listener/field'
import { ModelRecord, type IFormFieldListener, type IFormFieldValue, type MaybeMultiple } from '@front/volcanion/types'
import { UserUtils } from '@front/volcanion/utils'

class Callbacks {
  static afterChangeHandler() {
    return function afterChange(value: MaybeMultiple<IFormFieldValue>, prev_value: MaybeMultiple<IFormFieldValue>, listener: IFormFieldListener<IFormFieldValue>) {
      const companyuser = listener.getValueOption(value) as ModelRecord
      const parentFormListener = listener.getParentForm()
      const companyuser_name = UserUtils.getFormatedName({ ...companyuser.contact, designation: companyuser.user?.customerinfo?.name })
      const companyuser_number = companyuser?.user?.auth?.gsm

      parentFormListener.getField('passenger_name').setValue(companyuser_name)
      parentFormListener.getField('passenger_number').setValue(companyuser_number)
      parentFormListener.setStateKey('guest_booking', !!companyuser?.guest_booking)
      parentFormListener.setStateKey('user_id', companyuser?.user?.user_id)
    }
  }
}

export default Callbacks
