
import React from 'react'
import { I18n, withSlot } from '@front/volcanion'

import _ from 'lodash'
import { Box } from '@mui/material'

import { DialogForm, Elements } from '@front/squirtle'
import { DecoratorContentSlot, DecoratorTitleSlot } from '@front/squirtle/Components_ts/Dialog/types'

import { PopupsProps } from './types'

import AddressModify, { AddressTitle } from './AddressModify'
import PasswordModify from './PasswordModify'
import GsmModify, { GsmTitle } from './GsmModify'
import PaymentDetail from './PaymentDetail'


const GsmTitleSlot = withSlot<{}, DecoratorTitleSlot>(props => <GsmTitle {...props} />)

const GsmModifySlot = withSlot<{}, DecoratorContentSlot>(props => <GsmModify {...props} />)
const PaymentDetailSlot = withSlot<{}, DecoratorContentSlot>(props => <PaymentDetail {...props} />)
const PaymentAddSlot = withSlot<{}, DecoratorContentSlot>(props => <Elements.AddCard  {...props} />)
const PasswordModifySlot = withSlot<{}, DecoratorContentSlot>(props => <PasswordModify {...props} />)
const AddressModifySlot = withSlot<{}, DecoratorContentSlot>(props => <AddressModify {...props} />)
const AddressTitleSlot = withSlot<{}, DecoratorTitleSlot>(props => <AddressTitle {...props} />)

const PopupComponent: React.FC<PopupsProps> = ({
  onSubmitCard,
  onSubmitAddressModify,
  onSubmitPasswordModify,
  onSubmitGsmModify,
  recordToFormAddress,
  recordToStateAddress,
  getEmptyGsmForm,
  getEmptyPaymentAddForm,
  isValidPassword,
  allowConfirmCloseGsm,
  onSubmitPaymentDetail
}) => {
  return (
    <Box>
      <DialogForm
        name={'address_modify'}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        formProps={{
          onSubmit: onSubmitAddressModify,
          recordToForm: recordToFormAddress,
          recordToState: recordToStateAddress
        }}
        maxWidth='md'
      >
        <AddressTitleSlot slot='dialog_title' />
        <AddressModifySlot slot='dialog_content' />
      </DialogForm>

      <DialogForm
        name={'password_modify'}
        title={I18n.t('password.modify')}
        _title={{ variant: 'h4', color: 'text' }}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        _button_confirm={{ label: I18n.t('action.save'), disabled: !isValidPassword }}
        formProps={{
          onSubmit: onSubmitPasswordModify,
        }}
        maxWidth='sm'
      >
        <PasswordModifySlot slot='dialog_content' />
      </DialogForm>

      <DialogForm
        name={'gsm_modify'}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        _button_confirm={{ label: I18n.t('action.save') }}
        formProps={{
          onSubmit: onSubmitGsmModify,
          getEmptyForm: getEmptyGsmForm
        }}
        maxWidth='sm'
        allowConfirmClose={!!allowConfirmCloseGsm}
      >
        <GsmTitleSlot slot='dialog_title' />
        <GsmModifySlot slot='dialog_content' />
      </DialogForm>

      <DialogForm
        name={'payment_detail'}
        title={I18n.t('card.detail')}
        _title={{ variant: 'h5', color: 'text' }}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        _button_confirm={{ label: I18n.t('action.save') }}
        formProps={{
          onSubmit: onSubmitPaymentDetail
        }}
        maxWidth='sm'
      >
        <PaymentDetailSlot slot='dialog_content' />
      </DialogForm>

      <DialogForm
        name='payment_add'
        title={I18n.t('card.add')}
        _title={{ variant: 'h5', color: 'text' }}
        _button={{ fullWidth: true, sx: { mx: 2 } }}
        _button_cancel={{ variant: 'outlined' }}
        _action={{ sx: { display: 'flex', justifyContent: 'center', width: '100%', mb: 1, mt: 3 } }}
        formProps={{
          onSubmit: onSubmitCard,
          getEmptyForm: getEmptyPaymentAddForm
        }}
      >
        <PaymentAddSlot slot='dialog_content' />
      </DialogForm>
    </Box >
  )
}

export default PopupComponent
