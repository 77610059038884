import React, { useCallback, useState } from 'react'
import { I18n, hooks, HookUtils } from '@front/volcanion'

import _ from 'lodash'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  ListItemText,
  List,
  ListItemButton,
  Typography
} from '@mui/material'

import type { DrawerProps, ListItemButtontTypes } from './types'
import { AdminList, CommonList, OrdersList, CustomListItemIcon, ListItemButtonProps, ListItemTextProps } from './List'
import SubHeaderCompany from './SubHeader/Company'
import DrawerHeader from './Header/'

import { Drawer } from './styles'
import Callbacks from './callbacks'


const ListItemButtonComponent: React.FC<ListItemButtontTypes> = ({ name, label, open, isSubOpen, hasSubMenu = false, enableSelection = false, iconName, onClick }) => {
  const [{ location }] = hooks.useRouter()
  const isSelected = !!enableSelection && _.includes(location?.pathname, name)
  return (
    <ListItemButton selected={isSelected} onClick={onClick} {...ListItemButtonProps} >
      {!!(!open || !!iconName) && <CustomListItemIcon name={'drawer'} category={'icons'} variant={iconName || 'separator'} />}
      <ListItemText hidden={!open} {...ListItemTextProps} primary={label} />
      <Box hidden={!hasSubMenu || !open}> {!!isSubOpen ? <ExpandLess /> : <ExpandMore />}</Box>
    </ListItemButton >
  )
}

const MiniDrawer: React.FC<DrawerProps> = ({ setOpen, open }) => {
  const version = hooks.useVersion()
  const navigate = hooks.useNavigate()
  const [user] = hooks.useActiveUser()
  const [company] = hooks.useGlobalState('persist.company_id')
  const [isExpand, setExpand] = hooks.useGlobalState('isExpand')
  const [subListExpand, setSubListExpand] = useState(['order', 'admin'])
  const [[companyuser], , companyuser_state] = hooks.useModelSearch('companyuser', 'get', {
    initial_filter: {},
    forced_filter: { company, user: user?.user_id },
    single: true,
    populate: ['company'],
    watchers: [user?.user_id, company]
  })

  const isAdmin = !HookUtils.getLoadingState([companyuser_state]) && !!companyuser?.administration_level

  const onMouseEnter = useCallback(Callbacks.onMouseActionHandler(isExpand as boolean, true, setOpen), [isExpand, setOpen])
  const onMouseLeave = useCallback(Callbacks.onMouseActionHandler(isExpand as boolean, false, setOpen), [isExpand, setOpen])
  const onStaticExpand = useCallback(Callbacks.onStaticExpandHandler(isExpand as boolean, setOpen, setExpand), [isExpand, setOpen, setExpand])
  const onClickBook = useCallback(() => navigate('book'), ['book', navigate])
  const onClickSubExpand = useCallback((name: string) => {
    if (name === 'order' && _.includes(subListExpand, 'order') || name === 'admin' && _.includes(subListExpand, 'admin')) {
      setSubListExpand(_.filter(subListExpand, elem => name !== elem))
    } else {
      setSubListExpand(_.flatten([subListExpand, name]))
    }
  }, [subListExpand, setSubListExpand])

  const inSubOrderList = _.includes(subListExpand, 'order') || !open
  const inSubAdminList = (_.includes(subListExpand, 'admin') || !open) && !!isAdmin
  return (
    <Box display='flex'>
      <Drawer
        variant={'permanent'}
        open={open}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <DrawerHeader setOpen={setOpen} open={open} />
        <Divider />
        <Box display='flex' flex={1} flexDirection='column' justifyContent='space-between' alignItems={!!open ? 'flex-start' : 'center'} mb={2}>
          <Box sx={{ width: '100%' }}>
            <List subheader={<SubHeaderCompany open={open} />}>
              <ListItemButtonComponent name='book' onClick={onClickBook} label={I18n.t('action.book')} open={open} iconName='booking' enableSelection />
              <ListItemButtonComponent name='orders' onClick={() => onClickSubExpand('order')} label={I18n.t('order.my', { count: -1 })} open={open} hasSubMenu isSubOpen={inSubOrderList} />
              <Collapse in={inSubOrderList} timeout="auto" unmountOnExit><OrdersList open={open} /></Collapse>
              {!!isAdmin && <ListItemButtonComponent name='admin' onClick={() => onClickSubExpand('admin')} label={I18n.t('admin.space')} open={open} hasSubMenu={!!isAdmin} isSubOpen={inSubAdminList} />}
              <Collapse in={inSubAdminList} timeout="auto" unmountOnExit ><AdminList open={open} /></Collapse>
            </List >
          </Box>

          <Box sx={{ width: '100%' }}>
            <CommonList open={open} />
            <List>
              <ListItemButton onClick={(e: any) => onStaticExpand()} sx={{ minHeight: 46, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: !open ? 1 : 0 }}>
                <CustomListItemIcon name={'drawer'} category={'icons'} variant='collapse' />
                <ListItemText>{""}</ListItemText>
              </ListItemButton>
            </List>
            <Typography textAlign='center'>{version}</Typography>
          </Box>
        </Box>
      </Drawer >
    </Box >
  )
}

export default MiniDrawer