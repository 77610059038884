import React, { useEffect } from "react"
import { FormHooks, hooks, HookUtils, type VTypes } from "@front/volcanion"
import { Loader } from "@front/squirtle"

import _ from "lodash"

import Component from "./component"
import NoResultComponent from "../noResult"

const withContainer: React.FC = () => {
  const [contract, service, user, date_start, date_end, schedule_type, unit] = FormHooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end', 'schedule_type', 'unit']) as VTypes.V__ANY
  const [company] = hooks.useGlobalState('persist.company_id') as VTypes.V__ANY
  const [data, search, state] = hooks.useModelValues('order', 'get', {})

  const default_filter = {
    requestedAt: { '>=': date_start, '<=': date_end },
    service: (!!service && { companyservice_id: service }) || { contract: (!!contract && { companycontract_id: contract }) || { company: { company_id: company } } },
    do: user || undefined,
    schedule_type: schedule_type || undefined
  }
  const filter = {
    or: [
      _.merge({}, default_filter, { reason: 'not_served' }),
      _.merge({}, default_filter, { status: { '!': 'canceled' } }),
      _.merge({}, default_filter, { reason: { '!': 'not_served' }, status: 'canceled' })
    ]
  }

  const options = {
    group_by: ['DATE(requestedAt) AS x', 'status AS status', 'reason AS reason', 'schedule_type'],
    no_select: true,
    query_operations: [{ path: 'order_id', operation: 'COUNT', alias: 'y' }],
    sort: ['DATE(requestedAt) DESC'],
    select: [] as [],
    limit: -1,
    type: 'b2b'
  }
  useEffect(() => {
    search({ filter, options })
  }, [contract, service, user, date_start, date_end, schedule_type, company])

  const mergedProps = {
    unit: unit as string,
    data: data as { x: number, y: number }[],
    date_end: date_end as string,
    date_start: date_start as string
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
