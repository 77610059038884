import React from "react"
import { I18n, FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: [] })

  const schedule_type = I18n.t(`schedule.immediate`)
  const waiting_time = 'TODO'
  const passenger_number = order?.passenger_count
  const has_luggage = order?.has_luggage
  const driver_comment = order?.driver_comment || I18n.t('none')

  const mergedProps = {
    schedule_type,
    waiting_time,
    passenger_number,
    has_luggage,
    driver_comment
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
