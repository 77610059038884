import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'
import { Button, Icon } from '@mui/material'


const PaymentAdd: React.FC = props => {
  const [, { openDialog }] = hooks.useDialogs()
  return (
    <Button
      fullWidth
      size='large'
      variant='text'
      sx={{
        textTransform: 'unset',
        boxShadow: 3,
        p: 1,
        flex: 1,
        height: 100,
        opacity: 0.4,
        '&:hover': { opacity: 1 }
      }}
      onClick={() => openDialog('payment_add')}
      startIcon={<Icon>{'credit_card'}</Icon>}
    >
      {I18n.t('payment.add_card')}
    </Button>
  )
}

export default PaymentAdd
