import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { List, ListItemButton, ListItemText } from '@mui/material/'

import { CustomListItemIcon, ListItemButtonProps, ListItemTextProps } from '../'

const OrdersList: React.FC<{ open: boolean }> = ({ open }) => {
  const [{ location }] = hooks.useRouter()
  const navigate = hooks.useNavigate()
  return (
    <List dense disablePadding>
      <ListItemButton onClick={() => navigate('orders/history')} selected={_.includes(location?.pathname, 'orders/history')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='history' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.order.history')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('orders/current')} selected={_.includes(location?.pathname, 'orders/current')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='in_progress' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.order.in_progress')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('orders/planned')} selected={_.includes(location?.pathname, 'orders/planned')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='in_coming' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.order.planned')} />
      </ListItemButton>
    </List >
  )
}

export default OrdersList