
import React from 'react'
import _ from 'lodash'

import { Box, Typography } from '@mui/material'
import type { BillingInformationProps } from './types'

const BillingInformationComponent: React.FC<BillingInformationProps> = ({ customer_ref, payment_type }) => {
  return (
    <Box display='flex'>
      <Box hidden={!customer_ref}>
        <Box display='flex'>
          <Typography color='primary'>{'Ref. facturation :'}</Typography>
          <Typography mx={1} sx={{ color: 'text.main' }} >{customer_ref}</Typography>
        </Box>
      </Box>
      <Box hidden={!payment_type}>
        <Box display='flex'>
          <Typography color='primary'>{'Paiement :'}</Typography>
          <Typography sx={{ color: 'text.main' }} mx={1} >{payment_type}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default BillingInformationComponent
