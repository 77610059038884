import React from 'react'
import { hooks } from '@front/volcanion'

import _ from 'lodash'

import { List, ListItemButton, ListItemText } from '@mui/material/'

import { CustomListItemIcon, ListItemButtonProps, ListItemTextProps } from '../'

const CommonList: React.FC<{ open: boolean }> = ({ open }) => {
  const [{ location }] = hooks.useRouter()
  const navigate = hooks.useNavigate()

  return (
    <List dense disablePadding >
      <ListItemButton onClick={() => navigate('account')} selected={_.includes(location?.pathname, 'account')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'account'} variant='profil' category={'icons'} style={{ height: 22, width: 22, fill: 'primary.main' }} />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={'Mon compte'} />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          localStorage.clear()
          window.location.reload()
        }}
        {...ListItemButtonProps}
      >
        <CustomListItemIcon name={'logout'} category={'icons'} style={{ height: 24, width: 24, fill: 'red' }} />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={'Déconnexion'} />
      </ListItemButton>
    </List >
  )
}

export default CommonList