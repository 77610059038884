import React from "react"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {

  return (
    <Component />
  )
}

export default withContainer
