import React, { useCallback } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const isReady = FormHooks.useFormStatus('isReady')

  const [load_count] = FormHooks.useFieldValues(['load_count']) as VTypes.V__ANY

  const [
    service,
    requestedAt,
    returnAt,
    source,
    destination,
    ridemode,
    duration,
    vehicle_opts,
    driver_opts
  ] = FormHooks.useFieldValues([
    'service',
    'requestedAt',
    'returnAt',
    'source',
    'destination',
    'ridemode',
    'duration',
    'vehicle_opts',
    'driver_opts'
  ]) as VTypes.V__ANY

  const vehicleOptionField = FormHooks.useFieldListener('vehicle_opts')
  const driverOptionField = FormHooks.useFieldListener('driver_opts')

  const [, authorized, { isLoading }] = hooks.useEstimation({
    service,
    requestedAt,
    returnAt,
    source_details: source,
    destination_details: destination,
    mode: ridemode,
    duration,
    vehicle_opts,
    driver_opts,
    load_count
  }, { isReady, refreshOnEnabled: true })

  const cp_ids = _.compact(_.map(authorized, 'commercial_package_id'))

  const afterChange = useCallback(() => {
    vehicleOptionField().clearValue()
    driverOptionField().clearValue()
  }, [vehicleOptionField, driverOptionField])

  const mergedProps = {
    isLoading,
    cp_ids,
    afterChange
  }

  return (
    <Component{...mergedProps} />
  )
}

export default withContainer
