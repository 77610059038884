import React, { useCallback } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const getCompanyuserFilter = (booking_level: string, self_booking: string, service: string, companyuser_id: string, companycontract_id: string, company: string) => {
  if (!booking_level && !!self_booking) return { companyuser_id }
  else if (booking_level === 'company' && !!self_booking) return { company }
  else if (booking_level === 'company' && !self_booking) return { company, companyuser_id: { '!': companyuser_id } }
  else if (booking_level === 'contract' && !!self_booking) return { services: { contract: companycontract_id } }
  else if (booking_level === 'contract' && !self_booking) return { services: { contract: companycontract_id }, companyuser_id: { '!': companyuser_id } }
  else if (booking_level === 'service' && !!self_booking) return { services: [service] }
  else if (booking_level === 'service' && !self_booking) return { services: [service], companyuser_id: { '!': companyuser_id } }
  else return null
}
const withContainer: React.FC = () => {
  const [{ company, own_companyuser_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [service] = FormHooks.useFieldValues(['service', 'companyuser_id']) as VTypes.V__ANY
  const [companyuser] = hooks.useModel('companyuser', [own_companyuser_id], { single: true, populate: ['user', 'contact'] })

  const { booking_level, self_booking, companycontrat, companyuser_id } = companyuser || {}
  const companycontract_id = companycontrat?.companycontract_id
  const watchers = [
    companyuser_id,
    booking_level,
    self_booking,
    company,
    companycontract_id,
    service
  ]

  const companyuser_filter = getCompanyuserFilter(booking_level, self_booking, service, companyuser_id, companycontract_id, company)

  const afterChange = useCallback(Callbacks.afterChangeHandler(), [])

  const mergedProps = {
    companyuser_filter,
    watchers,
    afterChange
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
