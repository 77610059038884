import React, { useState, useCallback } from 'react'
import { hooks, FormHooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [score, setScore] = useState()
  const { openNotification } = hooks.useNotification()
  const [, setState] = FormHooks.useFormState()
  const isSubmitting = FormHooks.useFormStatus('isSubmitting')
  const [, signin] = hooks.useAuthFunction('signin')
  const [email, password] = FormHooks.useFieldValues(['email', 'password'])
  const [visible, setVisible] = useState(false)
  const onVisibleChange = useCallback(() => setVisible(!visible), [visible, setVisible])

  const onRequestSubmit = useCallback(Callbacks.onRequestSubmitHandler(setState, signin, openNotification, email, password, score), [setState, signin, openNotification, email, password, score])
  const mergedProps = {
    setScore,
    visible,
    onVisibleChange,
    onRequestSubmit,
    isSubmitting,
    password
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
