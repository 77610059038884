
import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, Button, Grid, Typography, Icon } from '@mui/material'

import List from './table'

const ComnyuserComponent: React.FC = props => {
  const navigate = hooks.useNavigate()
  const users = hooks.useSearchListenerResult('companyuser', 'companyuser_list')
  return (
    <Box p={4}>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center'>
              <Icon>{'group'}</Icon>
              <Typography ml={2} variant='h5'>{I18n.t('user.count', { user_count: _.size(users) })}</Typography>
            </Box>
            <Button
              variant='outlined'
              startIcon={<Icon>{'add'}</Icon>}
              onClick={() => navigate('/admin/user/create')}
            >
              {I18n.t('companyuser.create')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List />
        </Grid>
      </Grid>
    </Box >
  )
}

export default ComnyuserComponent
