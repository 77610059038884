import React from 'react'
import info from '../info'
import config from '../constants.json'
import { SVG, Image, SHooks, StorageManager, GIF } from '@front/squirtle'
import { useTheme } from '@mui/material/styles'

import getTranslations from './translations'
import Pages from './pages'
import { ProviderRoot } from '@front/volcanion'


const SManager = new StorageManager()
const Main = () => (<Pages />)

const App: React.FC = () =>
  <ProviderRoot
    storage={{ manager: SManager }}
    relay={{ info, config, translations: getTranslations() }}
    global={{ persist: ['persist'] }}
    model={{ auto_load: [] }}
    main={{
      useRouter: SHooks.useRouter,
      useNotification: SHooks.useNotification,
      useBlobHandler: SHooks.useBlobHandler,
      useTheme,
      ImageRenderer: Image,
      SVGRenderer: SVG,
      GIFRenderer: GIF,
    }}
  >
    <Main />
  </ProviderRoot>

export default App
