import React, { useCallback } from "react"
import { I18n, hooks, withSlot } from "@front/volcanion"

import _ from "lodash"

import { Box, Grid, Icon, Button } from "@mui/material"
import { FormFactory } from "@front/squirtle"

import PassengerSelector from "./PassengerSelector"

import { GlobalInformationProps } from "./types"

const MenuSelectorFooter = withSlot(props => {
  const [, { openDialog }] = hooks.useDialogs()
  return (
    <Button onClick={() => openDialog('payment_add')} fullWidth>{I18n.t('card.add')}</Button>
  )
})

const GlobalInformationSection: React.FC<GlobalInformationProps> = ({ guest_booking, customerrefoption_id, manual_allowed, required, options, afterPaymentChange }) => (
  <Grid item container spacing={2}>
    <Grid item xs hidden={!guest_booking}>
      <Box display='flex' alignItems={'flex-end'} >
        <Box mr={1}><Icon color='primary'>{'person'}</Icon></Box>
        <PassengerSelector />
      </Box>
    </Grid>
    <Grid item xs={12} sm display='flex' alignItems={'flex-end'}>
      <Box mr={1}> <Icon color='primary' fontSize='small'>{'euro_symbol'}</Icon></Box>
      <FormFactory.MenuSelectorField
        name={'payment'}
        labelKeys={['label']}
        selectFirst
        options={options}
        afterChange={afterPaymentChange}
        _native={{ label: I18n.t('payment.mode'), variant: 'standard' }}
      >
        <MenuSelectorFooter slot='menuselector_footer' />
      </FormFactory.MenuSelectorField>
    </Grid>
    <Grid item xs display='flex' alignItems={'flex-end'}>
      <Box mr={1}> <Icon color='primary' fontSize='small'>{'tag'}</Icon></Box>
      <FormFactory.AutocompleteField
        name='customer_ref'
        config={{
          listener_type: 'search',
          model_name: 'customerref',
          forced_filter: {
            client_id: { '!': null },
            parent: customerrefoption_id
          },
          watchers: [customerrefoption_id],
          validate_filter: useCallback(() => !!customerrefoption_id, [customerrefoption_id])
        }}
        labelKeys={['client_id']}
        _native={{
          creatable: !!manual_allowed,
          selectCreatableLabel: true,
          disableUnderline: true,
          label: I18n.t('customer_ref.label', { count: 1 }),
          _render: {
            fullWidth: true,
            variant: 'standard'
          }
        }}
        required={required}
      />
    </Grid>
  </Grid>
)

export default GlobalInformationSection
