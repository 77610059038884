import React, { useCallback } from "react"
import { hooks } from '@front/volcanion'
import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {

  const mergedProps = {

  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
