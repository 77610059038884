import React from 'react'
import { FormHooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [{ isForgot }] = FormHooks.useFormState()

  const mergedProps = {
    isForgot
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
