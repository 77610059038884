import React, { useMemo, useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'
import { GeoUtils, BillingUtils, TimeUtils, OrderUtils } from '@front/volcanion/utils'
import type { ModelRecord } from '@front/volcanion/types'

import moment from 'moment'
import _ from 'lodash'

import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material'

const HeaderComponent: React.FC = props => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Numéro de course</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Prestation</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Status</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Date</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Passager </TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Classe</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Départ</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Destination</TableCell>
        <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>Prix total</TableCell>
      </TableRow>
    </TableHead>
  )
}

const RowComponent: React.FC<{ row: ModelRecord }> = ({ row }) => {
  const navigate = hooks.useNavigate()
  const onOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    window.open(`/order/${row?.order_id}`, '_blank')
    e.stopPropagation()
    e?.preventDefault()
  }, [row?.order_id])

  const transport_status = row?.assigned_transport?.status
  const transport_color = hooks.useTransportStatusColor(row?.assigned_transport)
  const order_color = hooks.useOrderStatusColor(row)

  return (
    <TableRow key={row?.name} onClick={() => navigate(`/order/${row?.order_id}`)} hover>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>
        <Link href="#" target='_blank' fontWeight={'bold'} onClick={onOpen}>{row?.order_client_id}</Link>
      </TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.mode?.name_translated}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>
        <Chip
          variant={'filled'}
          size='small'
          sx={{ color: 'white', backgroundColor: transport_color || order_color }} label={!!row?.assigned_transport ? I18n.t(`transport.status.${transport_status}`) : I18n.t(`order.status.${row?.status}`)} />
      </TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{TimeUtils.getDetailsMomentFront(row?.requestedAt, row?.source, { format: 'DD/MM/YYYY HH:mm' })}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.loads?.[0]?.src_contact}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.commercial_package?.name_translated}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{GeoUtils.getFormattedAddress(row?.source)}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{GeoUtils.getFormattedAddress(row?.destination)}</TableCell>
      <TableCell size='small' align="center" padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{BillingUtils.getPaymentDisplay(row?.quotation, false, 1)}</TableCell>
    </TableRow>
  )
}

const BasicTable: React.FC<{ type: string }> = ({ type }) => {
  const type_filter = useMemo(() => {
    if (type === 'history') {
      return {
        status: ['canceled', 'complete'],
        requestedAt: { '<=': moment.utc().format() }
      }
    } else if (type === 'planned') {
      return { schedule_type: 'planned', status: ['created', 'standby'] }
    } else if (type === 'current') {
      return { status: { '!': ['canceled', 'complete'] } }
    }
  }, [type])

  const [orders] = hooks.useModelSearch('order', 'get', {
    initial_filter: {},
    forced_filter: type_filter,
    watchers: [type],
    populate: ['mode', 'commercial_package', 'source', 'destination', 'quotation', 'loads', 'assigned_transport'],
    listener_id: 'orders_list'
  })

  return (
    <TableContainer>
      <Table>
        <HeaderComponent />
        <TableBody>
          {_.map(orders, (row) => (<RowComponent row={row} />))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
