import _ from 'lodash'


import { IFormBaseListener, ISubmitMap, ModelRecord } from '@front/volcanion/types'
import { FormatUtils, GeoUtils, UserUtils } from '@front/volcanion/utils'

class Callbacks {
  static getEmptyStateHandler() {
    return function getEmptyState() {
      return {
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {

      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record: ModelRecord) {
      return {
        contact: {
          gsm: record?.contact.gsm,
          email: record?.contact.email,
          last_name: record?.contact.last_name,
          first_name: record?.contact.first_name
        },
        status: FormatUtils.getStatus(_.pick(record, ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])),
        suspendedSince: record?.suspendedSince,
        suspendedUntil: record?.suspendedUntil,
        administration_level: record?.administration_level,
        booking_level: record?.booking_level,
        self_booking: record?.self_booking,
        guest_booking: record?.guest_booking
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record: ModelRecord) {
      return {
        name: UserUtils.getDisplayName(record?.user),
        user_id: record?.user?.user_id
      }
    }
  }

  static formToSubmitHandler(company?: string, application_id?: string) {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      const user_id = form.getStateValue('user_id')
      if (!user_id) {
        submit_map.setDataKey('user', {
          auth: {
            gsm: form.getFieldValue('contact.gsm'),
            application: application_id,
            email: form.getFieldValue('contact.email'),
          },
          customerinfo: {},
          info: {
            first_name: form.getFieldValue('first_name'),
            last_name: form.getFieldValue('last_name'),
          }
        })
      }

      submit_map
        .setDataKey('company', company)
        .setDataKey('contact', {
          gsm: form.getFieldValue('contact.gsm'),
          email: form.getFieldValue('contact.email'),
          first_name: form.getFieldValue('first_name'),
          last_name: form.getFieldValue('last_name')
        })
        .setDataKey('suspendedSince', form.getFieldValue('suspendedSince'))
        .setDataKey('suspendedUntil', form.getFieldValue('suspendedUntil'))
        .setDataKey('administration_level', form.getFieldValue('administration_level'))
        .setDataKey('booking_level', form.getFieldValue('booking_level'))
        .setDataKey('can_call_booker', form.getFieldValue('can_call_booker'))
        .setDataKey('self_booking', form.getFieldValue('self_booking'))
        .setDataKey('guest_booking', form.getFieldValue('guest_booking'))
        .setDataKey('status', FormatUtils.parseStatus(form.getFieldValue('status')))

      return submit_map
    }
  }

  static onSubmitSuccessHandler(navigate: (path: string) => void) {
    return function onSubmitSuccess(form: IFormBaseListener, records: ModelRecord[]) {
      const record = _.head(records) as ModelRecord
      navigate(`/admin/user/${record?.companyuser_id}`)
    }
  }
}

export default Callbacks

