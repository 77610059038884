
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import _ from 'lodash'
import { FormFactory } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'
import type { ParamType } from './types'

const CompanyService: React.FC = props => {
  const { companyuser_id } = hooks.useParams() as ParamType
  const navigate = hooks.useNavigate()
  const [gl_company_id] = hooks.useGlobalState('persist.company_id')
  const [applications] = hooks.useModelSearch('application', 'get', { initial_filter: {} })
  const application_id = _.head(_.sortBy(applications, 'display_index'))?.application_id


  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(), [])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const recordToState = useCallback(Callbacks.recordToStateHandler(), [])

  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(gl_company_id as string, application_id), [gl_company_id, application_id])
  const onSubmitSuccess = useCallback(Callbacks.onSubmitSuccessHandler(navigate), [navigate])

  return <FormFactory.Form
    name={`user_detail_${companyuser_id || 'create'}`}
    listener_type='form'
    getEmptyState={getEmptyState}
    getEmptyForm={getEmptyForm}
    recordToForm={recordToForm}
    recordToState={recordToState}
    formToSubmit={formToSubmit}
    onSubmitSuccess={onSubmitSuccess}
    config={{
      model_name: 'companyuser',
      populate: ['contact', 'user.auth', 'user.customerinfo', 'user.info'],
      ids: [companyuser_id]
    }}
  >
    <FormContent />
  </FormFactory.Form>
}

export default CompanyService
