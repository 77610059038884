import { ModelRecord, type IFormFieldListener, type IFormFieldValue, type MaybeMultiple } from "@front/volcanion/types"
import { UserUtils } from "@front/volcanion/utils"

class Callbacks {
  static afterChangeHandler(user: ModelRecord) {
    return function afterChange(value: MaybeMultiple<IFormFieldValue>, prev_value: MaybeMultiple<IFormFieldValue>, listener: IFormFieldListener<IFormFieldValue>) {
      const companyuserservices = listener.getValueOption(value) as ModelRecord
      const companyuser = companyuserservices?.companyuser
      const companyservice = companyuserservices?.companyservice
      const parentFormListener = listener.getParentForm()

      if (!!companyuser) {
        if (!!companyuser?.self_booking) {
          const companyuser_name = UserUtils.getFormatedName({ ...companyuser.contact, designation: companyuser.user?.customerinfo?.name })
          const companyuser_number = user?.auth?.gsm
          parentFormListener.getField('passenger_name').setValue(companyuser_name)
          parentFormListener.getField('passenger_number').setValue(companyuser_number)
          parentFormListener.getField('companyuser_id').setValue(companyuser?.companyuser_id)
        }
        parentFormListener.setStateKey('own_companyuser_id', companyuser?.companyuser_id)
        parentFormListener.setStateKey('guest_booking', companyuser?.guest_booking)
        parentFormListener.setStateKey('commercial_formula', companyservice?.contract?.formula)

      } else {
        const customer_name = UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name })
        const customer_number = user?.auth?.gsm
        parentFormListener.getField('passenger_name').setValue(customer_name)
        parentFormListener.getField('passenger_number').setValue(customer_number)
        parentFormListener.setStateKey('commercial_formula', user?.commercial_formula)
        parentFormListener.setStateKey('guest_booking', false)
        parentFormListener.getField('companyuser_id').clearValue()
        parentFormListener.setStateKey('own_companyuser_id', undefined)
      }
    }
  }

  static mutatorHandler() {
    return function mutator(cus: ModelRecord) {
      return {
        label: [cus?.companyservice?.contract?.company?.name, cus?.companyservice?.contract?.name, cus?.companyservice?.name].join(' '),
        value: cus?.companyservice?.companyservice_id,
        icon: 'work_icon',
        ...cus
      }
    }
  }
}

export default Callbacks

