
import React from 'react'
import { FormHooks, hooks, VTypes, I18n, BillingUtils } from "@front/volcanion"

import _ from 'lodash'

import { Box, Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'
import type { IFormFieldOption } from '@front/volcanion/types'

const PackageOptions: React.FC = props => {
  const [{ commercial_package_id, service_id, user_id }] = FormHooks.useFormState() as VTypes.V__ANY

  const vehicle_ids = hooks.useSearchListenerResult('vehicleoption', 'search_vehicle_opts')
  const driver_ids = hooks.useSearchListenerResult('useroption', 'search_driver_opts')
  const currency = BillingUtils.getCurrencySymbol('EUR')
  return (
    <Box>
      <Grid container justifyContent='center' rowSpacing={3}>
        <Grid item xs={8} hidden={_.isEmpty(vehicle_ids)}>
          <FormFactory.OptionSelectorField
            name='vehicle_opts'
            config={{
              model_name: 'vehicleoption',
              initial_filter: {},
              forced_filter: {
                commercial_package_id,
                user_id,
                service_id
              },
              mode: 'default',
              listener_type: 'search',
              listener_id: 'search_vehicle_opts'
            }}
            getOptionProps={(option: IFormFieldOption) => ({
              label: _.compact([option?.name_translated, option?.price ? `+${option?.price}${currency}` : undefined]).join(' ')
            })}
            _native={{
              valueType: 'chip',
              label: [I18n.t('option.vehicle.label', { count: -1 }), ':'].join(' ')
            }}
          />
        </Grid>
        <Grid item xs={8} hidden={_.isEmpty(driver_ids)}>
          <FormFactory.OptionSelectorField
            name='driver_opts'
            config={{
              model_name: 'useroption',
              initial_filter: {},
              forced_filter: {
                commercial_package_id,
                user_id,
                service_id
              },
              mode: 'default',
              listener_type: 'search',
              listener_id: 'search_driver_opts'
            }}
            getOptionProps={(option: IFormFieldOption) => ({
              label: _.compact([option?.name_translated, option?.price ? `+${option?.price}${currency}` : undefined]).join(' ')
            })}
            _native={{
              valueType: 'chip',
              label: [I18n.t('option.driver.label', { count: -1 }), ':'].join(' ')
            }}
          />
        </Grid>
      </Grid>
    </Box >
  )
}

export default PackageOptions
