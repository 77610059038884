
import React from 'react'
import _ from 'lodash'

import { Box, Divider, Grid } from '@mui/material'

import {
  Addresses,
  BillingInformation,
  Header,
  History,
  Map,
  GlobalInformation,
  PackageInformation,
  TransportInformation
} from './Section'

import Popups from './Popups'

const FormComponent: React.FC = props =>
  <Box p={2}>
    <Popups />
    <Header />
    <Box display='flex'>
      <Box sx={{ flex: 1, p: 2, m: 1, backgroundColor: '#F9F6F4', boxShadow: '0px 10px 20px 4px rgba(35, 35, 35, 0.10)', borderRadius: 1 }}>
        <Grid container rowSpacing={3} spacing={3}>
          <Grid item xs={12}>
            <GlobalInformation />
          </Grid>
          <Grid item xs={6}>
            <Addresses />
          </Grid>
          <Grid item xs={6}>
            <TransportInformation />
          </Grid>
          <Grid item xs={6}>
            <BillingInformation />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PackageInformation />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flex: 1, m: 1, backgroundColor: '#F9F6F4', boxShadow: '0px 10px 20px 4px rgba(35, 35, 35, 0.10)', borderRadius: 1 }}>
        <Map />
        <History />
      </Box>
    </Box>
  </Box >

export default FormComponent
