import React, { useMemo, useCallback } from 'react'

import { I18n, hooks } from '@front/volcanion'
import type { ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'
import moment from 'moment'

import { Button, Box, Grid2 as Grid, Icon, Typography, IconButton } from '@mui/material'

const PaymentCard: React.FC<{ card: ModelRecord }> = ({ card: {
  cardholder_name,
  card_tail,
  expiry_year,
  expiry_month,
  name,
  payment_method_id,
  variant
} }) => {
  const [deleteCard] = hooks.useModelFunction('paymentmethod', 'delete')
  const [, { openDialog }] = hooks.useDialogs()

  const onDeleteCard = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    deleteCard({ payment_method_id })
    e.stopPropagation()
  }, [payment_method_id, deleteCard])

  const isValid = useMemo(() => {
    const currentMonth = moment().month()
    const currentYear = moment().year()
    if (currentYear < expiry_year) return true
    else if (currentYear === expiry_year) return currentMonth <= expiry_month
    else return false
  }, [expiry_year, expiry_month])

  return (
    <Button
      fullWidth
      size='large'
      variant='text'
      sx={{ textTransform: 'unset', boxShadow: 3, p: 1 }}
      onClick={() => openDialog('payment_detail', { formProps: { initialState: { record_id: payment_method_id } } })}
    >
      <Box width={1} height={1}>
        <Grid container >
          <Grid size={1} >
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
              <Icon>{'credit_card'}</Icon>
            </Box>
          </Grid>

          <Grid size={10}>
            <Box display='flex' flexDirection='column' justifyContent='center' width={1} height={1}>
              <Box>
                <Box display={'flex'} justifyContent={'center'} width={'95%'}>
                  <Typography fontWeight='bold' textAlign='left' color='primary'> {_.upperFirst(variant)}:</Typography>
                  <Typography textAlign='left' color='primary' ml={1} noWrap> {name || cardholder_name}</Typography>
                </Box>
                <Typography noWrap>{I18n.t('card.label', { tail: card_tail })}</Typography>
                <Typography color={isValid ? 'success' : 'error'} fontWeight='bold'> {isValid ? 'Valide' : 'Expirée'}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid size={1}>
            <IconButton sx={{ width: 1, height: 1 / 2 }} disableRipple>
              <Icon color='secondary'>{'mode_edit_outlined'}</Icon>
            </IconButton>
            <IconButton sx={{ width: 1, borderRadius: 0, height: 1 / 2 }} onClick={onDeleteCard}>
              <Icon color='secondary'>{'delete_outlined'}</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Box >
    </Button>
  )
}

export default PaymentCard
