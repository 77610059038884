import React from "react"
import { withSlot, CDNSVG, hooks, FormHooks } from '@front/volcanion'
import { ModelRecord } from "@front/volcanion/types"

import _ from "lodash"

import { Box, Typography, Button } from "@mui/material"
import { FormFactory } from "@front/squirtle"

import { RidemodeProps } from './types'
import { GridElemSlot } from "@front/squirtle/FormFactory/GridSelector/types"

const CardCustomRidemode = withSlot<{}, GridElemSlot>(({ option, onChange }) => {
  const selectorProps = FormHooks.useLocalFieldSelectorFunctions()
  const isSelected = selectorProps?.isOptionSelected(option)
  const modelOption = option as ModelRecord
  return (
    <Box sx={{ borderBottom: !!isSelected ? 2 : 0, borderColor: 'primary.main', py: 1 }}>
      <Button sx={{ textTransform: 'none' }} onClick={() => onChange(modelOption?.ridemode_id)} fullWidth>
        <CDNSVG name={modelOption?.client_type_icon} category={'icons'} fill={!!isSelected ? 'primary' : 'gray.main'} style={{ height: 30, width: 30 }} />
        <Typography noWrap fontSize={'1.2vw'} color={!!isSelected ? 'primary' : 'gray'} ml={3} sx={{ display: { xs: 'none', md: 'flex' } }}>{modelOption?.name_translated}</Typography>
      </Button>
    </Box>
  )
})

const Ridemode: React.FC<RidemodeProps> = ({ afterChange }) => (
  <Box>
    <FormFactory.GridSelectorField
      name='ridemode'
      selectFirst
      required
      config={{
        model_name: 'ridemode',
        populate: ['config'],
        initial_filter: {},
        listener_type: 'search',
        sortBy: 'display_index',
        sortDirection: 'asc'
      }}
      _native={{
        _gridItem: {
          sm: 2
        },
        _grid: { justifyContent: 'space-around' }
      }}
      afterChange={afterChange}
    >
      <CardCustomRidemode slot='card' />
    </FormFactory.GridSelectorField>
  </Box >
)

export default Ridemode
