
import React from 'react'
import { I18n, hooks, CDNSVG } from '@front/volcanion'

import _ from 'lodash'

import { Box, IconButton, Icon, Typography, Chip, Button, Grid } from '@mui/material'

import { HeaderProps } from './types'

const HeaderComponent: React.FC<HeaderProps> = ({
  client_id,
  status,
  ridemode,
  ridemode_icon,
  canDownloadBill,
  canRequestInvoice,
  onRequestInvoice,
  downloadBill
}) => {
  const navigate = hooks.useNavigate()

  return (
    <Box>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} lg={3} display='flex'>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}><Icon color='primary'>{'arrow_back'}</Icon></IconButton>
          <Typography ml={2} variant='h5'>{I18n.t('order.label', { count: 1 })}</Typography>
          <Typography sx={{ mx: 1, textDecoration: 'underline', fontWeight: 'bold' }} variant='h5'>{client_id}</Typography>
        </Grid>
        <Grid item xs={12} lg={2} display='flex' alignItems='center'>
          <Chip label={status} color='secondary' sx={{ color: 'white', mr: 1 }} />
          <Chip label={ridemode} color='primary' variant='outlined' sx={{ mr: 1 }} icon={<Box pb={'2px'}><CDNSVG name={ridemode_icon} category={'icons'} fill='primary' /></Box>} />
        </Grid>
        <Grid item xs={12} lg display='flex' justifyContent='flex-end'>
          <Box hidden={!canRequestInvoice}>
            <Button sx={{ textTransform: 'none', mr: 1 }} variant='outlined' size={'small'} onClick={onRequestInvoice}>
              <Box display='flex' >
                <CDNSVG name={'return_receipt'} category={'icons'} />
                <Typography ml={1}>{'resend.receipt'}</Typography>
              </Box>
            </Button>
          </Box>
          <Box hidden={!canDownloadBill}>
            <Button sx={{ textTransform: 'none', ml: 1 }} variant='outlined' size={'small'} onClick={downloadBill}>
              <Box display='flex' >
                <CDNSVG name={'download_invoice'} category={'icons'} />
                <Typography ml={1}>{I18n.t('download.bill')}</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default HeaderComponent
