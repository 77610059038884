import React, { useCallback } from "react"
import { FormHooks, hooks, CDNImage, withSlot, VTypes, I18n } from "@front/volcanion"
import { BillingUtils } from "@front/volcanion/utils"

import _ from "lodash"

import { Box, Button, Typography, Icon, Tooltip, Link } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info'

import { GridElemSlot } from "@front/squirtle/FormFactory/GridSelector/types"
import { ModelRecord } from "@front/volcanion/types"
import { ChipOption } from "@front/squirtle"

import type { ChipOptionProps } from './types'

const PackageChipOption: React.FC<ChipOptionProps> = ({ id, model_name, field_name, options }) => {
  const [option] = hooks.useModel(model_name, [id], { single: true })
  const field = FormHooks.useFieldListener(field_name)
  const currency = BillingUtils.getCurrencySymbol('EUR')

  return (
    <ChipOption
      label={_.compact([option?.name_translated, option?.price ? `+${option?.price}${currency}` : undefined]).join(' ')}
      sx={{ mx: 1 }}
      onDelete={() => field().setValue(_.difference([id], options))}
    />
  )
}

const PackageCard = withSlot<{}, GridElemSlot>(({ option, onChange }) => {
  const [, { openDialog }] = hooks.useDialog('package_options')
  const selectorProps = FormHooks.useLocalFieldSelectorFunctions()
  const isSelected = selectorProps?.isOptionSelected(option)
  const modelOption = option as ModelRecord

  const [{ user_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [estimation_type, vehicle_opts, driver_opts, service_id] = FormHooks.useFieldValues(['estimation_type', 'vehicle_opts', 'driver_opts', 'service']) as VTypes.V__ANY

  const [estimation] = hooks.useSelectedEstimation(modelOption?.commercial_package_id, estimation_type)
  const rate = BillingUtils.getEstimationDisplay(estimation, false, 1)

  const openOption = useCallback(() => {
    openDialog({
      formProps: {
        initialValues: { vehicle_opts, driver_opts },
        initialState: { commercial_package_id: modelOption?.commercial_package_id, service_id, user_id }
      }
    })
  }, [openDialog, vehicle_opts, driver_opts, modelOption, service_id, user_id])

  return (
    <Button sx={{
      textTransform: 'unset',
      borderRadius: 2,
      backgroundColor: !!isSelected ? 'secondary.light' : 'white',
      p: 1
    }} onClick={e => {
      e.stopPropagation()
      onChange(modelOption?.commercial_package_id)
    }}>
      <Box>
        <Box display='flex' justifyContent={'flex-end'} width={'100%'}>
          <Box p={1}>
            <Tooltip title={modelOption.summary_translated} placement={'top'} slotProps={{ tooltip: { sx: { fontSize: '14px' } } }}>
              <InfoIcon color='primary' fontSize='small' />
            </Tooltip>
          </Box>
        </Box>
        <CDNImage name={modelOption?.client_type_icon} category={'packages'} style={{ maxHeight: 100 }} />
        <Box display='flex' alignItems='flex-end' justifyContent={'space-between'} px={3}>
          <Typography fontSize={20} color={'primary'} sx={{ mr: 3 }}>{modelOption?.name_translated}</Typography>
          <Box display='flex'>
            <Icon color='primary'>{'group'}</Icon>
            <Typography fontSize={20} ml={1} color={'primary'}>{modelOption?.max_load}</Typography>
          </Box>
        </Box>
        <Typography mr={2} fontSize={20} color={'primary'}>{rate}</Typography>
        <Box hidden={!isSelected} p={1}>
          {_.map(driver_opts, elem => <PackageChipOption id={elem} model_name={'useroption'} field_name={'driver_opts'} options={driver_opts} />)}
          {_.map(vehicle_opts, elem => <PackageChipOption id={elem} model_name={'vehicleoption'} field_name={'vehicle_opts'} options={vehicle_opts} />)}
          <Link href="#" variant="body2" onClick={openOption}>
            {I18n.t('action.add_option')}
          </Link>
        </Box>
      </Box>
    </Button>
  )
})

export default PackageCard
