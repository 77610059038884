import React from "react"
import { I18n } from '@front/volcanion'

import _ from "lodash"

import { Box, Grid, Typography } from "@mui/material"
import { FormFactory, Loader } from "@front/squirtle"

import { PackageProps } from './types'
import CardCustomCommercialPackage from './Card'

const PackageSection: React.FC<PackageProps> = ({ afterChange, cp_ids, isLoading }) => (
  <Grid container item xs={12} rowSpacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5' color='primary'>{I18n.t('commercial_package.label', { count: 1 })}</Typography>
    </Grid>
    <Grid item xs={12} display='flex' justifyContent='center'>
      <Box sx={{ width: '100%' }}>
        <Loader nativeLoader isLoading={isLoading} type='linear' >
          <FormFactory.GridSelectorField
            name='commercial_package'
            afterChange={afterChange}
            selectFirst
            _native={{
              isLoading,
              _grid: {
                spacing: 1,
                justifyContent: 'center',
                display: 'flex',
              },
              _gridItem: {
                justifyContent: 'center',
                display: 'flex',
                xs: 12, sm: 6, md: 4, lg: 4, xl: 3
              }
            }}
            config={{
              model_name: 'commercialpackage',
              ids: cp_ids,
              sortBy: 'display_index',
              sortDirection: 'asc'
            }}
            required
          >
            <CardCustomCommercialPackage slot='card' />
          </FormFactory.GridSelectorField>
        </Loader>
      </Box>
    </Grid>
  </Grid >
)

export default PackageSection
