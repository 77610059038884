
import React from 'react'
import { I18n, withSlot, hooks } from '@front/volcanion'

import _ from 'lodash'
import { Box } from '@mui/material'

import { Elements, DialogForm, DialogInformation, DialogConfirmation } from '@front/squirtle'
import { DecoratorContentSlot, DecoratorTitleSlot, } from '@front/squirtle/Components_ts/Dialog/types'

import { PopupsProps } from './types'

import BookingSuccess, { BookingSuccessTitle } from './BookingSuccess'
import PackageOptions from './PackageOptions'
import ReservationInfo from './ReservationInfo'

const PaymentAddSlot = withSlot<{}, DecoratorContentSlot>(props => <Elements.AddCard  {...props} />)
const BookingSuccessTitleSlot = withSlot<{}, DecoratorTitleSlot>(props => <BookingSuccessTitle {...props} />)
const BookingSuccessContentSlot = withSlot<{}, DecoratorContentSlot>(props => <BookingSuccess {...props} />)
const PackageOptionsContentSlot = withSlot<{}, DecoratorContentSlot>(props => <PackageOptions {...props} />)
const ReservationInfoContentSlot = withSlot<{}, DecoratorContentSlot>(props => <ReservationInfo {...props} />)

const PopupComponent: React.FC<PopupsProps> = ({ onSubmitPackageOptions, onSubmitReservationInfo, onSubmitCard, getEmptyPaymentAddForm }) => {
  const navigate = hooks.useNavigate()
  return (
    <Box>
      <DialogForm
        name={'package_options'}
        title={I18n.t('option.selection')}
        formProps={{ onSubmit: onSubmitPackageOptions }}
      >
        <PackageOptionsContentSlot slot='dialog_content' />
      </DialogForm>

      <DialogForm
        name={'reservationinfo'}
        title={I18n.t('info.additional.label', { count: -1 })}
        formProps={{ onSubmit: onSubmitReservationInfo }}
      >
        <ReservationInfoContentSlot slot='dialog_content' />
      </DialogForm>

      <DialogInformation name={'warning'} />

      <DialogConfirmation
        name={'booking_success'}
        cancelLabel={I18n.t('action.book_new')}
        confirmLabel={I18n.t('action.order_detail')}
        onCancel={() => window.location.reload()}
        onConfirm={(state: object, extra: { order_id: string }) => {
          navigate(`order/${extra?.order_id}`)
        }}
      >
        <BookingSuccessTitleSlot slot='dialog_title' />
        <BookingSuccessContentSlot slot='dialog_content' />
      </DialogConfirmation>

      <DialogForm name='payment_add' title={I18n.t('card.add')} formProps={{ onSubmit: onSubmitCard, getEmptyForm: getEmptyPaymentAddForm }}>
        <PaymentAddSlot slot='dialog_content' />
      </DialogForm>
    </Box >
  )
}

export default PopupComponent
