
import React from 'react'
import { I18n, CDNImage } from '@front/volcanion'

import _ from 'lodash'

import { Box, Typography } from '@mui/material'
import { AddressesProps } from './types'

const AddressesComponent: React.FC<AddressesProps> = ({
  source,
  source_date,
  destination,
  destination_date
}) => (
  <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{ height: '100%', p: 1 }}>

    <Box display='flex'>
      <CDNImage name={'source'} category='map' style={{ width: '24px', height: "24px" }} />
      <Typography ml={1} noWrap>{source}</Typography>
    </Box>

    <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{ borderLeft: 1, flex: 1, pl: 1, ml: 1, my: 1 }}>
      <Box sx={{ flex: 1 }}>
        <Box hidden={!source_date}>
          <Typography fontWeight={'bold'}>{[I18n.t(`hour.source`), ':'].join(' ')}</Typography>
          <Typography ml={1}>{source_date}</Typography>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box hidden={!destination_date}>
          <Typography fontWeight={'bold'}>{[I18n.t('hour.destination'), ':'].join(' ')}</Typography>
          <Typography ml={1}>{destination_date}</Typography>
        </Box>
      </Box>
    </Box >

    <Box display='flex'>
      <CDNImage name={'destination'} category='map' style={{ width: '24px', height: "24px" }} />
      <Typography ml={1} noWrap>{destination}</Typography>
    </Box>
  </Box>
)

export default AddressesComponent
