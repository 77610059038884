import _ from 'lodash'


import { IFormBaseListener, ISubmitMap, ModelRecord } from '@front/volcanion/types'
import { FormatUtils, GeoUtils } from '@front/volcanion/utils'

class Callbacks {
  static getEmptyStateHandler(companycontract_id?: string) {
    return function getEmptyState() {
      return {
        companycontract_id
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {

      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record: ModelRecord) {
      const status = FormatUtils.getStatus(record)
      return {
        name: record?.name,
        client_id: record?.client_id,
        contact: {
          email: record?.contact?.email
        },
        can_call_driver: record?.can_call_driver,
        can_call_booker: record?.can_call_booker,
        deferred_billing: record?.deferred_billing,
        customerref_option: {
          refs: record?.customerref_option?.refs,
          fill_ref_rule: record?.customerref_option?.fill_ref_rule,
          manual_allowed: record?.customerref_option.imposed_ref
        },
        password: record?.password,
        new_password: record?.new_password,
        new_password_availableSince: record?.new_password_availableSince,
        status
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record: ModelRecord) {
      return {
        deferred_billing: record?.deferred_billing,
        password: record?.password,
        new_password: record?.new_password,
        new_password_availableSince: record?.new_password_availableSince
      }
    }
  }

  static formToSubmitHandler() {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      const status = form.getFieldValue('status')

      submit_map
        .setDataKey('contract', form.getStateValue('companycontract_id'))
        .setDataKey('name', form.getFieldValue('name'))
        .setDataKey('client_id', form.getFieldValue('client_id'))
        .setDataKey('contact.email', form.getFieldValue('contact.email'))
        .setDataKey('deferred_billing', form.getFieldValue('deferred_billing'))
        .setDataKey('can_call_booker', form.getFieldValue('can_call_booker'))
        .setDataKey('can_call_driver', form.getFieldValue('can_call_driver'))
        .setDataKey('password', form.getFieldValue('password'))
        .setDataKey('new_password', form.getFieldValue('new_password'))
        .setDataKey('active', status === 'active')
        .setDataKey('deleted', status === 'archive')
        .setDataKey('new_password_availableSince', form.getFieldValue('new_password_availableSince'))
        .setDataKey('customerref_option', {
          refs: form.getFieldValue('customerref_option.refs'),
          fill_ref_rule: form.getFieldValue('customerref_option.fill_ref_rule'),
          manual_allowed: form.getFieldValue('customerref_option.imposed_ref')
        })
      return submit_map
    }
  }

  static onSubmitSuccessHandler(navigate: (path: string) => void) {
    return function onSubmitSuccess(form: IFormBaseListener, records: ModelRecord[]) {
      const record = _.head(records) as ModelRecord
      navigate(`admin/service/${record?.companyservice_id}`)
    }
  }
}

export default Callbacks

