import React, { useCallback } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [commercial_package_id, has_luggage, comment] = FormHooks.useFieldValues(['commercial_package', 'has_luggage', 'comment']) as VTypes.V__ANY
  const [{ guest_booking }] = FormHooks.useFormState() as VTypes.V__ANY
  const [{ hasError }] = FormHooks.useFieldErrors('comment')
  const [commercial_package] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })
  const max_load = commercial_package?.max_load
  return (
    <Component
      hasError={hasError}
      comment={comment}
      has_luggage={has_luggage}
      max_load={max_load}
      guest_booking={guest_booking}
    />
  )
}

export default withContainer
