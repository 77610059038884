
import { I18n } from '@front/volcanion'

import moment from 'moment'
import _ from 'lodash'


import { IFormBaseListener, ISubmitMap, ModelRecord } from '@front/volcanion/types'
import { UserUtils } from '@front/volcanion/utils'

class Callbacks {
  static getEmptyStateHandler() {
    return function getEmptyState() {
      return {

      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record: ModelRecord) {
      return {
        ...record?.preference,
        driver_comment: record?.customerinfo?.comment_to_driver
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record: ModelRecord) {
      return {
        preferred_language: record?.info?.preferred_language
      }
    }
  }

  static formToSubmitHandler() {
    return function formToSubmit(form: IFormBaseListener, submit_map: ISubmitMap, extra?: object) {
      return submit_map
    }
  }

}

export default Callbacks

