import React from 'react'
import { CDNSVG } from '@front/volcanion'

import { styled, } from '@mui/material/styles'

const DrawerHeaderStyled = styled('div')(({ theme }) => (theme.mixins.toolbar))

const DrawerHeaderComponent: React.FC<{ open: boolean, setOpen: (value: boolean) => void }> = ({ open, setOpen }) => {

  return (
    <DrawerHeaderStyled sx={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: 100 }}>
      {!!open
        ? <CDNSVG name='logo' category={"icons"} variant={'full'} style={{ width: '100%', height: 'auto' }} />
        : <CDNSVG name='logo' category={"icons"} style={{ width: '90%', height: 'auto' }} />
      }
    </DrawerHeaderStyled>
  )
}

export default DrawerHeaderComponent
