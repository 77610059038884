
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'

import _ from 'lodash'
import { FormFactory, Loader } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'
import type { ParamType } from './types'

const Booking: React.FC = () => {
  const [user, user_status] = hooks.useActiveUser({ populate: ["user_companies", "auth", "customerinfo", "info"], })

  const { companycontract_id } = hooks.useParams() as ParamType
  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(user?.user_id), [user?.user_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const recordToState = useCallback(Callbacks.recordToStateHandler(), [])

  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(), [])

  return <Loader isLoading={!user || !HookUtils.getReadyState([user_status])}>
    <FormFactory.Form
      name={`contract_detail_${companycontract_id}`}
      listener_type='form'
      getEmptyState={getEmptyState}
      getEmptyForm={getEmptyForm}
      recordToForm={recordToForm}
      recordToState={recordToState}
      formToSubmit={formToSubmit}
      config={{
        model_name: 'companycontract',
        populate: ['contact', 'source_address', 'comment'],
        ids: [companycontract_id]
      }}
    >
      <FormContent />
    </FormFactory.Form>
  </Loader>


}

export default Booking
