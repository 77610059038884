import React, { useEffect, useMemo, useState } from "react"
import { FormHooks, VTypes, hooks, I18n } from "@front/volcanion"

import _ from "lodash"
import moment from 'moment'

import Component from "./component"
import { UserUtils, GeoUtils } from "@front/volcanion/utils"


const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [order] = hooks.useModel('order', [record_id], {
    single: true, populate: ['events', 'assigned_transport.driver.info', 'assigned_transport.info', 'source']
  })

  const driver_name = UserUtils.getDisplayName(order?.assigned_transport?.driver)
  const transport_status = order?.assigned_transport?.status
  const order_status = order?.status
  const source = GeoUtils.mergeAddress(order?.source)

  const [elapsedTime, setElapsedTime] = useState(moment.utc().unix())

  const waitingSince = moment.utc(order?.requestedAt).isAfter(moment.utc(order?.assigned_transport?.info?.arrivedAt))
    ? moment.utc(order?.requestedAt).format()
    : moment.utc(order?.assigned_transport?.info?.arrivedAt).format()

  const waitFor = hooks.useOrderWaitingTime(record_id)



  const currentTime = moment.utc().format('DD/MM/YYYY HH:mm:ss')

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment.utc();
      const elapsed = now.diff(moment.utc(waitingSince), 'seconds');  // calcule le nombre de secondes écoulées depuis `waitingSince`
      // setElapsedTime(elapsed);
    }, 1000); // Met à jour toutes les secondes

    return () => clearInterval(interval);
  }, [waitingSince]);

  const timeLeft = useMemo(() => {
    const totalTimeInSeconds = waitFor * 60;  // Convertir le waitFor en secondes
    return totalTimeInSeconds - (elapsedTime / 60);  // Soustraire le temps écoulé du temps total
  }, [waitFor, elapsedTime]);

  const time_percentage = parseInt((((timeLeft / 60) / waitFor) * 100).toFixed(2))

  const mergedProps = {
    waitFor,
    waitingSince,
    time_percentage,
    driver_name,
    transport_status,
    order_status,
    source
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
