
import React from 'react'
import { I18n, withSlot } from '@front/volcanion'

import _ from 'lodash'

import { Box, Icon, Typography, Grid2 as Grid, InputAdornment } from '@mui/material'
import { FormFactory } from '@front/squirtle'

import { PreferencesProps } from './types'
import { IFormFieldOption, ModelRecord } from '@front/volcanion/types'
import { DecoratorLabelOptionSlot } from '@front/squirtle/FormFactory/OptionSelector/types'
import { ModifyButton } from '../../../../elements/Button'

const ArmRestLabelSlot = withSlot<{}, DecoratorLabelOptionSlot>(({ option }) => {
  const modelRecord = option as ModelRecord
  return (
    <Typography fontSize={12}>{modelRecord?.label}</Typography>
  )
})

const PreferencesComponent: React.FC<PreferencesProps> = ({ music_options, armrest, driver_comment, features }) => (
  <Box sx={{ boxShadow: 3, p: 2 }}>
    <Grid container rowSpacing={3} spacing={2}>
      <Grid size={{ xs: 12, md: 8, lg: 8 }} >
        <Typography variant={'h6'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{I18n.t('preference.label', { count: 2 })}</Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 4, lg: 4 }} >
        <Box display='flex' justifyContent={'flex-end'}>
          <ModifyButton />
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_WAITING_DURATION}>
        <FormFactory.MenuSelectorField
          name='preference.waiting_duration'
          options={[
            { label: '15 min', value: 15 * 60 },
            { label: '30 min', value: 30 * 60 },
            { label: '45 min', value: 45 * 60 },
            { label: '1h', value: 60 * 60 },
            { label: '1h30', value: 90 * 60 },
            { label: '2h', value: 120 * 60 },
            { label: '2h30min', value: 150 * 60 },
            { label: '3h', value: 180 * 60 },
            { label: '4h', value: 240 * 60 }
          ]}
          _native={{ label: I18n.t('preference.waiting_duration') }}
          nullOptionLabel={I18n.t('unlimited')}
          nullOptionValue={undefined}
          addNullOption
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_PREFERRED_MUSIC}>
        <FormFactory.MenuSelectorField
          name='preference.preferred_music'
          options={music_options}
          _native={{ label: I18n.t('preference.preferred_music') }}
          nullOptionLabel={I18n.t('none_fem')}
          nullOptionValue={undefined}
          addNullOption
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_ARMREST}>
        <Box display='flex' alignItems='flex-end' justifyContent={'center'}>
          <FormFactory.OptionSelectorField
            name='preference.armrest'
            options={[{ label: I18n.t('placement.down.label', { count: -1 }), value: true }]}
            _native={{
              valueType: 'switch',
              label: [I18n.t('preference.armrest.label', { count: -1 }), ':'].join(' ')
            }}
            getOptionLabelProps={(option: IFormFieldOption) => ({
              color: !armrest ? 'primary.main' : 'text.label',
              sx: { fontSize: 12 }
            })}
          >
            <ArmRestLabelSlot slot='label_option_slot' />
          </FormFactory.OptionSelectorField>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_OPEN_DOOR}>
        <FormFactory.MenuSelectorField
          name={'preference.open_door'}
          options={[
            { label: I18n.t('ever'), value: 'always' },
            { label: I18n.t('preference.open_door_depends'), value: 'depends' },
            { label: I18n.t('never'), value: 'never' }
          ]}
          _native={{
            label: I18n.t('preference.open_door')
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_PREFERRED_OPEN_WINDOW}>
        <FormFactory.OptionSelectorField
          name='preference.open_window'
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('preference.open_window')
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_PREFERRED_TEMPERATURE}>
        <FormFactory.TextField
          name={'preference.temperature'}
          _native={{
            label: I18n.t('preference.temperature.celcius'),
            type: 'number',
            slotProps: {
              input: {
                endAdornment: <InputAdornment position='end'>
                  <Icon color='primary'>{'thermostat_auto'}</Icon>
                </InputAdornment>
              }
            }
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} hidden={!features?.DISPLAY_PREF_PREFERRED_AIR_CONDITIONING}>
        <FormFactory.OptionSelectorField
          name='preference.air_conditioning'
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('preference.air_conditioning')
          }}
        />
      </Grid>
      <Grid size={12} hidden={!features?.DISPLAY_PREF_PREFERRED_COMMENT_TO_DRIVER}>
        <FormFactory.TextField
          name={'preference.driver_comment'}
          _native={{
            label: I18n.t('comment.length.default', { count: (driver_comment || '').length }),
            placeholder: I18n.t('preference.comment_placeholder'),
            type: 'number',
            slotProps: {
              input: {
                startAdornment: <InputAdornment position='start'>
                  <Icon>{'speaker_notes'} </Icon>
                </InputAdornment>
              }
            }
          }}
        />
      </Grid>
    </Grid>
  </Box>
)

export default PreferencesComponent
