
import React from 'react'
import { VTypes, hooks, FormHooks } from '@front/volcanion'

import _ from 'lodash'

import { Box, IconButton, Icon, Typography, Grid } from '@mui/material'

import { Primary } from './Section'
import Services from './Section/Services/table'

import Popups from './Popups'

const ContractComponent: React.FC = props => {
  const navigate = hooks.useNavigate()
  const [name, client_id] = FormHooks.useFieldValues(['name', 'client_id']) as VTypes.V__ANY
  return (
    <Box sx={{ p: 6, pt: 3 }}>
      <Popups />
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}><Icon color='primary'>{'arrow_back'}</Icon></IconButton>
          <Box ml={2} display='flex' alignItems='center'> <Icon color='primary'>{'description'}</Icon></Box>
          <Typography ml={2} variant='h5'> {[name, client_id].join(' - ')}</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2, p: 3, backgroundColor: '#F9F6F4' }}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Primary />
          </Grid>
          <Grid item xs={12}>
            <Services />
          </Grid>
        </Grid>
      </Box >
    </Box>
  )
}

export default ContractComponent
