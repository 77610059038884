import React from "react"
import { hooks } from "@front/volcanion"

import _ from "lodash"

import Component from "./component"

const withContainer: React.FC = () => {
  const [user] = hooks.useActiveUser({ populate: ['addresses'] })
  const addresses = user?.addresses

  const home_address = _.find(addresses, ['type', 'home'])?.address_id
  const work_address = _.find(addresses, ['type', 'work'])?.address_id
  const favorites_address = _.map(_.filter(addresses, ['favorite', true]), 'address_id')

  const mergedProps = {
    home_address,
    work_address,
    favorites_address
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
