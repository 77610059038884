import React, { useCallback } from "react"
import { hooks, FormHooks, type VTypes } from '@front/volcanion'
import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY

  const formToSubmitCompanyuserService = useCallback(Callbacks.formToSubmitCompanyuserServiceHandler(record_id), [record_id])

  const mergedProps = {
    formToSubmitCompanyuserService
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
