import React, { useCallback, useEffect } from 'react'
import { FormHooks, hooks, I18n, VTypes } from '@front/volcanion'

import _ from 'lodash'

import { Avatar, Box, ListSubheader } from '@mui/material/'
import { FormFactory } from '@front/squirtle'


const CompanyFormContent: React.FC = props => {
  const [company_id, setCompany] = hooks.useGlobalState('persist.company_id')
  const [company] = FormHooks.useFieldValues(['company']) as VTypes.V__ANY
  const [user] = hooks.useActiveUser()

  const afterChangeCompany = useCallback((value: string) => {
    setCompany(value)
  }, [setCompany])

  useEffect(() => {
    if (company_id !== company?.company_id) {
      setCompany(company_id)
    }
  }, [company])

  return (
    <FormFactory.MenuSelectorField
      name='company'
      afterChange={afterChangeCompany}
      labelKeys={['name']}
      config={{
        model_name: 'company',
        listener_type: 'search',
        initial_filter: {},
        forced_filter: { companyusers: { user: user?.user_id } },
        watchers: [user?.user_id],
        listener_id: 'global_company_search'
      }}
      _native={{
        fullWidth: true,
        valueDecoratorProps: {
          fontWeight: 'bold',
          fontSize: 22
        },
        _select: {
          sx: { ml: 1, },
          disableUnderline: true
        },
      }}
      selectFirst
    // nullOptionLabel={I18n.t('account.private')}
    // nullOptionValue={undefined}
    // addNullOption
    />
  )
}
const SubHeaderCompany: React.FC<{ open: boolean }> = ({ open }) => {
  const [company_id] = hooks.useGlobalState('persist.company_id')
  const [company] = hooks.useModel('company', [company_id as string], { single: true })

  return (
    <ListSubheader component='div' sx={{ px: 0 }}>
      <Box sx={{ my: 3 }}>
        <Box display='flex' alignItems='center' justifyContent='center' >
          <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32, fontSize: 22 }}>{_.first(company?.name)}</Avatar>
          <Box hidden={!open}>
            <FormFactory.Form
              name={'current_company'}
              listener_type='form'
            >
              <CompanyFormContent />
            </FormFactory.Form>
          </Box>
        </Box>
      </Box>
    </ListSubheader>
  )
}

export default SubHeaderCompany