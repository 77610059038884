import React, { useCallback, useEffect } from 'react'
import { FormHooks, hooks, VTypes, withSlot } from '@front/volcanion'

import _ from 'lodash'

import Component from "./component"
import { Prefix } from './types'
import { DecoratorLabelSlot } from '@front/squirtle/FormFactory/Text/types'
import { ModelRecord } from '@front/volcanion/types'

const withContainer = withSlot<{ prefix?: Prefix }, DecoratorLabelSlot>(({ label, prefix }) => {

  const [{ schedule_type, commercial_formula: commercial_formula_id, reservationinfo_pick_type }] = FormHooks.useFormState() as VTypes.V__ANY

  const [commercial_formula] = hooks.useModel('commercialformula', [commercial_formula_id], { single: true, populate: ['google_options', 'authorized_pois.poi_type'] })

  const authorized_pois = _.map(_.filter(commercial_formula?.authorized_pois, poi =>
    _.includes(['all', prefix], poi?.direction_type) && !!poi[`${schedule_type}_order`]
  ), 'poi_type') as ModelRecord[]

  const [sources, types, setSource] = hooks.useSource(`autocomplete_${prefix}`)

  const mergedProps = {
    authorized_pois,
    prefix,
    label,
    setSource
  }

  return (
    <Component {...mergedProps} />
  )
})

export default withContainer