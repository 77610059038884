import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { FormatUtils } from '@front/volcanion/utils'
import type { ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'

import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Icon, Link, Box } from '@mui/material'

const HeaderComponent: React.FC = props => (
  <TableHead>
    <TableRow>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('name.label', { count: 1 })}</TableCell>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('name.first')}</TableCell>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('email.label', { count: 1 })}</TableCell>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('company.contract_services')}</TableCell>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('admin.label.short')}</TableCell>
      <TableCell align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{I18n.t('status.label', { count: 1 })}</TableCell>
    </TableRow>
  </TableHead>
)
const RowComponent: React.FC<{ row: ModelRecord }> = ({ row }) => {
  const navigate = hooks.useNavigate()
  const status = FormatUtils.getStatus(row)
  const statusColor = FormatUtils.getStatusColor(status)

  const onOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    window.open(`/admin/user/${row?.companyuser_id}`, '_blank')
    e.stopPropagation()
    e?.preventDefault()
  }, [row?.order_id])

  return (
    <TableRow key={row?.name} onClick={() => navigate(`/admin/user/${row?.companyuser_id}`)}>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}> <Link href="#" target='_blank' fontWeight={'bold'} onClick={onOpen}>{row?.contact?.last_name}</Link></TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.contract?.first_name}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{row?.contact?.email}</TableCell>
      <TableCell size='small' align='center' padding='none' >
        <Box display='flex' sx={{ flex: 1, m: 1 }}>
          {_.map(row?.services, service => (
            <Chip
              variant='outlined'
              icon={<Icon>{'description'}</Icon>}
              label={`${service?.contract?.name} - ${service?.name}`}
              size='small'
              sx={{ mx: 1, pt: '3px' }}
            />
          ))
          }
        </Box>
      </TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}>{<Icon>{!!row?.administration_level ? 'check' : 'close'}</Icon>}</TableCell>
      <TableCell size='small' align='center' padding='none' sx={{ border: 0.5, borderColor: '#DDDDDD', p: '4px' }}><Typography sx={{ color: statusColor }}>{status}</Typography></TableCell>
    </TableRow>
  )
}

const BasicTable: React.FC<{ type?: string }> = ({ type }) => {

  const [data] = hooks.useModelSearch('companyuser', 'get', {
    initial_filter: {},
    populate: ['contact', 'services.contract'],
    listener_id: 'companyuser_list'
  })

  return (
    <TableContainer>
      <Table>
        <HeaderComponent />
        <TableBody>
          {_.map(data, (row) => (<RowComponent row={row} />))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
