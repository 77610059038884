import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'
import { Routes, Route, SHooks, Loader } from '@front/squirtle'

import Account from './Account/'
import Booking from './Booking/form'
import Contracts from './Contracts/'
import Contract from './Contract/form'
import Dashboard from './Dashboard/form'
import Orders from './Orders'
import Order from './Order/form'
import Service from './Service/form'
import Users from './Users/'
import User from './User/form'
import Drawer from '../elements/Drawer'
import { Main } from '../elements/Drawer/styles'

const PrivateRoutes: React.FC = props => {
  const [isExpand, setExpand] = hooks.useGlobalState('isExpand')
  const [open, setOpen] = hooks.useGlobalState('drawer_open')

  const [{ StripeProvider, stripe }, { clientSecret }, { isReady }] = SHooks.useStripeProvider()

  return (
    <div>
      <Loader isLoading={!stripe || !isReady}>
        <StripeProvider stripe={stripe} options={{ clientSecret }}>
          <Drawer setOpen={setOpen} open={open as boolean} />
          <Main open={isExpand as boolean}>
            <Routes>
              <Route index element={<Booking />} />
              <Route path='/book/*' element={<Booking />} />
              <Route path='/orders/planned' element={<Orders type='planned' />} />
              <Route path='/orders/history' element={<Orders type='history' />} />
              <Route path='/orders/current' element={<Orders type='current' />} />
              <Route path='/order/:order_id' element={<Order />} />
              <Route path='/admin/contracts/*' element={<Contracts />} />
              <Route path='/admin/contract/:companycontract_id' element={<Contract />} />
              <Route path='/admin/contract/*' element={<Contract />} />
              <Route path='/admin/users/*' element={<Users />} />
              <Route path='/admin/user/:companyuser_id' element={<User />} />
              <Route path='/admin/user/*' element={<User />} />
              <Route path='/admin/service/:companyservice_id' element={<Service />} />
              <Route path='/admin/service/*' element={<Service />} />
              <Route path='/admin/dashboard/*' element={<Dashboard />} />
              <Route path='/admin/current/*' element={<Orders type='current' />} />
              <Route path='/admin/history/*' element={<Orders type='history' />} />
              <Route path='/account/*' element={<Account />} />
            </Routes>
          </Main>
        </StripeProvider>
      </Loader>
    </div>
  )
}

export default PrivateRoutes