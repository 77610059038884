import React from 'react'
import { I18n, VTypes, FormHooks, hooks } from '@front/volcanion'
import { FormatUtils, ValidationUtils } from '@front/volcanion/utils'

import _ from 'lodash'

import { Box, Typography, Grid } from '@mui/material'
import { FormFactory } from '@front/squirtle'

const Row1: React.FC = props => {
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.TextField name='contact.gsm' required _native={{ required: true, label: I18n.t('gsm.label', { count: 1 }) }} normalize={(value: string) => ValidationUtils.parsePhone(value, CODE_INTERNATIONAL as string)} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='contact.email' required _native={{ required: true, label: I18n.t('email.pro') }} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='contact.first_name' required _native={{ required: true, label: I18n.t('name.first') }} />
      </Grid>
      <Grid item xs>
        <FormFactory.TextField name='contact.last_name' required _native={{ required: true, label: I18n.t('name.last') }} />
      </Grid>
    </Grid>
  )
}

const Row2: React.FC = props => {
  const [status] = FormHooks.useFieldValues(['status']) as VTypes.V__ANY
  const statusColor = FormatUtils.getStatusColor(status)
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs>
        <FormFactory.OptionSelectorField
          name={'administration_level'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('admin.label', { count: 1 })
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.OptionSelectorField
          name={'self_booking'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('order.self_booking')
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.OptionSelectorField
          name={'guest_booking'}
          options={[{ value: true }]}
          _native={{
            valueType: 'switch',
            label: I18n.t('order.guest_booking')
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.MenuSelectorField
          name='booking_level'
          options={[
            { label: I18n.t('companycontract.label', { count: 1 }), value: 'contract' },
            { label: I18n.t('company.label', { count: 1 }), value: 'company' },
            { label: I18n.t('companyservice.label', { count: 1 }), value: 'service' }
          ]}
          nullOptionLabel={I18n.t('none')}
          nullOptionValue={undefined}
          addNullOption
          _native={{
            label: I18n.t('company.user'),
          }}
        />
      </Grid>
      <Grid item xs>
        <FormFactory.MenuSelectorField
          name='status'
          options={[
            { label: I18n.t('status.active'), value: 'active' },
            { label: I18n.t('status.inactive'), value: 'inactive' },
            { label: I18n.t('status.archived'), value: 'archived' },
            { label: I18n.t('status.suspended'), value: 'suspended' },
          ]}
          _native={{
            label: I18n.t('status.label', { count: 1 }),
            valueDecoratorProps: {
              color: statusColor
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection: React.FC = props => (
  <Box display='flex'>
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant='h6' color='primary'>{I18n.t('info.general.label', { count: 2 })}</Typography>
      </Grid>
      <Row1 />
      <Row2 />
    </Grid>
  </Box >
)


export default PrimarySection
