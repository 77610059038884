
import React from 'react'
import { I18n, withSlot, hooks } from '@front/volcanion'

import _ from 'lodash'
import { Box } from '@mui/material'

import { DialogForm, DialogInformation, DialogConfirmation } from '@front/squirtle'
import { DecoratorContentSlot } from '@front/squirtle/Components_ts/Dialog/types'

import { PopupsProps } from './types'

import CallDriver from './CallDriver'

const CallDriverSlot = withSlot<{}, DecoratorContentSlot>(props => <CallDriver  {...props} />)

const PopupComponent: React.FC<PopupsProps> = props => {
  const navigate = hooks.useNavigate()
  return (
    <Box>
      <DialogConfirmation title='Appel chauffeur' name='driver_call'>
        <CallDriverSlot slot='dialog_content' />
      </DialogConfirmation>
    </Box >
  )
}

export default PopupComponent
