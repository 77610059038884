import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import _ from 'lodash'

import { List, ListItemButton, ListItemText } from '@mui/material/'

import { CustomListItemIcon, ListItemButtonProps, ListItemTextProps } from '../'

const AdminList: React.FC<{ open: boolean }> = ({ open }) => {
  const [{ location }] = hooks.useRouter()
  const navigate = hooks.useNavigate()
  return (
    <List dense disablePadding>
      <ListItemButton onClick={() => navigate('admin/dashboard')} selected={_.includes(location?.pathname, 'admin/dashboard')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='dashboard' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.admin.dashboard')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('admin/contracts')} selected={_.includes(location?.pathname, 'admin/contract')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='contracts' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.admin.contracts')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('admin/users')} selected={_.includes(location?.pathname, 'admin/user')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='users' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.admin.users')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('admin/current')} selected={_.includes(location?.pathname, 'admin/current')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='b2b-in_progress' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.admin.in_progress')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('admin/history')} selected={_.includes(location?.pathname, 'admin/history')} {...ListItemButtonProps}>
        <CustomListItemIcon name={'drawer'} category={'icons'} variant='b2b-history' />
        <ListItemText hidden={!open} {...ListItemTextProps} primary={I18n.t('drawer.admin.history')} />
      </ListItemButton>
    </List >
  )
}

export default AdminList