import React, { useCallback, useEffect } from "react"
import { FormHooks, VTypes, hooks } from "@front/volcanion"
import { IFormFieldOption } from "@front/volcanion/types"
import { TimeUtils } from "@front/volcanion/utils"

import _ from "lodash"
import moment from 'moment'

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const [{
    schedule_type,
    display_returnAt,
    commercial_formula,
    source_option,
    destination_option,
    source_trigger,
    destination_trigger,
    user_id
  }, setFormState] = FormHooks.useFormState() as VTypes.V__ANY
  const [
    commercial_package_id,
    service,
    companyuser_id,
    ridemode_id,
    requestedAt,
    source] = FormHooks.useFieldValues(['commercial_package', 'service', 'companyuser_id', 'ridemode', 'requestedAt', 'source']) as VTypes.V__ANY

  const [selectedEstimation] = hooks.useSelectedEstimation(commercial_package_id as string)
  const specificparameter_id = selectedEstimation?.details?.params?.specificparameter_id as string

  const [threshold_RA] = hooks.useSetting('threshold_RA')
  const [companyuser] = hooks.useModel('companyuser', [companyuser_id], { single: true })
  const [ridemode] = hooks.useModel('ridemode', [ridemode_id], { populate: ['config'], single: true })
  const ridemode_options = hooks.useRidemodeDurationOptions(ridemode_id)
  const [{ minimumDate, maximumDate }] = hooks.useSPTimeLimits(specificparameter_id)
  const returnAtListener = FormHooks.useFieldListener('returnAt')

  const display_destination = ridemode?.config?.display_destination_field
  const display_duration = ridemode?.config?.display_duration_field
  const display_travel = !!ridemode?.stay_required
  const owner = companyuser?.user || user_id

  const minDateTime = moment(minimumDate)
  const maxDateTime = !!maximumDate ? moment(maximumDate) : undefined
  const finalRequestedAt = moment(TimeUtils.getDetailsMomentFront(requestedAt, source)).format('[Le] DD/MM/YYYY [à] HH[h]mm')

  const onChangeReturnAt = useCallback(Callbacks.onChangeReturnAtHandler(setFormState, returnAtListener), [setFormState, returnAtListener])
  const resolveAddressCallback = useCallback(Callbacks.resolveAddressCallbackHandler(setFormState), [setFormState])
  const afterChangeRequestedAt = useCallback(Callbacks.afterChangeRequestedAtHandler(setFormState, threshold_RA as string), [setFormState, threshold_RA])

  const [addresses_source, autocomplete_source, , { onResolveAddress: onResolveAddress_source, getListener: getSourceListener }] = hooks.useAddressAutocomplete({
    owner,
    listener_id: 'autocomplete_source',
    resolveAddressCallback: (details) => resolveAddressCallback(details, 'source'),
    requestOptions: {
      recentLimit: 4,
      favoriteLimit: 4,
      commercial_formula,
      schedule_type
    },
    ...source_option,
    watchers: [schedule_type, service, commercial_formula, owner, source_option]
  })

  const [addresses_destination, autocomplete_destination, , { onResolveAddress: onResolveAddress_destination, getListener: getDestinationListener }] = hooks.useAddressAutocomplete({
    owner,
    listener_id: 'autocomplete_destination',
    resolveAddressCallback: (details) => resolveAddressCallback(details, 'destination'),
    requestOptions: {
      recentLimit: 4,
      favoriteLimit: 4,
      commercial_formula,
      schedule_type,
    },
    ...destination_option,
    watchers: [schedule_type, service, commercial_formula, owner, destination_option]
  })

  useEffect(() => {
    autocomplete_source('')
  }, [source_option, source_trigger])

  useEffect(() => {
    autocomplete_destination('')
  }, [source_option, destination_trigger])

  const mergedProps = {
    source: {
      autocomplete: autocomplete_source,
      onResolveAddress: onResolveAddress_source,
      options: addresses_source as IFormFieldOption[]
    },
    destination: {
      autocomplete: autocomplete_destination,
      onResolveAddress: onResolveAddress_destination,
      options: addresses_destination as IFormFieldOption[]
    },
    minDateTime,
    maxDateTime,
    display_duration,
    display_destination,
    display_travel,
    display_returnAt,
    setFormState,
    afterChangeRequestedAt,
    onChangeReturnAt,
    ridemode_options,
    finalRequestedAt
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
