import React, { useCallback } from "react"

import _ from "lodash"

import Component from "./component"
import Callbacks from "./callbacks"

const withContainer: React.FC = () => {
  const afterChange = useCallback(Callbacks.afterChangeHandler(), [])

  const mergedProps = {
    afterChange
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
