import React, { useCallback } from 'react'
import { FormHooks, hooks, I18n, type VTypes } from '@front/volcanion'
import type { ModelRecord } from '@front/volcanion/types'

import _ from 'lodash'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link, Grid, Icon, Button } from '@mui/material'

const HeaderComponent: React.FC = props => (
  <TableHead>
    <TableRow>
      <TableCell align='center'>Nom contrat</TableCell>
      <TableCell align='center'>Code contrat</TableCell>
      <TableCell align='center'>Nom service</TableCell>
      <TableCell align='center'>Code service</TableCell>
    </TableRow>
  </TableHead>
)
const RowComponent: React.FC<{ row: ModelRecord }> = ({ row }) => {
  const navigate = hooks.useNavigate()

  const onOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    window.open(`/admin/service/${row?.companyservice?.companyservice_id}`, '_blank')
    e.stopPropagation()
    e?.preventDefault()
  }, [row?.order_id])

  return (
    <TableRow key={row?.name} onClick={() => navigate(`/admin/service/${row?.companyservice?.companyservice_id}`)}>
      <TableCell size='small' align='center'> <Link href="#" target='_blank' fontWeight={'bold'} onClick={onOpen}>{row?.companyservice?.contract?.name}</Link></TableCell>
      <TableCell size='small' align='center'>{row?.companyservice?.contract?.client_id}</TableCell>
      <TableCell size='small' align='center'>{row?.companyservice?.name}</TableCell>
      <TableCell size='small' align='center'>{row?.companyservice?.client_id}</TableCell>
    </TableRow>
  )
}

const BasicTable: React.FC = props => {
  const [{ record_id }] = FormHooks.useFormState() as VTypes.V__ANY
  const [gl_company_id] = hooks.useGlobalState('persist.company_id')
  const [data] = hooks.useModelSearch('companyuserservices', 'get', {
    initial_filter: {},
    forced_filter: {
      companyuser: record_id,
      companyservice: {
        contract: {
          company: gl_company_id as string
        }
      }
    },
    watchers: [record_id],
    populate: ['companyuser', 'companyservice.contract'],
    listener_id: 'companyuser_list'
  })

  return (
    <Grid container>
      <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6' color='primary'>{I18n.t('companyservice.associated.label', { count: 2 })}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <HeaderComponent />
            <TableBody>
              {_.map(data, (row) => (<RowComponent row={row} />))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid >
  )
}

export default BasicTable
