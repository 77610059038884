
import React from 'react'
import _ from 'lodash'

import { Box, Typography } from '@mui/material'

const NoResultComponent: React.FC = props => {
  return (
    <Box>
      <Typography sx={{ opacity: 1 }} variant={'h4'} fontStyle={'italic'} textAlign={'center'} letterSpacing={3} >{'No result'}</Typography>
    </Box >
  )
}

export default NoResultComponent
