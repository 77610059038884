
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import _ from 'lodash'
import { FormFactory } from '@front/squirtle'

import FormContent from './'
import Callbacks from './callbacks'
import type { CompanyServiceForm, ParamType } from './types'

const CompanyService: React.FC<CompanyServiceForm> = ({ companycontract_id }) => {
  const { companyservice_id } = hooks.useParams() as ParamType
  const navigate = hooks.useNavigate()

  const getEmptyState = useCallback(Callbacks.getEmptyStateHandler(companycontract_id), [companycontract_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const recordToState = useCallback(Callbacks.recordToStateHandler(), [])

  const formToSubmit = useCallback(Callbacks.formToSubmitHandler(), [])
  const onSubmitSuccess = useCallback(Callbacks.onSubmitSuccessHandler(navigate), [navigate])

  return <FormFactory.Form
    name={`servicet_detail_${companyservice_id || 'create'}`}
    listener_type='form'
    getEmptyState={getEmptyState}
    getEmptyForm={getEmptyForm}
    recordToForm={recordToForm}
    recordToState={recordToState}
    formToSubmit={formToSubmit}
    onSubmitSuccess={!companyservice_id ? onSubmitSuccess : _.noop}
    config={{
      model_name: 'companyservice',
      populate: ['contact', 'customerref_option.refs'],
      ids: [companyservice_id]
    }}
  >
    <FormContent />
  </FormFactory.Form>
}

export default CompanyService
